/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import AuscultationDetails from "views/exam/components/auscultation-details"
import { observer } from "mobx-react-lite"
import InPersonExamContext from "views/exam/in-person-exam/in-person-exam-context"

const AuscultationList = observer((): ReactElement => {
  const { inPersonExamStore } = useContext(InPersonExamContext)

  return (
    <div className="panel overflow-y-auto">
      <div className="panel-header">Auscultations</div>
      {inPersonExamStore.auscultations.length > 0 ? (
        <ul className="divide-y">
          {inPersonExamStore.auscultations.map((auscultation) => (
            <li key={auscultation.id}>
              <AuscultationDetails expanded={true} auscultation={auscultation} onContract={() => null} />
            </li>
          ))}
        </ul>
      ) : (
        <div>
          <div className="my-16 mx-auto w-2/3 text-center">
            <h3 className="text-lg font-semibold">You don't have any auscultations yet.</h3>
            <p className="mt-3">
              In order to start an auscultation, click <strong>New Auscultation</strong>.
            </p>
          </div>
        </div>
      )}
    </div>
  )
})

export default AuscultationList
