/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../common/types/global.d.ts" />
import "reflect-metadata" // https://github.com/typestack/class-transformer#installation
import React from "react"
import App from "app"
import * as serviceWorker from "./serviceWorker"
import "./index.css"
import { createRoot } from "react-dom/client"

// Allows typing of params so we don't need to unknown check everywhere
declare module "react-router-dom" {
  export function useParams<
    P extends Record<string, string | undefined> = {
      [key: string]: string | undefined
    }
  >(): P
}

const container = document.getElementById("root") as Element
const root = createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
