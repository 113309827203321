/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom"
import { bindToggle, usePopupState } from "material-ui-popup-state/hooks"
import Popup from "@medaica/common/components/popup/popup"
import { Button, DialogContent, DialogTitle } from "@mui/material"
import { EventData } from "views/exam/virtual-exam-calendar/index"
import { EventProps } from "react-big-calendar"
import VirtualExamEventDetails from "views/exam/components/virtual-exam-event-details"
import { Paths } from "const"

const CalendarEvent = ({ event }: EventProps<EventData>): ReactElement => {
  const navigate = useNavigate()
  const popupState = usePopupState({ variant: "popper", popupId: `event-${event.virtualExam.id}` })

  return (
    <>
      <Popup
        placement="right"
        popupState={popupState}
        style={{ zIndex: 9999 }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        <>
          <DialogTitle>
            <div className="text-2xl">Virtual Exam</div>
            <div className="mt-2 text-base font-normal">
              {event.virtualExam.scheduledStartDate.toLocaleString(undefined, { dateStyle: "medium" })}{" "}
              {event.virtualExam.scheduledStartDate.toLocaleTimeString(undefined, { timeStyle: "short" })}
              {" - "}
              {event.virtualExam.scheduledEndDate.toLocaleTimeString(undefined, { timeStyle: "short" })}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="space-x-2 mt-5">
              <Button variant="contained" onClick={() => navigate(`${Paths.liveExam}/${event.virtualExam.id}`)}>
                Join Exam
              </Button>
            </div>
            <div className="mt-8">
              <VirtualExamEventDetails virtualExam={event.virtualExam} />
            </div>
          </DialogContent>
        </>
        {/*  <div className="p-3 max-w-xs">*/}
        {/*    <h3 className="text-lg font-semibold">{event.virtualExam.patientProfile.fullName}</h3>*/}
        {/*    <div className="mt-3">*/}
        {/*      {event.virtualExam.scheduledStartDate.toLocaleString(undefined, { dateStyle: "medium" })}{" "}*/}
        {/*      {event.virtualExam.scheduledStartDate.toLocaleTimeString(undefined, { timeStyle: "short" })}*/}
        {/*      {" - "}*/}
        {/*      {event.virtualExam.scheduledEndDate.toLocaleTimeString(undefined, { timeStyle: "short" })}*/}
        {/*    </div>*/}
        {/*    <div className="space-x-2 mt-5">*/}
        {/*      <Button variant="contained" onClick={() => navigate(`${Paths.liveExam}/${event.virtualExam.id}`)}>*/}
        {/*        Join Exam*/}
        {/*      </Button>*/}
        {/*      <Button variant="outlined">Edit</Button>*/}
        {/*    </div>*/}
        {/*    <div className="mt-8 flex items-center">*/}
        {/*      <LinkIcon fontSize="small" />*/}
        {/*      <div className="flex-1 ml-2 truncate overflow-ellipsis overflow-hidden">{event.virtualExam.id}</div>*/}
        {/*    </div>*/}
        {/*    <div className="mt-4">*/}
        {/*      <ul>*/}
        {/*        <li className="flex items-center">*/}
        {/*          <PersonOutlineIcon fontSize="small" />*/}
        {/*          <span className="ml-2">{event.virtualExam.healthcareProvider.displayName}</span>*/}
        {/*        </li>*/}
        {/*        <li className="flex items-center">*/}
        {/*          <PersonOutlineIcon fontSize="small" />*/}
        {/*          <span className="ml-2">{event.virtualExam.patientProfile.fullName}</span>*/}
        {/*        </li>*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </div>*/}
      </Popup>
      <div {...bindToggle(popupState)}>
        <span className="text-sm pl-1 text-primary-600">{event.title}</span>
      </div>
    </>
  )
}

export default CalendarEvent
