/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import { PatientProfile } from "@medaica/common/types"
import CreatePatientDialog from "views/patient/create-patient-dialog"
import { Box, Typography } from "@mui/material"
import Table, { buildActionColumn } from "@medaica/common/components/table/table"
import Page from "@medaica/common/components/page/page"
import useQuery from "@medaica/common/hooks/query"
import moment from "moment/moment"
import AddIcon from "@mui/icons-material/Add"
import Button2 from "@medaica/common/components/Button2"
import ViewDetailsButton from "@medaica/common/components/table/view-details-button"
import useAuth from "hooks/auth"
import ActionMenu from "views/organization/patient-list/components/action-menu"
import { logError } from "@medaica/common/services/util"
import { MUIDataTableData } from "mui-datatables"
import Loading from "@medaica/common/components/loading"
import NoPatientsView from "views/organization/patient-list/components/no-patients-view"

type PatientData = {
  name: { name: string; id: string }
  birthdate: Date | null
  emailAddress: string
  actions: PatientProfile
}

const PatientTable = ({ noPatientsView }: { noPatientsView: ReactNode }): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [patientData, setPatientData] = useState<PatientData[]>()
  const [count, setCount] = useState<number>()
  const pageNumber = useQuery("p")
  const { user } = useAuth()
  const [error, setError] = useState<string>()

  const fetchData = useCallback(
    async (pageNumber: number) => {
      try {
        const page = await medaicaApiService.patientProfiles.getPatientProfiles({
          sort: [{ field: "dateCreated", direction: "desc" }],
          pagination: { pageNumber: pageNumber, pageSize: 10 },
          filters: [{ field: "healthcareProviderId", value: user?.id }],
        })
        setCount(page.totalCount)
        setPatientData(
          page.items.map((patientProfile) => {
            return {
              name: { name: `${patientProfile.firstName} ${patientProfile.lastName}`, id: patientProfile.id },
              birthdate: patientProfile.birthdate,
              emailAddress: patientProfile.emailAddress,
              actions: patientProfile,
            }
          })
        )
      } catch (e) {
        logError(e)
        setError(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    },
    [medaicaApiService.patientProfiles, user?.id]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])
  if (error) {
    return <div style={{ textAlign: "center" }}>{error}</div>
  }

  if (patientData) {
    return (
      <Table
        data={patientData as unknown as MUIDataTableData[]}
        searchPlaceholder="Enter name or email address"
        title="Patient list"
        aria-label="Patient table"
        options={{
          serverSide: true,
          count: count,
          download: false,
          print: false,
        }}
        noRecordsPanel={noPatientsView}
        columns={[
          {
            label: "Name",
            name: "name",
            options: {
              filter: true,
              sort: true,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {patientData[dataIndex].name.name}
                  </Typography>
                )
              },
            },
          },
          {
            name: "birthdate",
            label: "Date of birth",
            options: {
              sort: true,
              customBodyRenderLite: (dataIndex) => {
                return moment(patientData[dataIndex].birthdate).format("ll")
              },
            },
          },
          {
            name: "emailAddress",
            label: "Email",
            options: {
              filter: true,
              sort: true,
            },
          },
          buildActionColumn("actions", (patientProfile: PatientProfile) => (
            <Box sx={{ display: "flex", columnGap: "4px" }}>
              <ViewDetailsButton to={`${Paths.patients}/${patientProfile.id}`} />
              <ActionMenu patientProfile={patientProfile} refreshPatientList={() => void fetchData(1)} />
            </Box>
          )),
        ]}
      />
    )
  }

  return <Loading />
}

const PatientListView = (): ReactElement => {
  const { setCurrentPage } = useGlobalContext()
  const [createPatientProfileModalIsOpen, setCreatePatientProfileModalIsOpen] = useState(false)

  useEffect(() => {
    setCurrentPage("patients", Paths.patients)
  }, [setCurrentPage])

  return (
    <Page variant="full">
      <CreatePatientDialog
        isOpen={createPatientProfileModalIsOpen}
        onClose={() => setCreatePatientProfileModalIsOpen(false)}
      />
      <Header
        title="Patients"
        actions={
          <Button2
            type="button"
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => setCreatePatientProfileModalIsOpen(true)}
            sx={{
              "& .MuiSvgIcon-fontSizeMedium": {
                fontSize: "14px",
              },
            }}
          >
            Add new patient
          </Button2>
        }
      />
      <PatientTable noPatientsView={<NoPatientsView onAddPatient={() => setCreatePatientProfileModalIsOpen(true)} />} />
    </Page>
  )
}

export default PatientListView
