/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

const DashboardIcon = ({
  height = 24,
  width = 24,
  deemphasize = false,
}: {
  height?: number
  width?: number
  deemphasize?: boolean
}): ReactElement => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {deemphasize ? (
        <>
          <path
            d="M2 4C2 2.89543 2.89543 2 4 2H8C9.10457 2 10 2.89543 10 4V8C10 9.10457 9.10457 10 8 10H4C2.89543 10 2 9.10457 2 8V4Z"
            stroke="#2823EE"
            strokeWidth="1.5"
          />
          <path
            d="M22 6C22 8.20914 20.2091 10 18 10C15.7909 10 14 8.20914 14 6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6Z"
            stroke="#2823EE"
            strokeWidth="1.5"
          />
          <path
            d="M10 18C10 20.2091 8.20914 22 6 22C3.79086 22 2 20.2091 2 18C2 15.7909 3.79086 14 6 14C8.20914 14 10 15.7909 10 18Z"
            stroke="#2823EE"
            strokeWidth="1.5"
          />
          <path
            d="M14 16C14 14.8954 14.8954 14 16 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H16C14.8954 22 14 21.1046 14 20V16Z"
            stroke="#2823EE"
            strokeWidth="1.5"
          />
        </>
      ) : (
        <>
          <path
            opacity="0.4"
            d="M1.53833 3.61499C1.53833 2.51042 2.43376 1.61499 3.53833 1.61499H8.46167C9.56623 1.61499 10.4617 2.51042 10.4617 3.61499V8.53833C10.4617 9.64289 9.56623 10.5383 8.46166 10.5383H3.53833C2.43376 10.5383 1.53833 9.64289 1.53833 8.53832V3.61499Z"
            fill="#1A1990"
          />
          <path
            opacity="0.4"
            d="M13.4617 15.5383C13.4617 14.4338 14.3571 13.5383 15.4617 13.5383H20.385C21.4896 13.5383 22.385 14.4338 22.385 15.5383V20.4617C22.385 21.5662 21.4896 22.4617 20.385 22.4617H15.4617C14.3571 22.4617 13.4617 21.5662 13.4617 20.4617V15.5383Z"
            fill="#1A1990"
          />
          <circle cx="17.9233" cy="6.07665" r="4.46166" fill="#1A1990" />
          <circle cx="5.99999" cy="18" r="4.46166" fill="#1A1990" />
        </>
      )}
    </svg>
  )
}

export default DashboardIcon
