/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FailureReason } from "@medaica/common/components/compatibility-check"
import CompatibilityCheckFailureMessage from "@medaica/common/components/compatibility-check-failure-message"

const TryAgainButton = (): ReactElement => {
  return (
    <Button
      onClick={() => {
        window.location.reload()
      }}
    >
      Try again
    </Button>
  )
}
const CompatibilityCheckFailure = ({
  onClose,
  failureReason,
}: {
  onClose: () => void
  failureReason: FailureReason
}): ReactElement => {
  const [isOpen, setIsOpen] = useState(true)

  const handleCloseClicked = () => {
    setIsOpen(false)
    onClose()
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Exam access failed</DialogTitle>
      <DialogContent>
        <CompatibilityCheckFailureMessage failureReason={failureReason} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClicked}>Close</Button>
        <TryAgainButton />
      </DialogActions>
    </Dialog>
  )
}

export default CompatibilityCheckFailure
