/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useState } from "react"
import LobbyBase from "@medaica/common/views/exam/virtual-exam/lobby"
import { useLocation } from "react-router-dom"
import useAuth from "hooks/auth"
import { Paths } from "const"
import useGlobalContext from "@medaica/common/hooks/global-context"
import UnspecifiedPatientView from "./components/unspecified-patient-view"
import SpecifiedPatientView from "./components/specified-patient-view"
import Warning from "@medaica/common/components/snackbars/warning"
import { useSnackbar } from "notistack"
import { logError } from "@medaica/common/services/util"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"

const NewExam = (): ReactElement => {
  const { user } = useAuth()
  const location = useLocation()
  const { medaicaApiService } = useGlobalContext()
  const [startingExam, setStartingExam] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { uiStore, preferencesStore, mediaDeviceStore } = useContext(VirtualExamContext)

  const startLiveExam = async (patientProfileId: string) => {
    try {
      setStartingExam(true)
      const virtualExam = await medaicaApiService.virtualExams.createVirtualExam(patientProfileId, user.id)
      window.history.replaceState(null, "", `${Paths.liveExam}/${virtualExam.id}`)
      uiStore.navigate("exam")
    } catch (error) {
      enqueueSnackbar("There was an error starting the exam. Please try again.", {
        content: (key, message) => <Warning id={key} message={message} />,
      })
      logError(error)
      setStartingExam(false)
    }
  }

  return (
    <LobbyBase mediaDeviceStore={mediaDeviceStore} preferencesStore={preferencesStore}>
      <div className="flex h-full flex-col justify-center w-full items-center panel p-10 border-0">
        {location.state?.patientProfileId ? (
          <SpecifiedPatientView
            patientProfileId={location.state?.patientProfileId}
            startingExam={startingExam}
            onStartExam={() => void startLiveExam(location.state?.patientProfileId as string)}
          />
        ) : (
          <UnspecifiedPatientView
            startingExam={startingExam}
            onPatientSelected={(patientProfileId) => void startLiveExam(patientProfileId)}
          />
        )}
      </div>
    </LobbyBase>
  )
}

export default NewExam
