/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Button, ButtonProps, SxProps } from "@mui/material"
import mergeSx from "merge-sx"

const containedSx: SxProps = {
  backgroundColor: "primary.main",
  borderColor: "#f0f0fb",
  borderStyle: "solid",
  borderRadius: "6px",
  color: "#fff",
  fontWeight: "500",
}

const containedSecondarySx: SxProps = {
  backgroundColor: "#f0f0fb",
  fontSize: "12px",
  fontWeight: "500",
  borderRadius: "6px",
  color: "primary.dark",
  borderWidth: 0,
}

const outlinedSx: SxProps = {
  backgroundColor: "#fff",
  borderRadius: "6px",
  borderWidth: "1px",
  borderStyle: "solid",
  fontWeight: "medium",
  padding: "0px 19px",
  "& .MuiSvgIcon-fontSizeMedium": {
    fontSize: "14px",
  },
}

const outlinedPrimarySx: SxProps = {
  borderColor: "primary.main",
  color: "primary.dark",
  // todo background light
  backgroundColor: "#f0f0fb",
  "&:hover": {
    backgroundColor: "#e1e1fa",
  },
}

const outlinedSecondarySx: SxProps = {
  borderColor: "secondary.main",
  color: "primary.dark",
  "&:hover": {
    backgroundColor: "secondary.main",
    borderColor: "secondary.main",
  },
}

const filledTonalPrimarySx: SxProps = {
  color: "primary.dark",
  backgroundColor: "#f0f0fb",
  "&:hover": {
    backgroundColor: "#e1e1fa",
  },
}

const textSx: SxProps = {
  color: "primary.dark",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiSvgIcon-fontSizeMedium": {
    color: "primary.dark",
  },
}

const Button2 = <C extends React.ElementType>(
  props: ButtonProps<
    C,
    {
      component?: C
    }
  >
): ReactElement => {
  const { variant = "contained", sx, color, size, ...rest } = props

  const sizeSx = {
    small: { fontSize: "12px", padding: "0px 13px", fontWeight: "medium" },
    medium: { fontSize: "14px", padding: "0px 19px", fontWeight: "medium" },
    large: { fontSize: "14px", padding: "2px 20px", fontWeight: "medium" },
  }[size ?? "medium"] as SxProps

  const variantSx = {
    filled: containedSx,
    contained: containedSx,
    "contained-secondary": containedSecondarySx,
    outlined: outlinedSx,
    "outlined-secondary": outlinedSecondarySx,
    text: textSx,
  }[variant] as SxProps

  const colorSx = (() => {
    if (variant === "contained") return {}
    if (variant === "filledTonal") return filledTonalPrimarySx
    if (variant === "outlined" && color === "primary") return outlinedPrimarySx
    if (variant === "outlined" && color === "secondary") return outlinedSecondarySx
    return {}
  })()

  return (
    <Button variant="contained" sx={mergeSx(variantSx, colorSx, sizeSx, sx)} {...rest}>
      {props.children}
    </Button>
  )
}

export default Button2
