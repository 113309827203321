/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { HTMLProps, ReactElement, useEffect, useState } from "react"
import { ErrorMessage as FormikErrorMessage, Field, FormikErrors, FormikTouched, FormikValues, getIn } from "formik"

const Select = ({
  label,
  helpText,
  hiddenLabel = false,
  children,
  name,
  errors,
  touched,
  className,
  ...props
}: {
  label: string
  helpText?: string
  hiddenLabel?: boolean
  children: React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>[]
  name: string
  errors?: FormikErrors<FormikValues>
  touched?: FormikTouched<FormikValues>
  className?: string
} & HTMLProps<HTMLSelectElement>): ReactElement => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(!!getIn(errors, name) && !!getIn(touched, name))
  }, [errors, touched, name])

  return (
    <>
      <label htmlFor={name} className={"block font-base text-gray-700" + (hiddenLabel ? " sr-only" : "")}>
        {label}
      </label>
      <div className="mt-1 relative">
        <Field
          as="select"
          {...props}
          name={name}
          className="mt-1 block w-full input"
          aria-invalid={hasError}
          aria-describedby={hasError ? name + "-errorMessage" : helpText ? name + "-description" : null}
        >
          {children}
        </Field>
      </div>
      <FormikErrorMessage name={name}>
        {(msg) => (
          <div className="mt-1 text-error" id={`${name}-errorMessage`}>
            {msg}
          </div>
        )}
      </FormikErrorMessage>
      {helpText && (
        <p id={`${name}-description`} className="mt-2 text-gray-500">
          {helpText}
        </p>
      )}
    </>
  )
}

export default Select
