/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import DatePicker from "react-datepicker"
import { Field, Form, Formik, ErrorMessage } from "formik"
import * as Yup from "yup"
import { ConnectedFocusError } from "focus-formik-error"
import TextInput from "@medaica/common/components/forms/text-input"
import { PatientProfile } from "@medaica/common/types"
import Select from "@medaica/common/components/forms/select"
import { classNames } from "@medaica/common/services/util"
import PageDialogTitle from "@medaica/common/components/dialog/page-dialog-title"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import PageDialog from "@medaica/common/components/dialog/page-dialog"
import PageDialogActions from "@medaica/common/components/dialog/page-dialog-actions"
import PageDialogContent from "@medaica/common/components/dialog/page-dialog-content"
import { Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import useAuth from "hooks/auth"
import "react-datepicker/dist/react-datepicker.css"
import "@medaica/common/common.css"
import { createGlobalStyle } from "styled-components"
import { EmailAddressValidator } from "services/yup"

interface PatientProfileData {
  firstName: string
  lastName: string
  emailAddress: string
  sex: string | undefined
  birthdate: string | undefined
  organizationId: string
  healthcareProviderId: string
}

const PatientDetailsForm = ({
  patientProfile,
  onSubmit,
  onClose,
  isOpen,
  submitButtonValue,
  title,
}: {
  patientProfile?: PatientProfile
  onSubmit: (patientProfile: PatientProfileData, setSubmitting: (boolean) => void) => Promise<void>
  onClose: () => void
  isOpen: boolean
  submitButtonValue: string
  title: string
}): ReactElement => {
  const { user } = useAuth()
  const customDateFormat = "mm/dd/yyyy"
  const startDate = new Date("01/01/1900")
  const defaultDate = new Date("01/01/1960")
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    patientProfile?.birthdate ? patientProfile.birthdate : defaultDate
  )
  const toDateString = (date: Date | undefined | null): string => {
    if (!date) {
      return ""
    }
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker-wrapper {
        width: 100%;
    }
    
    /* Styling for both dropdowns */
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      font-size: 13px;
      border: 1px solid #ccc;
      border-radius: 10px;
    }
    
    /* Hover effect for both dropdowns */
    .react-datepicker__month-select:hover,
    .react-datepicker__year-select:hover {
      background-color: #f0f0f0;
    }

    /* Active/focus effect for both dropdowns */
    .react-datepicker__month-select:focus,
    .react-datepicker__year-select:focus {
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    }

    /* Hover effect for the date */
    .react-datepicker__day:hover {
      background-color: cornflowerblue;
    }

    /* Active/focus effect for the date */
    .react-datepicker__day:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name {
      font-size: 16px;
      color: #333;
    }

    .react-datepicker__today-button {
      /* Styling for the today button */
      background-color: #f0f0f0;
      border: 2px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;
      font-size: 13px;
    }
    `

  const schema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, "First name must be fewer than 100 characters")
      .matches(/^[\p{L}.,'\s-]+$/u, "First name cannot contain special characters")
      .required("First name is required"),
    lastName: Yup.string()
      .max(100, "Last name must be fewer than 100 characters")
      .matches(/^[\p{L}.,'\s-]+$/u, "Last name cannot contain special characters")
      .required("Last name is required"),
    emailAddress: EmailAddressValidator,
    sex: Yup.string(),
    birthdate: Yup.date()
      .required("Date of birth is required")
      .max(new Date(), "Date of birth must be in the past")
      .typeError("Date of birth must be a valid date"),
  })

  const selectDate = (date: Date, form) => {
    setSelectedDate(date)
    form.setFieldValue("birthdate", toDateString(date))
  }

  return (
    <PageDialog open={isOpen} onClose={onClose}>
      <DialogCloseButton onClick={onClose} />
      <Formik
        initialValues={{
          firstName: patientProfile?.firstName ?? "",
          lastName: patientProfile?.lastName ?? "",
          emailAddress: patientProfile?.emailAddress ?? "",
          sex: patientProfile?.sex ?? "",
          birthdate: toDateString(patientProfile?.birthdate),
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          void onSubmit(
            { organizationId: user.organizationId, healthcareProviderId: user.id, ...values },
            setSubmitting
          )
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <ConnectedFocusError />
            <PageDialogTitle>{title}</PageDialogTitle>
            <PageDialogContent>
              <div style={{ minWidth: "34rem" }}>
                <div className="space-y-6">
                  <div>
                    <TextInput
                      type="text"
                      name="firstName"
                      id="firstName"
                      errors={errors}
                      touched={touched}
                      aria-required="true"
                      autoComplete="given-name"
                      label="First name"
                    />
                  </div>
                  <div>
                    <TextInput
                      type="text"
                      name="lastName"
                      id="lastName"
                      errors={errors}
                      touched={touched}
                      aria-required="true"
                      autoComplete="family-name"
                      label="Last name"
                    />
                  </div>
                  <div>
                    <TextInput
                      type="text"
                      name="emailAddress"
                      id="emailAddress"
                      errors={errors}
                      touched={touched}
                      aria-required="true"
                      autoComplete="email"
                      label="Email"
                    />
                  </div>
                  <div>
                    <Select name="sex" label="Sex" errors={errors} touched={touched}>
                      <option value="unknown">Unknown</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Select>
                  </div>
                  <div>
                    <label htmlFor="date-picker-input" className="block font-base text-gray-700">
                      Date of birth
                    </label>
                    <div className="mt-1 relative">
                      <Field
                        name="birthdate"
                        render={({ field, form }) => (
                          <DatePicker
                            {...field}
                            id="date-picker-input"
                            customInput={
                              <div className="custom-datepicker-input">
                                <DatePickerWrapperStyles />
                                <span className="datepicker-value">{field.value ? field.value : customDateFormat}</span>
                                <div className="datepicker_icon">&#x1F4C5;</div>
                              </div>
                            }
                            selected={selectedDate}
                            onYearChange={(date) => {
                              selectDate(date, form)
                            }}
                            onMonthChange={(date) => {
                              selectDate(date, form)
                            }}
                            onChange={(date) => {
                              if (!date) {
                                return
                              }
                              selectDate(date, form)
                            }}
                            dateFormat={customDateFormat}
                            minDate={startDate}
                            maxDate={new Date()} // today
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            scrollableYearDropdown
                            className={classNames(
                              "block w-full input",
                              errors.birthdate && touched.birthdate ? "input-error" : ""
                            )}
                          />
                        )}
                      />
                    </div>
                    <ErrorMessage name="birthdate">
                      {(msg) => (
                        <div className="mt-1 text-error" id="birthdate-errorMessage">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
            </PageDialogContent>
            <PageDialogActions>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton variant="contained" loading={isSubmitting} type="submit">
                {submitButtonValue}
              </LoadingButton>
            </PageDialogActions>
          </Form>
        )}
      </Formik>
    </PageDialog>
  )
}

export default PatientDetailsForm
