/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"

const Devices = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [devices, setDevices] = useState<any[]>()

  const dateToYMD = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0") // Month from 0 to 11
    const year = date.getFullYear()
    const hour = date.getHours().toString().padStart(2, "0")
    const minute = date.getMinutes().toString().padStart(2, "0")
    return `${year}-${month}-${day} ${hour}:${minute}`
  }

  useEffect(() => {
    void (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const devices = (await medaicaApiService.ems.getDeviceUnits()) as any[]
      setDevices(devices)
    })()
  }, [medaicaApiService])

  return (
    <div className="container mx-auto mt-10 flex justify-center">
      <table className="border-collapse">
        <thead>
          <tr>
            <th>Serial</th>
            <th>Factory</th>
            <th>Device</th>
            <th>PCBA</th>
            <th>PCBA as Inputted</th>
            <th>Final</th>
            <th>Final as Inputted</th>
            <th>Batch Date</th>
          </tr>
        </thead>
        <tbody>
          {devices?.map((device) => {
            return (
              <tr key={device.serial}>
                <td className="px-4">{device.serial}</td>
                <td className="px-4">{device.factory}</td>
                <td className="px-4">{device.device}</td>
                <td className="px-4">{dateToYMD(device.pcba as Date)}</td>
                <td className="px-4">{device.pcbaString}</td>
                <td className="px-4">{dateToYMD(device.final as Date)}</td>
                <td className="px-4">{device.finalString}</td>
                <td className="px-4">{device.batchDate?.toISOString()}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Devices
