/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import UIStoreBase from "@medaica/common/views/exam/virtual-exam/stores/ui-store"
import VirtualExamUIStore from "views/exam/virtual-exam/exam-room/stores/ui-store"

type View = "exam" | "lobby"

class UIStore extends UIStoreBase<View> {
  virtualExam: VirtualExamUIStore
}

export default UIStore
export type { View }
