/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import "firebase/database"
import Video from "views/exam/virtual-exam/exam-room/components/video"
import PageControls from "views/exam/virtual-exam/exam-room/components/page-controls"
import AuscultationList from "views/exam/virtual-exam/exam-room/components/auscultation-list"
import Layout from "@medaica/common/views/exam/virtual-exam/layout"
import ExamEntryRequest from "views/exam/virtual-exam/exam-room/components/exam-entry-request"
import Notifications from "views/exam/virtual-exam/exam-room/components/notifications"
import { observer } from "mobx-react-lite"
import useGlobalContext from "@medaica/common/hooks/global-context"
import VirtualExamContext, { VirtualExamContextProvider } from "views/exam/virtual-exam/virtual-exam-context"
import useAuth from "hooks/auth"
import { useNavigate } from "react-router-dom"
import VirtualExamRoomUIStore from "views/exam/virtual-exam/exam-room/stores/ui-store"
import VirtualExamStore from "views/exam/virtual-exam/stores/virtual-exam-store"
import URI from "urijs"
import FirebaseClient from "@medaica/common/services/firebase-client"
import FirebaseLiveExamService from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import { logError } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"

const LiveExamView = observer((): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const { preferencesStore, uiStore, mediaDeviceStore } = useContext(VirtualExamContext)
  const { user } = useAuth()
  const navigate = useNavigate()
  const avStoreRef = useRef(new AVStore(mediaDeviceStore))
  const [virtualExamStore, setVirtualExamStore] = useState<VirtualExamStore | undefined>(undefined)

  useEffect(() => {
    let virtualExamStore: VirtualExamStore | undefined
    void (async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const virtualExamId = URI(window.location.href).segment(1)!
        const firebaseAccessToken = await medaicaApiService.firebase.getFirebaseAccessTokenForAuthorizedParticipant(
          virtualExamId
        )
        await FirebaseClient.instance.authenticate(firebaseAccessToken.value)
        const firebaseService = new FirebaseLiveExamService(virtualExamId)

        uiStore.virtualExam = new VirtualExamRoomUIStore()

        virtualExamStore = new VirtualExamStore(
          user.id,
          virtualExamId,
          firebaseService,
          medaicaApiService,
          avStoreRef.current,
          uiStore.virtualExam
        )
        await virtualExamStore.load()

        setVirtualExamStore(virtualExamStore)
      } catch (error) {
        virtualExamStore?.dispose()
        avStoreRef.current.dispose()
        logError(error)
        uiStore.navigate("lobby", { redirectReason: "entryError" })
      }
    })()

    const currentAVStoreRef = avStoreRef.current

    return () => {
      currentAVStoreRef.dispose()
      virtualExamStore?.dispose()
    }
  }, [medaicaApiService, mediaDeviceStore, navigate, preferencesStore, uiStore, user])

  return virtualExamStore ? (
    <VirtualExamContextProvider
      uiStore={uiStore}
      preferencesStore={preferencesStore}
      mediaDeviceStore={mediaDeviceStore}
      virtualExamStore={virtualExamStore}
      avStore={avStoreRef.current}
    >
      <Layout
        overlays={<Notifications />}
        main={
          <div className="flex justify-center w-full space-x-3">
            <ExamEntryRequest />
            <Video />
            <AuscultationList />
          </div>
        }
        footer={<PageControls />}
      />
    </VirtualExamContextProvider>
  ) : (
    <Loading />
  )
})

export default LiveExamView
