/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { createContext, ReactElement, ReactNode, useMemo } from "react"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import InPersonExamStore from "views/exam/in-person-exam/stores/in-person-exam-store"

type InPersonExamContextInterface = {
  inPersonExamStore: InPersonExamStore
  mediaDeviceManager: M1MediaDeviceStore
}
const InPersonExamContext = createContext<InPersonExamContextInterface>(null as unknown as InPersonExamContextInterface)

const InPersonExamContextProvider = ({
  inPersonExamStore,
  mediaDeviceManager,
  children,
}: InPersonExamContextInterface & { children: ReactNode }): ReactElement => {
  const contextProviderValue = useMemo(
    () => ({
      inPersonExamStore,
      mediaDeviceManager,
    }),
    [inPersonExamStore, mediaDeviceManager]
  )
  return <InPersonExamContext.Provider value={contextProviderValue}>{children}</InPersonExamContext.Provider>
}

export default InPersonExamContext
export { InPersonExamContextProvider }
export type { InPersonExamContextInterface }
