/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import { DialogProps } from "@mui/material/Dialog"

const SimpleDialog = ({
  message,
  onClose,
  ...dialogProps
}: {
  message: string | undefined | null
  onClose: () => void
  open?: boolean
} & Omit<DialogProps, "open">): ReactElement => {
  return (
    <Dialog {...dialogProps} open={!!message}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleDialog
