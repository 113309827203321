/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useState } from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import TextInput from "@medaica/common/components/forms/text-input"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Alert, { Mode } from "@medaica/common/components/alert"
import { logError } from "@medaica/common/services/util"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import { useSnackbar } from "notistack"
import PageDialogActions from "@medaica/common/components/dialog/page-dialog-actions"
import LoadingButton from "@mui/lab/LoadingButton"
import { EmailAddressValidator } from "services/yup"

const SendAccessInfoDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: (shouldClose: boolean) => void
}): ReactElement => {
  const [error, setError] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const { virtualExamStore } = useContext(VirtualExamContext)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Dialog open={open} onClose={() => onClose(false)} sx={{ zIndex: 3000 }}>
      <div style={{ minWidth: "34rem" }}>
        <DialogTitle>
          <DialogCloseButton onClick={() => onClose(false)} />
          Send Access Info
        </DialogTitle>
        <Formik
          initialValues={{
            emailAddress: virtualExamStore.patientProfile.emailAddress,
          }}
          validationSchema={Yup.object({
            emailAddress: EmailAddressValidator,
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setError(null)
            try {
              await medaicaApiService.virtualExams.sendInvitation(virtualExamStore.virtualExamId, values.emailAddress)
              enqueueSnackbar("The access information email was successfully sent")
              onClose(true)
            } catch (error) {
              setError("There was an error sending the email. Please try again.")
              logError(error)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ errors, handleBlur, handleChange, touched, isSubmitting }) => (
            <Form>
              <DialogContent>
                <div>
                  {error && (
                    <Alert className="mb-4" mode={Mode.error}>
                      {error}
                    </Alert>
                  )}
                  <TextInput
                    label="Patient's email address"
                    name="emailAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </DialogContent>
              <PageDialogActions>
                <Button color="neutral" variant="outlined" onClick={() => onClose(false)}>
                  Cancel
                </Button>
                <LoadingButton loading={isSubmitting} variant="contained" type="submit">
                  Send email
                </LoadingButton>
              </PageDialogActions>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

export default SendAccessInfoDialog
