/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

const MedaicaLogoNew = ({ width = "134px", height = "27px" }: { width?: string; height?: string }): ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 134 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_680_2444)">
        <path
          d="M81.4875 26.2536C81.7205 26.0179 81.973 25.7821 82.206 25.5268C83.0411 24.5643 83.682 23.7589 84.3617 22.4428C84.5753 22.05 84.7307 21.6767 84.8666 21.3232V13.7999C84.8666 11.482 84.1481 9.77307 82.6915 8.6927C81.235 7.61233 79.293 7.08197 76.846 7.08197C75.7973 7.08197 74.7875 7.16054 73.8359 7.33733C72.8843 7.51412 72.0492 7.78912 71.3113 8.16234C70.8452 8.37841 70.4956 8.63377 70.2626 8.92842C70.0295 9.22306 69.9324 9.59628 69.9324 10.0481C69.9324 10.4213 70.0295 10.7552 70.2043 11.0695C70.3791 11.3838 70.6121 11.6392 70.884 11.8552C71.6414 11.482 72.5153 11.1481 73.4863 10.8534C74.4573 10.5588 75.4672 10.4213 76.5353 10.4213C77.8753 10.4213 78.924 10.6963 79.6814 11.2266C80.4388 11.757 80.8078 12.6213 80.8078 13.7802V14.6642L75.3118 15.2338C73.195 15.4499 71.5831 16.0195 70.4762 16.9624C69.3498 17.9053 68.7866 19.241 68.7866 21.0089C68.7866 22.9732 69.5052 24.4661 70.9423 25.4875C72.3794 26.5089 74.3797 27 76.9237 27C78.3414 27 79.6037 26.8625 80.6718 26.5679C80.9437 26.4107 81.2156 26.3321 81.4875 26.2536ZM76.9043 23.6607C75.506 23.6607 74.4768 23.4053 73.8165 22.9143C73.1562 22.4232 72.826 21.7357 72.826 20.8517C72.826 20.0071 73.1368 19.3589 73.7388 18.9464C74.3408 18.5339 75.2536 18.2589 76.4771 18.1214L80.7884 17.6892V22.7768C80.4971 22.9928 80.0116 23.1893 79.3318 23.3857C78.6327 23.5625 77.8365 23.6607 76.9043 23.6607Z"
          fill="#3B3AEE"
        />
        <path
          d="M22.4909 10.828C21.8382 10.28 20.948 10.0059 19.8205 10.0059C19.0688 10.0059 18.3567 10.143 17.6644 10.417C16.9721 10.691 16.3588 11.0825 15.7852 11.5522C15.8445 11.7675 15.8841 11.9828 15.9039 12.2373C15.9237 12.4722 15.9434 12.7071 15.9434 12.9615V25.8016C15.7456 25.8799 15.4885 25.9582 15.1324 26.0169C14.7764 26.0756 14.4203 26.1148 14.0445 26.1148C13.273 26.1148 12.6796 25.9777 12.3038 25.6841C11.9279 25.3905 11.7301 24.9012 11.7301 24.2161V13.4117C11.7301 12.2373 11.3938 11.3956 10.7213 10.8476C10.0685 10.28 9.17836 10.0059 8.05085 10.0059C7.29917 10.0059 6.58706 10.1234 5.9145 10.3583C5.24195 10.5931 4.6683 10.8672 4.21334 11.1608V25.782C4.01553 25.8603 3.75838 25.9386 3.4221 25.9973C3.08583 26.056 2.70999 26.0952 2.31437 26.0952C1.54291 26.0952 0.949486 25.9582 0.573648 25.6646C0.197809 25.371 0 24.8816 0 24.1966V11.0433C0 10.4366 0.118686 9.94723 0.356057 9.57533C0.63299 9.18387 1.02861 8.81198 1.60226 8.44008C2.37371 7.93118 3.36276 7.50057 4.54962 7.16782C5.73648 6.8155 6.92333 6.65892 8.12997 6.65892C9.39595 6.65892 10.5235 6.83508 11.5323 7.16782C12.5411 7.52014 13.3719 8.00947 14.0049 8.67496C14.7566 8.08777 15.6467 7.59843 16.6753 7.22654C17.704 6.83508 18.9106 6.63934 20.2755 6.63934C21.3239 6.63934 22.2931 6.75678 23.2228 7.01124C24.1328 7.26569 24.924 7.65715 25.5768 8.20521C26.2295 8.75326 26.7438 9.43832 27.1197 10.2604C27.4955 11.0825 27.6933 12.0611 27.6933 13.216V25.7624C27.4757 25.8407 27.1988 25.919 26.8625 25.9777C26.5263 26.0365 26.1504 26.0756 25.7548 26.0756C24.9833 26.0756 24.3899 25.9386 24.0141 25.645C23.6382 25.3514 23.4404 24.8621 23.4404 24.177V13.3726C23.4602 12.2177 23.1437 11.3565 22.4909 10.828Z"
          fill="#3B3AEE"
        />
        <path
          d="M33.7152 18.724C34.0895 20.2395 34.8183 21.381 35.9017 22.1093C36.9852 22.8375 38.3641 23.2115 40.0582 23.2115C41.181 23.2115 42.2053 23.0343 43.1508 22.6997C44.0767 22.3455 44.8252 21.9715 45.3965 21.5779C46.145 22.0109 46.5193 22.6013 46.5193 23.3886C46.5193 23.8413 46.342 24.2546 46.0071 24.6482C45.6526 25.0419 45.1798 25.3765 44.5888 25.652C43.9782 25.9276 43.269 26.1441 42.4614 26.3212C41.6537 26.4786 40.7673 26.5574 39.8415 26.5574C38.2853 26.5574 36.8867 26.3409 35.6063 25.9079C34.3455 25.4749 33.2424 24.8451 32.3363 23.9791C31.4301 23.1327 30.721 22.0699 30.2285 20.8103C29.7361 19.5506 29.48 18.0942 29.48 16.4409C29.48 14.8467 29.7164 13.4296 30.2088 12.2093C30.6816 10.989 31.3513 9.98526 32.1787 9.15862C33.006 8.35166 33.991 7.72185 35.1138 7.28884C36.2366 6.85584 37.4382 6.63934 38.7186 6.63934C39.9991 6.63934 41.181 6.85584 42.225 7.26916C43.2887 7.68248 44.1949 8.25325 44.9434 9.00117C45.7117 9.72939 46.3026 10.6151 46.7163 11.6189C47.13 12.6423 47.3466 13.7445 47.3466 14.9451C47.3466 15.6143 47.1891 16.1063 46.8739 16.4015C46.5587 16.7165 46.0859 16.9133 45.4753 17.0314L33.7152 18.724ZM38.6792 9.90653C37.2018 9.90653 35.9805 10.3986 35.0153 11.3827C34.0304 12.3668 33.5182 13.7839 33.4788 15.6339L43.3478 14.2365C43.2493 13.0359 42.7963 12.0125 42.028 11.1662C41.2401 10.3198 40.1369 9.90653 38.6792 9.90653Z"
          fill="#3B3AEE"
        />
        <path
          d="M58.1549 6.92632C58.967 6.92632 59.7197 7.00414 60.4328 7.17925C61.1459 7.35435 61.7797 7.56837 62.3146 7.84075V0.311295C62.4928 0.252927 62.7701 0.19456 63.1267 0.116736C63.4832 0.0389119 63.8596 0 64.2557 0C65.0282 0 65.6225 0.136192 65.9988 0.428031C66.3752 0.71987 66.5534 1.20627 66.5534 1.88723V22.6467C66.5534 23.211 66.4346 23.6584 66.1969 24.0086C65.9592 24.3589 65.5828 24.7091 65.0678 25.0204C64.4538 25.4095 63.6021 25.7791 62.5523 26.0904C61.4826 26.4017 60.2743 26.5574 58.9076 26.5574C57.3824 26.5574 55.9761 26.3628 54.7084 25.9737C53.4406 25.5846 52.371 25.0009 51.4797 24.1838C50.5883 23.3861 49.9149 22.3549 49.4197 21.1292C48.9443 19.9034 48.6868 18.4637 48.6868 16.7905C48.6868 15.0784 48.9443 13.5803 49.4395 12.3351C49.9347 11.0899 50.6081 10.0587 51.4797 9.24158C52.3314 8.44388 53.3416 7.84075 54.4905 7.45163C55.6195 7.12088 56.8476 6.92632 58.1549 6.92632ZM62.3542 11.3234C61.8986 11.0315 61.3638 10.7786 60.7497 10.5646C60.1357 10.3506 59.4424 10.2533 58.6897 10.2533C57.8776 10.2533 57.1447 10.37 56.4514 10.6035C55.7582 10.837 55.1639 11.2261 54.6489 11.7709C54.1339 12.3156 53.7378 12.9966 53.4406 13.8332C53.1435 14.6698 53.0049 15.6815 53.0049 16.8683C53.0049 19.0863 53.5397 20.7011 54.5895 21.6934C55.6591 22.7051 57.0655 23.1915 58.8482 23.1915C59.6603 23.1915 60.3338 23.1137 60.8884 22.9386C61.443 22.7635 61.9382 22.5689 62.3542 22.316V11.3234Z"
          fill="#3B3AEE"
        />
        <path
          d="M110.861 10.0837C110.005 10.0837 109.208 10.2214 108.47 10.497C107.733 10.7725 107.115 11.2055 106.577 11.7566C106.059 12.3274 105.621 12.9966 105.322 13.8232C105.003 14.6302 104.864 15.5749 104.864 16.6574C104.864 18.7634 105.422 20.3773 106.518 21.4795C107.633 22.5816 109.068 23.1327 110.861 23.1327C111.897 23.1327 112.774 22.995 113.492 22.7391C114.229 22.4635 114.866 22.1486 115.404 21.7747C115.763 21.9912 116.042 22.247 116.261 22.5423C116.48 22.8375 116.58 23.1918 116.58 23.6248C116.58 24.4514 116.042 25.1403 114.946 25.7111C113.85 26.2818 112.416 26.5574 110.622 26.5574C109.128 26.5574 107.773 26.3606 106.537 25.9472C105.302 25.5536 104.246 24.9238 103.349 24.0971C102.453 23.2705 101.755 22.2274 101.257 20.9874C100.759 19.7475 100.5 18.291 100.5 16.6377C100.5 14.9844 100.759 13.528 101.297 12.2684C101.815 11.0087 102.532 9.96558 103.449 9.13894C104.346 8.3123 105.422 7.68248 106.637 7.26916C107.853 6.85584 109.148 6.63934 110.483 6.63934C111.379 6.63934 112.176 6.71807 112.894 6.87553C113.611 7.03298 114.229 7.24948 114.747 7.54471C115.265 7.82025 115.663 8.15485 115.942 8.50912C116.221 8.86339 116.361 9.25703 116.361 9.65067C116.361 10.0246 116.261 10.3592 116.062 10.6741C115.863 10.989 115.604 11.2055 115.305 11.3827C114.727 11.0284 114.109 10.7332 113.412 10.4576C112.734 10.2214 111.878 10.0837 110.861 10.0837Z"
          fill="#3B3AEE"
        />
        <path
          d="M125.97 6.63934C128.42 6.63934 130.383 7.17128 131.822 8.25485C133.28 9.33842 134 11.0327 134 13.3772V22.7156C134 23.3264 133.864 23.7992 133.592 24.1341C133.319 24.469 132.931 24.7843 132.464 25.0798C131.783 25.4738 130.889 25.8284 129.82 26.1239C128.731 26.4195 127.486 26.5574 126.067 26.5574C123.5 26.5574 121.517 26.0451 120.078 25.0404C118.639 24.0159 117.92 22.5186 117.92 20.5485C117.92 18.7754 118.484 17.4357 119.612 16.49C120.739 15.5443 122.353 14.973 124.453 14.7563L129.956 14.1849V13.2984C129.956 12.1163 129.586 11.2692 128.828 10.7372C128.07 10.2053 127.02 9.92946 125.678 9.92946C124.628 9.92946 123.598 10.0674 122.625 10.3629C121.653 10.6584 120.778 10.9933 120.02 11.3677C119.748 11.1509 119.534 10.8948 119.339 10.5796C119.145 10.2644 119.067 9.92946 119.067 9.55514C119.067 9.10201 119.184 8.72768 119.398 8.43216C119.631 8.13664 119.981 7.88053 120.448 7.66381C121.186 7.28949 122.023 7.01367 122.975 6.83636C123.909 6.71815 124.9 6.63934 125.97 6.63934ZM126.047 23.287C126.981 23.287 127.778 23.1884 128.458 23.0111C129.139 22.8338 129.625 22.6171 129.917 22.4004V17.2978L125.6 17.7312C124.395 17.8691 123.481 18.1449 122.859 18.5586C122.256 18.9724 121.945 19.6028 121.945 20.4697C121.945 21.3562 122.275 22.0458 122.936 22.5383C123.617 23.0308 124.647 23.287 126.047 23.287Z"
          fill="#3B3AEE"
        />
        <path
          d="M91.5042 21.373C91.1321 20.6539 90.4664 18.4382 90.2706 16.1837C90.0748 13.832 90.3489 11.4415 90.6622 10.7612C91.0734 9.82831 91.23 9.49791 91.6216 9.07033C91.7195 8.95371 91.2692 8.87597 91.1713 8.85654C89.7223 8.75936 88.6063 10.4114 88.4888 12.0051C88.4496 12.5687 88.43 13.385 88.43 14.2013C88.4104 14.9982 88.4105 15.795 88.3713 16.3975L88.3517 16.9223C88.293 18.0301 88.2538 18.9824 87.8035 20.3235C87.3335 21.7229 86.844 22.6169 86.1587 23.6858C85.6496 24.4827 85.0622 25.1435 84.5139 25.7654C84.279 26.0181 84.2594 27.8839 87.2552 26.4846C88.1755 26.057 89.0174 25.61 89.6636 25.0463C90.5447 24.2883 91.3279 23.3166 92.0133 22.1893C91.8175 21.9366 91.6608 21.6645 91.5042 21.373Z"
          fill="#5CC3AD"
        />
        <path
          d="M101.752 25.7566C101.174 25.1166 100.597 24.4766 100.079 23.6814C99.3818 22.6147 98.8838 21.7226 98.4058 20.3262C97.9477 19.0074 97.9078 18.0571 97.8481 16.9322L97.8281 16.4085C97.7883 15.8073 97.7883 15.0121 97.7684 14.217C97.7485 13.4218 97.7485 12.5878 97.7086 12.0254C97.609 10.5902 96.6928 9.15504 95.4579 8.90291C95.0794 8.82533 94.2827 8.80594 93.7847 9.07746C93.3664 9.31019 92.9083 9.81445 92.4701 10.7648C91.8726 12.0836 91.9722 14.0424 92.1514 15.9819C92.3506 18.0764 92.8685 19.8801 93.6652 21.3541C94.4819 22.8475 95.4379 24.0887 96.5534 25.039C97.2107 25.6015 98.0672 26.0281 99.0033 26.4742C102.011 27.89 101.971 26.0281 101.752 25.7566Z"
          fill="#3B3AEE"
        />
        <path
          d="M94.9168 7.52458C96.5202 7.52458 97.8201 6.23648 97.8201 4.64753C97.8201 3.05858 96.5202 1.77048 94.9168 1.77048C93.3133 1.77048 92.0134 3.05858 92.0134 4.64753C92.0134 6.23648 93.3133 7.52458 94.9168 7.52458Z"
          fill="#3B3AEE"
        />
        <path
          d="M90.4557 4.72324C90.4557 3.58756 90.9562 2.54652 91.9571 2.07332C92.0172 2.03547 92.0372 1.94083 91.9771 1.9219C91.6769 1.82726 91.3566 1.77048 91.0363 1.77048C89.3547 1.77048 87.9934 3.05758 87.9934 4.64753C87.9934 6.23747 89.3547 7.52458 91.0363 7.52458C91.3566 7.52458 91.6568 7.48672 91.9371 7.39208C92.0172 7.37315 92.0172 7.27851 91.9571 7.24066C90.9962 6.63496 90.4557 5.85892 90.4557 4.72324Z"
          fill="#5CC3AD"
        />
      </g>
      <defs>
        <clipPath id="clip0_680_2444">
          <rect width="134" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MedaicaLogoNew
