/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import Table from "@medaica/common/components/table/table"
import Page from "@medaica/common/components/page/page"
import useQuery from "@medaica/common/hooks/query"
import { Typography } from "@mui/material"
import { logError } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"
import { MUIDataTableData } from "mui-datatables"

type HcpData = {
  fullName: string
  displayName: string
  emailAddress: string
  role: string
  totalPatients: number
  totalExams: number
  totalUnreviewedExams: number
}

const HealthcareProviderListView = (): ReactElement => {
  const { setCurrentPage, medaicaApiService } = useGlobalContext()
  const [error, setError] = useState<string>()
  const [hcpData, setHcpData] = useState<HcpData[] | null>(null)
  const [count, setCount] = useState<number>()
  const pageNumber = useQuery("p")

  useEffect(() => {
    setCurrentPage("organization", Paths.organizationHealthcareProviders)
  }, [setCurrentPage])

  const fetchData = useCallback(
    async (pageNumber: number): Promise<void> => {
      try {
        const page = await medaicaApiService.healthcareProviders.getHealthcareProvidersOverview({
          sort: [{ field: "dateCreated", direction: "desc" }],
          pagination: { pageNumber: pageNumber, pageSize: 10 },
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCount(page.totalCount)
        setHcpData(
          page.items.map((item) => {
            return {
              fullName: item.fullName,
              displayName: item.displayName,
              emailAddress: item.emailAddress,
              role: item.roles.length > 1 ? `${item.roles[0]}/${item.roles[1]}` : item.roles[0],
              totalPatients: item.totalPatients,
              totalExams: item.totalExams,
              totalUnreviewedExams: item.totalUnreviewedExams,
            }
          })
        )
      } catch (e) {
        logError(error)
        setError(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    },
    [error, medaicaApiService.healthcareProviders]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])

  function CustomBodyRender({ item, dataIndex }) {
    return (
      <Typography component="span" sx={{ fontWeight: "600" }}>
        {item ? (item[dataIndex].fullName as string) : ""}
      </Typography>
    )
  }

  const commonTableOptions = {
    serverSide: true,
    count: count,
    download: false,
    print: false,
  }

  const columns = [
    {
      name: "Full Name",
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex: string | number) => {
          return <CustomBodyRender item={hcpData} dataIndex={dataIndex} />
        },
      },
    },
    {
      name: "displayName",
      label: "Display name",
    },
    {
      name: "emailAddress",
      label: "Email",
    },
    {
      name: "role",
      label: "Role",
    },
    {
      name: "totalPatients",
      label: "Total patients",
    },
    {
      name: "totalExams",
      label: "Total exams",
    },
    {
      name: "totalUnreviewedExams",
      label: "Unreviewed",
    },
  ]

  return (
    <Page variant="full">
      <Header title="Healthcare Providers" />
      {hcpData && (
        <Table
          data={hcpData as unknown as MUIDataTableData[]}
          title=""
          options={commonTableOptions}
          columns={columns}
        />
      )}
      {error && <div style={{ textAlign: "center" }}>{error}</div>}
      {!hcpData && !error && <Loading />}
    </Page>
  )
}

export default HealthcareProviderListView
