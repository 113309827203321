/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Link } from "react-router-dom"
import Button2 from "@medaica/common/components/Button2"

const ViewDetailsButton = ({ to }: { to: string }): ReactElement => {
  return (
    <Button2 variant="filledTonal" size="small" endIcon={<ChevronRightIcon />} component={Link} to={to}>
      View Details
    </Button2>
  )
}

export default ViewDetailsButton
