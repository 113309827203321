/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import ListenStep from "./components/listen-step"
import SelectPointStep from "./components/select-point-step"
import { observer } from "mobx-react-lite"
import AuscultationRequest from "views/exam/virtual-exam/stores/auscultation-request"
import Switch, { Case } from "react-switch-case"

const NewAuscultationWizard = observer(
  ({ auscultationRequest }: { auscultationRequest: AuscultationRequest }): ReactElement => {
    const [step, setStep] = useState(1)

    return (
      <Switch condition={step}>
        <Case value={1}>
          <SelectPointStep
            auscultationRequest={auscultationRequest}
            onPointSelected={() => {
              setStep(2)
            }}
          />
        </Case>
        <Case value={2}>
          <ListenStep auscultationRequest={auscultationRequest} onNavigateBack={() => setStep(1)} />
        </Case>
      </Switch>
    )
  }
)

export default NewAuscultationWizard
