/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import firebase from "firebase"
import { makeObservable, observable, runInAction } from "mobx"
import { AnyError } from "@medaica/common/types"
import logger from "@medaica/common/services/logging"
import FirebaseVirtualExamService from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"

class AuscultationRequestDataStore {
  private errorRef: firebase.database.Reference
  error: AnyError | null = null
  uploadProgress = 0
  auscultationId: string | null = null
  ref: firebase.database.Reference
  private firebaseVirtualExamService: FirebaseVirtualExamService

  constructor(firebaseVirtualExamService: FirebaseVirtualExamService) {
    makeObservable(this, {
      error: observable,
      uploadProgress: observable,
      auscultationId: observable,
    })
    this.firebaseVirtualExamService = firebaseVirtualExamService
  }

  start(auscultationPointLabel: string): void {
    this.ref = this.firebaseVirtualExamService.auscultationRequestsRef.push({
      auscultationPoint: auscultationPointLabel,
      recordingStartRequest: new Date().toISOString(),
    })
    this.errorRef = this.ref.child("error")

    this.ref.child("error").on("value", (snapshot) => {
      const error = snapshot.val()
      if (error != null) {
        runInAction(() => {
          this.error = error
        })
      }
    })

    this.ref.child("uploadProgress").on("value", (snapshot) => {
      if (snapshot.val()) {
        runInAction(() => {
          this.uploadProgress = snapshot.val() as number
        })
      }
    })

    this.ref.child("auscultationId").on("value", (snapshot) => {
      if (snapshot.val()) {
        runInAction(() => {
          this.auscultationId = snapshot.val()
        })
      }
    })
  }

  setRecordingCancelRequest(): void {
    logger.debug("Requesting auscultation cancel")
    void this.ref.update({
      recordingCancelRequest: new Date().toISOString(),
    })
  }

  setRecordingStopRequest(): void {
    logger.debug("Requesting auscultation stop")
    void this.ref.update({
      recordingStopRequest: new Date().toISOString(),
    })
  }

  setError(error: AnyError): void {
    void this.errorRef.set(error["message"] || error)
  }
}

export default AuscultationRequestDataStore
