/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import { PatientProfile, VirtualExam } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import moment from "moment"
import PageDialogActions from "@medaica/common/components/dialog/page-dialog-actions"
import { LoadingButton } from "@mui/lab"
import useAuth from "hooks/auth"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"

const ScheduleStep = ({
  onScheduled,
  onClose,
}: {
  onScheduled: (virtualExam: VirtualExam) => void
  onClose: () => void
}): ReactElement => {
  const time = 1000 * 60 * 5
  const date = new Date()
  const [fromDate, setFromDate] = useState<Date | null>(new Date(date.getTime() + time - (date.getTime() % time)))
  const [toDate, setToDate] = useState<Date | null>()
  const [patientProfile, setPatientProfile] = useState<PatientProfile | null>(null)
  const [patientsError, setPatientsError] = useState(false)
  const [minToDate, setMinToDate] = React.useState<Date | null>()
  const [fromDateOpen, setFromDateOpen] = useState(false)
  const [toDateOpen, setToDateOpen] = useState(false)
  const [patients, setPatients] = useState<PatientProfile[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()

  useEffect(() => {
    setToDate(moment(fromDate).add(15, "m").toDate())
    setMinToDate(moment(fromDate).add(5, "m").toDate())
  }, [fromDate])

  useEffect(() => {
    const fetchData = async () => {
      const page = await medaicaApiService.patientProfiles.getPatientProfiles({
        filters: [{ field: "organizationId", value: user.organizationId }],
        sort: [{ field: "lastName", direction: "asc" }],
      })
      setPatients(page.items)
    }
    void fetchData()
  }, [medaicaApiService, user])

  const handleScheduleExamButtonClicked = async () => {
    if (!patientProfile) {
      setPatientsError(true)
      return
    }
    if (!fromDate || !toDate) {
      // Can't imagine how we'd get here
      return
    }
    setIsSubmitting(true)
    const virtualExam = await medaicaApiService.virtualExams.createVirtualExam(
      patientProfile.id,
      user.id,
      fromDate,
      toDate
    )
    setIsSubmitting(false)
    onScheduled(virtualExam)
  }

  return (
    <>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Schedule an exam</DialogTitle>
      <DialogContent>
        <div style={{ display: "grid", gridTemplateColumns: "min-content 1fr", gridGap: "8px" }}>
          <div className="flex items-center">
            <PersonOutlineIcon />
          </div>
          <div className="w-full">
            <Autocomplete
              options={patients}
              size="small"
              onChange={(event, value) => {
                setPatientsError(!value)
                setPatientProfile(value)
              }}
              getOptionLabel={(patientProfile: PatientProfile) => patientProfile.fullName}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.fullName}
                  </li>
                )
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  sx={{
                    width: "100%",
                  }}
                  error={patientsError}
                  placeholder="Patient name"
                  name="patient"
                  variant="outlined"
                  onBlur={(e) => {
                    setPatientsError(!e.target.value)
                  }}
                />
              )}
            />
          </div>
          <div className="flex items-center">
            <AccessTimeOutlinedIcon />
          </div>
          <div className="flex items-center space-x-2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                open={fromDateOpen}
                onOpen={() => setFromDateOpen(true)}
                onAccept={() => setFromDateOpen(false)}
                disableOpenPicker
                disablePast
                value={fromDate}
                minutesStep={5}
                onChange={(newValue) => {
                  setFromDate(newValue)
                }}
                renderInput={(params) => <TextField onClick={() => setFromDateOpen(true)} size="small" {...params} />}
              />
            </LocalizationProvider>
            <ArrowForwardIcon />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                open={toDateOpen}
                onOpen={() => setToDateOpen(true)}
                onAccept={() => setToDateOpen(false)}
                disableOpenPicker
                disablePast
                value={toDate}
                minutesStep={5}
                minDateTime={minToDate}
                onChange={(newValue) => {
                  setToDate(newValue)
                }}
                renderInput={(params) => <TextField onClick={() => setToDateOpen(true)} size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
      </DialogContent>
      <PageDialogActions>
        <Button color="neutral" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={() => void handleScheduleExamButtonClicked()}
          variant="contained"
        >
          Schedule exam
        </LoadingButton>
      </PageDialogActions>
    </>
  )
}

export default ScheduleStep
