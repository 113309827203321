/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { ChevronDownIcon } from "@heroicons/react/solid"
import moment from "moment"
import { Auscultation } from "@medaica/common/types"

const AuscultationDetailsContracted = ({
  auscultation,
  onExpand,
}: {
  auscultation: Auscultation
  onExpand: () => void
}): ReactElement => {
  return (
    <div onClick={onExpand} onKeyDown={onExpand} className="panel bg-gray-100 p-1 cursor-pointer">
      <div className="flex flex-row items-start">
        <div className="flex flex-grow flex-row items-center">
          <AuscultationPointImage
            highlightedPoint={auscultation.auscultationPoint}
            className="border h-14 w-14 border-gray-200 rounded-md bg-gray-50"
            style={{ padding: "2px" }}
          />
          <div className="ml-2">
            <div>
              <h4 className="font-semibold header-color flex-grow">{auscultation.auscultationPoint.longLabel}</h4>
              <div className="text-sm text-gray-on-gray">{moment(auscultation.dateCreated).format("lll")}</div>
            </div>
          </div>
        </div>
        <button className="mr-2 mt-1">
          <ChevronDownIcon className="w-6" />
        </button>
      </div>
    </div>
  )
}

export default AuscultationDetailsContracted
