/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import { observer } from "mobx-react-lite"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { Button } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CreateAuscultation from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/create-auscultation-view"
import ReviewAuscultation from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/review-auscultation-view"

const AuscultationList = observer((): ReactElement => {
  const { virtualExamStore, avStore } = useContext(VirtualExamContext)

  const newAuscultationButtonClicked = () => {
    virtualExamStore.addAuscultationRequest()
  }

  return (
    <div className="flex flex-col" style={{ maxWidth: "28rem", width: "26rem", height: "625px" }}>
      <div className="panel bg-white py-4 px-4 flex-grow flex-initial overflow-y-auto">
        <div className="flex justify-between items-center mb-5">
          <h3 className="panel-title mb-0">Auscultations</h3>
          <Button
            size="small"
            variant="contained"
            startIcon={<KeyboardArrowDownIcon />}
            disabled={!avStore.remoteParticipant || virtualExamStore.currentAuscultationRequest !== null}
            onClick={newAuscultationButtonClicked}
            sx={{ ml: 2 }}
          >
            New Auscultation
          </Button>
        </div>
        <div className="mt-7">
          <div className="space-y-2">
            {virtualExamStore.currentAuscultationRequest && (
              <CreateAuscultation
                key={virtualExamStore.currentAuscultationRequest?.id}
                auscultationRequest={virtualExamStore.currentAuscultationRequest}
              />
            )}
            {virtualExamStore.auscultations.map((auscultation) => (
              <ReviewAuscultation key={auscultation.id} auscultation={auscultation} />
            ))}
            {virtualExamStore.hasLoaded &&
              virtualExamStore.auscultations.length === 0 &&
              !virtualExamStore.currentAuscultationRequest && (
                <div className="mt-12">
                  There are no auscultations. Click <strong>New Auscultation</strong> to begin an auscultation.
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default AuscultationList
