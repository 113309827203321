/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Exam } from "@medaica/common/types"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { moveCaretToEnd, logError } from "@medaica/common/services/util"
import { maxCharactersInNote } from "@medaica/common/const"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from "notistack"
import { Fade } from "@mui/material"

const Notes = ({ exam }: { exam: Exam }): ReactElement => {
  const notesRef = useRef<HTMLTextAreaElement>(null)
  const { medaicaApiService } = useGlobalContext()
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [saved, setSaved] = useState(false)
  let saveMessageTimeout: NodeJS.Timeout | undefined

  const handleSaveButtonClicked = async () => {
    setIsSubmitting(true)
    if (notesRef.current) {
      const notesValue = notesRef.current.value
      try {
        await medaicaApiService.exams.updateNotes(exam.id, notesValue)
        setSaved(true)
        if (saveMessageTimeout) {
          clearTimeout(saveMessageTimeout)
        }
        saveMessageTimeout = setTimeout(() => {
          setSaved(false)
        }, 3000)
      } catch (err) {
        enqueueSnackbar(`Failed to save exam notes.`, { variant: "error" })
        logError(err)
      }
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.focus()
      moveCaretToEnd(notesRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesRef.current])

  return (
    <div className="panel">
      <div className="panel-header">Notes</div>
      <div>
        <div style={{ marginBottom: "-5px" }}>
          <textarea
            ref={notesRef}
            style={{ resize: "none", borderBottom: "none" }}
            spellCheck="false"
            className="border-0 w-full h-60 focus:outline-none focus:ring-0 placeholder-gray-400"
            placeholder="Add notes..."
            defaultValue={exam.notes}
            maxLength={maxCharactersInNote}
          />
        </div>
        <div style={{ backgroundColor: "white", paddingLeft: "10px", paddingBottom: "10px" }}>
          <Fade in={saved} timeout={1200}>
            <div className="font-semibold text-gray-600 text-sm" style={{ backgroundColor: "white" }}>
              Notes saved
            </div>
          </Fade>
        </div>
      </div>
      <div>
        <LoadingButton
          loading={isSubmitting}
          onClick={() => void handleSaveButtonClicked()}
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}

export default Notes
