/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

const UserIcon = ({
  height = 24,
  width = 24,
  deemphasize = false,
}: {
  height?: number
  width?: number
  deemphasize?: boolean
}): ReactElement => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {deemphasize ? (
        <>
          <ellipse cx="12" cy="17.5" rx="7" ry="3.5" stroke="#2823EE" strokeWidth="1.5" strokeLinejoin="round" />
          <circle cx="12" cy="7" r="4" stroke="#2823EE" strokeWidth="1.5" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <ellipse opacity="0.4" cx="13" cy="18.4167" rx="7.58333" ry="4.33333" fill="#1A1990" />
          <circle cx="13" cy="7.58333" r="4.33333" fill="#1A1990" />
        </>
      )}
    </svg>
  )
}

export default UserIcon
