/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect } from "react"
import { observer } from "mobx-react-lite"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { useSnackbar } from "notistack"
import { reaction } from "mobx"

const Notifications = observer((): ReactElement => {
  const { avStore, virtualExamStore } = useContext(VirtualExamContext)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    return reaction(
      () => avStore.remoteParticipant,
      (connected) => {
        if (!connected) {
          enqueueSnackbar(`${virtualExamStore.patientProfile.fullName} has disconnected`)
        }
      }
    )
  }, [avStore.remoteParticipant, enqueueSnackbar, virtualExamStore.patientProfile.fullName])

  return <></>
})

export default Notifications
