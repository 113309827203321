/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"

const ErrorDialog = ({ isOpen, errorMessage }: { isOpen: boolean; errorMessage: string | null }): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const cancelButtonClicked = () => {
    virtualExamStore.cancelAuscultationRequest()
  }
  return (
    <Dialog open={isOpen} style={{ zIndex: 9999 }} disableEscapeKeyDown>
      <DialogTitle>Auscultation Error</DialogTitle>
      <DialogContent>{errorMessage}</DialogContent>
      <DialogActions>
        <Button onClick={cancelButtonClicked}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
