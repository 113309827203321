/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { useNavigate, useParams } from "react-router-dom"
import { Paths } from "const"
import Exams from "views/patient/patient-overview/components/exams"
import Page from "@medaica/common/components/page/page"
import { Box } from "@mui/material"
import Details from "views/patient/patient-overview/components/details"
import Button2 from "@medaica/common/components/Button2"
import InPersonExam from "views/exam/in-person-exam"

const PatientView = (): ReactElement => {
  const { patientProfileId } = useParams<{ patientProfileId: string }>()
  const { setCurrentPage } = useGlobalContext()
  const navigate = useNavigate()
  const [inPersonExamDialogIsOpen, setInPersonExamDialogIsOpen] = useState(false)
  const [refreshKeySeed, setRefreshKeySeed] = useState(1)

  useEffect(() => {
    setCurrentPage("patients", Paths.patients)
  }, [setCurrentPage])

  const handleStartVirtualExamButtonClicked = () => {
    navigate(Paths.liveExam, {
      state: {
        patientProfileId: patientProfileId,
      },
    })
  }

  const handleStartInPersonExamButtonClicked = () => {
    setInPersonExamDialogIsOpen(true)
  }

  const setInPersonExamDialogOnClose = () => {
    setInPersonExamDialogIsOpen(false)
    setRefreshKeySeed((refreshKeySeed) => refreshKeySeed + 1)
  }

  return (
    <>
      {inPersonExamDialogIsOpen && (
        <InPersonExam
          isOpen={inPersonExamDialogIsOpen}
          onClose={() => setInPersonExamDialogOnClose()}
          patientProfileId={patientProfileId}
        />
      )}
      <Page variant="full" sx={{ overflow: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: 2, flexGrow: 1, overflow: "hidden" }}>
          <Box style={{ display: "flex", flexDirection: "column", width: "250px" }}>
            <Header backButton={true} title="Patient Details" />
            <Details patientProfileId={patientProfileId} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Header
              title="Exam History"
              actions={
                <>
                  <Button2 onClick={handleStartVirtualExamButtonClicked} variant="outlined" color="primary">
                    Start Virtual Exam
                  </Button2>
                  <Button2 onClick={handleStartInPersonExamButtonClicked} variant="outlined" color="primary">
                    Start In-Person Exam
                  </Button2>
                </>
              }
            />
            <Exams patientProfileId={patientProfileId} key={refreshKeySeed} />
          </Box>
        </Box>
      </Page>
    </>
  )
}

export default PatientView
