/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { logError } from "@medaica/common/services/util"
import { Paths } from "const"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { useNavigate } from "react-router"
import PatientDetailsForm from "views/patient/components/patient-details-form"
import { plainToInstance } from "class-transformer"
import { PatientProfile } from "@medaica/common/types"
import { isMedaicaApiError, MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import SimpleDialog from "@medaica/common/components/dialog/simple-dialog"

const CreatePatientDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const navigate = useNavigate()
  const [error, setError] = useState<string>()

  const handleSubmit = async (values, setSubmitting) => {
    try {
      const patientProfile = await medaicaApiService.patientProfiles.createPatientProfile(
        plainToInstance(PatientProfile, values)
      )
      if (onClose) {
        onClose()
      }
      navigate(`${Paths.patients}/${patientProfile.id}`)
    } catch (error) {
      if (isMedaicaApiError(error) && error.type === MedaicaApiErrorType.DUPLICATE_PATIENT_PROFILE) {
        setError(
          // eslint-disable-next-line max-len
          `A patient with the email address ${values.emailAddress} already exists. Email addresses must be unique.`
        )
      } else {
        logError(error)
        setError("An unexpected error occurred. Our team has been notified and is looking into it. Please try again.")
      }
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <>
      <SimpleDialog message={error} onClose={() => setError(undefined)} />
      {isOpen && (
        <PatientDetailsForm
          title="New patient"
          submitButtonValue="Create new patient"
          onSubmit={handleSubmit}
          onClose={onClose}
          isOpen={isOpen}
        />
      )}
    </>
  )
}

export default CreatePatientDialog
