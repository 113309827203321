/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { FormControl, FormHelperText, InputLabel } from "@mui/material"
import { useNavigate, useOutletContext } from "react-router-dom"
import { ProfileAndSettingsContextType } from "views/profile-and-settings/index"
import * as Yup from "yup"
import { logError } from "@medaica/common/services/util"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-mui"
import FormContainer from "@medaica/common/components/ui/form/form-container"
import ButtonRow from "@medaica/common/components/ui/form/form-actions"
import Button from "@medaica/common/components/ui/button"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import { HealthcareProvider } from "@medaica/common/types"
import Loading from "@medaica/common/components/loading"
import {
  healthcareProviderDisplayNameValidator,
  healthcareProviderFirstNameValidator,
  healthcareProviderLastNameValidator,
} from "services/yup"
import ContentContainer from "views/profile-and-settings/content-container"
import { Paths } from "const"
import SystemErrorAlert from "@medaica/common/components/system-error-alert"

const Edit = (): ReactElement => {
  const { setActions, setCurrentPage } = useOutletContext<ProfileAndSettingsContextType>()
  const navigate = useNavigate()
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const [healthcareProvider, setHealthcareProvider] = useState<HealthcareProvider | null>(null)
  const [isSystemError, setIsSystemError] = useState(false)

  useEffect(() => {
    void (async () => {
      setActions(null)
      setCurrentPage("personalInfo")
      try {
        const healthcareProvider = await medaicaApiService.healthcareProviders.getHealthcareProvider(user.id)
        if (healthcareProvider === null) {
          throw new Error("User not found")
        }
        setHealthcareProvider(healthcareProvider)
      } catch (error) {
        setIsSystemError(true)
        logError(error)
      }
    })()
  }, [user, setActions, setCurrentPage, medaicaApiService])

  return (
    <ContentContainer>
      {healthcareProvider ? (
        <Formik
          initialValues={{
            firstName: healthcareProvider.firstName,
            lastName: healthcareProvider.lastName,
            displayName: healthcareProvider.displayName,
          }}
          validationSchema={Yup.object({
            firstName: healthcareProviderFirstNameValidator.required("First name is required"),
            lastName: healthcareProviderLastNameValidator.required("Last name is required"),
            displayName: healthcareProviderDisplayNameValidator.required("Display name is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await medaicaApiService.healthcareProviders.updateHealthcareProvider(healthcareProvider.id, {
                firstName: values.firstName,
                lastName: values.lastName,
                displayName: values.displayName,
              })
              navigate(Paths.profileAndSettingsPersonalInfo, { state: { updateSuccessful: true } })
            } catch (error) {
              setIsSystemError(true)
              logError(error)
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
            <Form>
              <FormContainer sx={{ width: "380px" }}>
                {isSystemError && (
                  <div>
                    <SystemErrorAlert />
                  </div>
                )}
                <div>
                  <InputLabel htmlFor="firstName">First name</InputLabel>
                  <Field
                    component={TextField}
                    size="small"
                    id="firstName"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="given-name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div>
                  <InputLabel htmlFor="lastName">Last name</InputLabel>
                  <Field
                    component={TextField}
                    size="small"
                    name="lastName"
                    id="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="family-name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div>
                  <InputLabel htmlFor="displayName">Display name</InputLabel>
                  <FormControl>
                    <Field
                      component={TextField}
                      name="displayName"
                      id="displayName"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <FormHelperText>This is how you will be identified to other users, e.g. Dr. Smith.</FormHelperText>
                  </FormControl>
                </div>
                <ButtonRow>
                  <Button
                    size="small"
                    variant="outlined"
                    color="red"
                    onClick={() => navigate(Paths.profileAndSettingsPersonalInfo)}
                  >
                    Cancel
                  </Button>
                  <Button loading={isSubmitting} size="small" type="submit">
                    Submit
                  </Button>
                </ButtonRow>
              </FormContainer>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </ContentContainer>
  )
}

export default Edit
