/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { observer } from "mobx-react-lite"
import { AuscultationPoint } from "@medaica/common/types"
import InPersonExamContext from "views/exam/in-person-exam/in-person-exam-context"
import { Alert, AlertTitle, Box, Button, DialogActions, DialogContent } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Recorder from "@medaica/common/services/recorder"
import ProgressCircle from "@medaica/common/components/progress-circle"
import Timer from "@medaica/common/services/timer"
import VolumeMeter from "@medaica/common/components/volume-meter"
import SimpleDialog from "@medaica/common/components/dialog/simple-dialog"
import { AuscultationData } from "views/exam/in-person-exam/components/new-auscultation-wizard"
import NoiseQualityRecorder from "@medaica/common/components/audio-player/noise-quality-recorder"
import BadNoiseDetector from "@medaica/common/components/audio-player/aquf/bad-noise-detector"
import Phonocardiogram2 from "@medaica/common/components/phonocardiogram2"
import NoiseFilter from "@medaica/common/components/audio-player/noise-filter"
import { logError } from "@medaica/common/services/util"
import logger from "@medaica/common/services/logging"

const StethoscopeDisconnectedNotification = () => {
  return (
    <div className="w-full">
      <Alert
        severity="warning"
        sx={{
          "& .MuiAlert-message": {
            width: "100%",
          },
        }}
      >
        <AlertTitle>Stethoscope Not Detected</AlertTitle>

        <div className="text-base">
          <p>The Medaica M1 Stethoscope could not be detected. Please ensure that the stethoscope is plugged in.</p>
        </div>
      </Alert>
    </div>
  )
}

const AQUFPhonocardiogram = observer(
  ({
    badNoiseDetector,
    bodyMicStream,
  }: {
    badNoiseDetector: BadNoiseDetector
    bodyMicStream: MediaStream
  }): ReactElement => {
    return (
      <Box
        className="panel"
        sx={{
          backgroundColor: !badNoiseDetector.isBadNoiseDetected ? "rgb(0, 0, 0)" : "rgb(255, 0, 0, 0.4)",
        }}
      >
        <Phonocardiogram2 stream={bodyMicStream} />
      </Box>
    )
  }
)

const ListenView = observer(
  ({
    auscultationPoint,
    onAuscultationProcessed,
    onBackButtonClicked,
    onClose,
  }: {
    auscultationPoint: AuscultationPoint
    onAuscultationProcessed: (auscultationData: AuscultationData) => void
    onBackButtonClicked: () => void
    onClose: () => void
  }): ReactElement => {
    const { mediaDeviceManager } = useContext(InPersonExamContext)
    const [error, setError] = useState<string>()
    const [isRecording, setIsRecording] = useState(false)
    const [mediaRecorder, setMediaRecorder] = useState<Recorder>()
    const timerRef = useRef(new Timer(60 * 1000, 100))
    const [roomMicVolumeProvider, setRoomMicVolumeProvider] = useState<(() => number) | null>(null)
    const [badNoiseDetector, noiseQualityRecorder] = useMemo(() => {
      const badNoiseDetector = new BadNoiseDetector(new NoiseFilter())
      const noiseQualityRecorder = new NoiseQualityRecorder(badNoiseDetector)
      return [badNoiseDetector, noiseQualityRecorder]
    }, [])

    useEffect(() => {
      mediaDeviceManager.start().catch(logError)
    }, [mediaDeviceManager])

    useEffect(() => {
      if (mediaDeviceManager.isM1Available) {
        mediaDeviceManager.connectToM1().catch(logError)
      }
    }, [mediaDeviceManager, mediaDeviceManager?.isM1Available])

    useEffect(() => {
      if (mediaDeviceManager.m1) {
        const m1 = mediaDeviceManager.m1
        badNoiseDetector.start(m1.recordingStream)
        setRoomMicVolumeProvider(() => () => m1.getVolume())
        m1.enableAuscultationMicOnLocalSpeakers()
      } else {
        badNoiseDetector.stop()
        setRoomMicVolumeProvider(null)
        setIsRecording(false)
        noiseQualityRecorder.stop()
        timerRef.current.reset()
      }
      return () => {
        if (mediaDeviceManager.m1) {
          mediaDeviceManager.m1.disableAuscultationMicOnLocalSpeakers()
        }
        badNoiseDetector.stop()
      }
    }, [badNoiseDetector, mediaDeviceManager.m1, noiseQualityRecorder])

    const startRecording = async () => {
      if (!mediaDeviceManager.m1) {
        return
      }
      setIsRecording(true)
      noiseQualityRecorder.start()
      const deviceLabel = mediaDeviceManager.m1.label
      const mediaRecorder = new Recorder()

      mediaRecorder.onRecordingComplete = (recording, mimeType) => {
        timerRef.current.reset()
        if (recording.size > 0) {
          onAuscultationProcessed({
            recording,
            mimeType,
            auscultationPoint,
            deviceLabel,
            noiseQualityRecord: noiseQualityRecorder.getResult(),
          })
        } else {
          setErrorDialog()
        }
        cleanupAudioComponents()
      }

      mediaRecorder.onError = (error) => {
        setErrorDialog()
        timerRef.current.reset()
        cleanupAudioComponents()
      }

      setMediaRecorder(mediaRecorder)
      await mediaRecorder.start(mediaDeviceManager.m1.recordingStream)
      timerRef.current.start()
    }

    const stopRecording = useCallback(() => {
      setIsRecording(false)
      noiseQualityRecorder.stop()
      mediaRecorder?.stop()
    }, [mediaRecorder, noiseQualityRecorder])

    // Fires when the timer completes
    useEffect(() => {
      if (timerRef.current.completed && mediaRecorder) {
        if (mediaRecorder.state === "recording") {
          stopRecording()
        }
      }
    }, [timerRef.current.completed, mediaRecorder, stopRecording])

    const cleanupAudioComponents = () => {
      try {
        mediaDeviceManager.dispose()
        mediaRecorder?.dispose()
        noiseQualityRecorder.stop()
        badNoiseDetector.stop()
      } catch (error) {
        logger.error("Error while clean up audio components:", error)
      }
    }

    const handleBackButtonClick = () => {
      cleanupAudioComponents()
      onBackButtonClicked()
    }

    const handleClose = () => {
      cleanupAudioComponents()
      onClose()
    }

    const setErrorDialog = () => {
      setError("There was an error during the auscultation. The auscultation has been cancelled. Please try again.")
      setIsRecording(false)
    }

    return (
      <>
        <DialogContent>
          <SimpleDialog message={error} maxWidth="xs" onClose={() => setError(undefined)} />
          {mediaDeviceManager.isM1Available ? (
            <div className="my-7 flex-col panel bg-gray-100 p-3">
              <div className="grid grid-cols-3 grid-rows-2 gap-3">
                <div
                  className="relative flex bg-white rounded-md p-1 justify-center items-center border"
                  style={{ gridRowStart: 1, gridRowEnd: 3 }}
                >
                  <AuscultationPointImage
                    style={{ width: "100%", height: "100%" }}
                    highlightedPoint={auscultationPoint}
                  />
                </div>
                <div
                  className="relative bg-white rounded-md flex items-center justify-center border"
                  style={{ gridRowStart: 1, gridRowEnd: 3 }}
                >
                  <div className="w-36">
                    <ProgressCircle timer={timerRef.current} />
                  </div>
                </div>
                {badNoiseDetector && mediaDeviceManager.m1 && (
                  <AQUFPhonocardiogram
                    badNoiseDetector={badNoiseDetector}
                    bodyMicStream={mediaDeviceManager.m1.recordingStream}
                  />
                )}
                <div className="bg-white rounded-md flex justify-center items-center border relative">
                  <div className="absolute text-xs top-1 left-2">Room sound</div>
                  <VolumeMeter on={true} volumeProvider={roomMicVolumeProvider} />
                </div>
              </div>
              <div className="text-sm text-gray-500 text-right">{mediaDeviceManager.m1?.label}</div>

              <div className="mt-5 mb-2 flex justify-center">
                {!isRecording ? (
                  <Button
                    onClick={() => {
                      startRecording().catch(logError)
                    }}
                    variant="contained"
                  >
                    Start Auscultation
                  </Button>
                ) : (
                  <Button variant="contained" color="error" onClick={stopRecording}>
                    Stop Auscultation
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <StethoscopeDisconnectedNotification />
          )}
        </DialogContent>
        <DialogActions>
          <div className="w-full flex">
            <div className="flex-grow">
              <Button color="neutral" startIcon={<ChevronLeftIcon />} onClick={handleBackButtonClick}>
                Back
              </Button>
            </div>
            <Button color="neutral" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </>
    )
  }
)

export default ListenView
