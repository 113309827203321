/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import { Box, Divider, Stack } from "@mui/material"

const DetailsList = ({ children }: { children: ReactNode }): ReactElement => (
  <Stack direction="column" divider={<Divider orientation="horizontal" flexItem />}>
    {children}
  </Stack>
)

const DetailGroup = ({ children }: { children: ReactNode }): ReactElement => <Box>{children}</Box>

const DetailLabel = ({ children }: { children: ReactNode }): ReactElement => (
  <dt
    style={{
      margin: "16px 24px 0",
      color: "#757575",
    }}
  >
    {children}
  </dt>
)

const DetailValue = ({ children }: { children: ReactNode }): ReactElement => (
  <dd
    style={{
      margin: "0 24px 16px",
      fontWeight: 500,
    }}
  >
    {children}
  </dd>
)

export { DetailLabel, DetailGroup, DetailsList, DetailValue }
