/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import * as Yup from "yup"
import isEmail from "validator/lib/isEmail"

const nameSpecialCharValidator = /^[\p{L}.,'\s-]+$/u

const healthcareProviderFirstNameValidator = Yup.string()
  .max(100, "First name may not exceed 100 characters")
  .matches(nameSpecialCharValidator, "First name may not contain special characters")

const healthcareProviderLastNameValidator = Yup.string()
  .max(100, "Last name may not exceed 100 characters")
  .matches(nameSpecialCharValidator, "Last name may not contain special characters")

const healthcareProviderDisplayNameValidator = Yup.string()
  .max(100, "Display name may not exceed 100 characters")
  .matches(nameSpecialCharValidator, "Display name may not contain special characters")

const healthcareProviderPasswordValidator = Yup.string()
  .max(128, "Password may not exceed 128 characters")
  .min(10, "Password must be at least 10 characters")

const EmailAddressValidator = Yup.string()
  .max(120, "Email address may not exceed 120 characters.")
  .required("Email address is required.")
  .test({
    name: "isValidEmail",
    message: "Invalid email address.",
    test: (value: string | undefined): boolean => {
      if (value === undefined) {
        return false
      }
      return isEmail(value)
    },
  })

export {
  healthcareProviderFirstNameValidator,
  healthcareProviderLastNameValidator,
  healthcareProviderDisplayNameValidator,
  healthcareProviderPasswordValidator,
  EmailAddressValidator,
}
