/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import Page from "@medaica/common/components/page/page"
import Button from "@medaica/common/components/ui/button"
import Paper from "@medaica/common/components/ui/paper"
import Header from "@medaica/common/components/page/page-header"

const variants: ("contained" | "outlined" | "text")[] = ["contained", "outlined", "text"]
const colors: ("primary" | "secondary" | "red")[] = ["primary", "secondary", "red"]
const sizes: ("large" | "small")[] = ["large", "small"]

const Design = (): ReactElement => {
  return (
    <Page>
      <Header title="Buttons" />
      <Paper p={2}>
        <table style={{ borderSpacing: "20px", borderCollapse: "separate" }}>
          <thead>
            <tr>
              <th>Default</th>
              <th>Disabled</th>
              <th>size (default: large)</th>
              <th>variant (default: contained)</th>
              <th>color (default: primary)</th>
            </tr>
          </thead>
          <tbody>
            {sizes.map((size) => {
              return variants.map((variant) => {
                return colors.map((color) => {
                  return (
                    <tr key={`${variant}-${color}`}>
                      <td>
                        <Button variant={variant} color={color} size={size}>
                          Button
                        </Button>
                      </td>
                      <td>
                        <Button variant={variant} color={color} size={size} disabled={true}>
                          Button
                        </Button>
                      </td>
                      <td>{size}</td>
                      <td>{variant}</td>
                      <td>{color}</td>
                    </tr>
                  )
                })
              })
            })}
          </tbody>
        </table>
      </Paper>
    </Page>
  )
}

export default Design
