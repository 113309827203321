/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { Box, Divider } from "@mui/material"
import moment from "moment"
import { getAge } from "@medaica/common/services/util"
import Button2 from "@medaica/common/components/Button2"
import { DetailGroup, DetailLabel, DetailsList, DetailValue } from "@medaica/common/components/DetailsList"
import UpdatePatientProfileDialog from "views/patient/update-patient-dialog"
import { PatientProfile } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Loading from "@medaica/common/components/loading"

const Details = ({ patientProfileId }: { patientProfileId: string }): ReactElement => {
  const [patientProfile, setPatientProfile] = useState<PatientProfile | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)

  const loadData = useCallback(async () => {
    const patientProfile = await medaicaApiService.patientProfiles.getPatientProfile(patientProfileId)
    setPatientProfile(patientProfile)
  }, [medaicaApiService, patientProfileId])

  useEffect(() => {
    void loadData()
  }, [loadData])

  return patientProfile ? (
    <>
      {editDialogIsOpen && (
        <UpdatePatientProfileDialog
          isOpen={editDialogIsOpen}
          onClose={() => setEditDialogIsOpen(false)}
          patientProfile={patientProfile}
          onUpdated={() => void loadData()}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          borderColor: "#f0f0fb",
          borderWidth: "1px",
          borderRadius: "4px",
          backgroundColor: "#fff",
        }}
      >
        <DetailsList>
          <DetailGroup>
            <DetailLabel>Name</DetailLabel>
            <DetailValue>
              {patientProfile.firstName} {patientProfile.lastName}
            </DetailValue>
          </DetailGroup>
          <DetailGroup>
            <DetailLabel>Date of birth</DetailLabel>
            <DetailValue>
              {patientProfile.birthdate ? (
                `${moment(patientProfile.birthdate).format("ll")} (${getAge(patientProfile.birthdate)})`
              ) : (
                <em>unknown</em>
              )}
            </DetailValue>
          </DetailGroup>
          <DetailGroup>
            <DetailLabel>Sex</DetailLabel>
            <DetailValue>
              {patientProfile.sex ? <span className="capitalize">{patientProfile.sex}</span> : <em>Unknown</em>}
            </DetailValue>
          </DetailGroup>
          <DetailGroup>
            <DetailLabel>Email</DetailLabel>
            <DetailValue>{patientProfile.emailAddress}</DetailValue>
          </DetailGroup>
          <DetailGroup>
            <DetailLabel>Profile created</DetailLabel>
            <DetailValue>{moment(patientProfile.dateCreated).format("lll")}</DetailValue>
          </DetailGroup>
          <DetailGroup>
            <DetailLabel>Primary doctor</DetailLabel>
            <DetailValue>{patientProfile.healthcareProvider?.displayName}</DetailValue>
          </DetailGroup>
        </DetailsList>
        <Divider />
        <Box m="20px" style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "end" }}>
          <Button2 variant="filledTonal" size="small" onClick={() => setEditDialogIsOpen(true)}>
            Update profile
          </Button2>
        </Box>
      </Box>
    </>
  ) : (
    <Loading />
  )
}

export default Details
