/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import FormContainer from "@medaica/common/components/ui/form/form-container"
import ContentContainer from "views/profile-and-settings/content-container"
import Button from "@medaica/common/components/ui/button"
import { useOutletContext } from "react-router-dom"
import { ProfileAndSettingsContextType } from "views/profile-and-settings/index"
import ButtonRow from "@medaica/common/components/ui/form/form-actions"
import { logError } from "@medaica/common/services/util"
import useAuth from "hooks/auth"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

const Password = (): ReactElement => {
  const { user } = useAuth()
  const { setActions, setCurrentPage } = useOutletContext<ProfileAndSettingsContextType>()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  useEffect(() => {
    setActions(null)
    setCurrentPage("password")
  }, [setActions, setCurrentPage])

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState)
  }

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation((prevState) => !prevState)
  }

  return (
    <ContentContainer>
      <Formik
        initialValues={{
          newPassword: "",
          passwordConfirmation: "",
        }}
        validationSchema={Yup.object({
          newPassword: Yup.string()
            .max(128, "Password must be fewer than 128 characters")
            .min(10, "Password must be at least 10 characters")
            .required("New password is required"),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref("newPassword"), ""], "Passwords must match")
            .required("You must confirm your password"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            setIsSuccessful(false)
            setErrorMessage(null)
            await medaicaApiService.healthcareProviders.updateHealthcareProviderPassword(values.newPassword, user.id)
            setIsSuccessful(true)
            resetForm()
          } catch (error) {
            const generalErrorMessage = "There was an error setting your password. Please try again later."

            const medaicaErrorMessages = {
              [MedaicaApiErrorType.PASSWORD_SAME_AS_PREVIOUS]:
                "The password you entered is the same as your previous password. Please choose a new password.",
              [MedaicaApiErrorType.PASSWORD_TOO_SHORT]:
                "The password you entered is too short. Please ensure that the password is at least 10 characters.",
              [MedaicaApiErrorType.PASSWORD_TOO_COMMON]:
                "The password you entered is too common. Please enter a less commonly used password.",
              [MedaicaApiErrorType.PASSWORD_CONTAINS_USER_INFORMATION]:
                "The password contains user information. Please change your password.",
              [MedaicaApiErrorType.AUTH0_UNKNOWN_ERROR]:
                "There was an error setting your password. Please contact administrator.",
            }
            setErrorMessage((medaicaErrorMessages[error.type] ?? generalErrorMessage) as string)
            logError(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <FormContainer sx={{ width: "380px" }}>
              {errorMessage && (
                <Alert severity="error" aria-label="submisson error">
                  {errorMessage}
                </Alert>
              )}
              {isSuccessful && (
                <Alert severity="success" aria-label="submisson error">
                  You successfully updated your password
                </Alert>
              )}
              <Box>
                <InputLabel htmlFor="newPassword">New password</InputLabel>
                <FormControl variant="outlined">
                  <Field onBlur={handleBlur} onChange={handleChange} errors={errors} touched={touched}>
                    {() => {
                      return (
                        <OutlinedInput
                          name="newPassword"
                          id="newPassword"
                          type={showPassword ? "text" : "password"}
                          size="small"
                          autoComplete="off"
                          onBlur={handleBlur}
                          error={!!errors.newPassword && !!touched.newPassword}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                aria-label="toggle password visibility"
                                tabIndex={-1}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                  </Field>
                  {errors.newPassword && touched.newPassword && (
                    <FormHelperText sx={{ color: "error.main" }}>{errors.newPassword}</FormHelperText>
                  )}
                  <FormHelperText>The password must be at least 10 characters long.</FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <InputLabel htmlFor="passwordConfirmation">Confirm password</InputLabel>
                <FormControl variant="outlined">
                  <Field>
                    {() => {
                      return (
                        <OutlinedInput
                          name="passwordConfirmation"
                          id="passwordConfirmation"
                          type={showPasswordConfirmation ? "text" : "password"}
                          size="small"
                          autoComplete="off"
                          onBlur={handleBlur}
                          error={!!errors.passwordConfirmation && !!touched.passwordConfirmation}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordConfirmationVisibility}
                                aria-label="toggle password visibility"
                                tabIndex={-1}
                              >
                                {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                  </Field>
                  {errors.passwordConfirmation && touched.passwordConfirmation && (
                    <FormHelperText sx={{ color: "error.main" }}>{errors.passwordConfirmation}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <ButtonRow>
                <Button loading={isSubmitting} size="small" type="submit">
                  Submit
                </Button>
              </ButtonRow>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </ContentContainer>
  )
}

export default Password
