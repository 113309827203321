/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { AuscultationPoint, PatientProfile } from "@medaica/common/types"
import Loading from "@medaica/common/components/loading"
import { classNames, logError } from "@medaica/common/services/util"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"
// import DatePicker from "@medaica/common/components/DatePicker"
import useAuth from "hooks/auth"
import { auscultationPoints, AuscultationPointType } from "@medaica/common/const"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { ConnectedFocusError } from "focus-formik-error"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import PageDialogActions from "@medaica/common/components/dialog/page-dialog-actions"
import PageDialog from "@medaica/common/components/dialog/page-dialog"
import PageDialogTitle from "@medaica/common/components/dialog/page-dialog-title"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from "notistack"

type Point = {
  point: AuscultationPoint
  isSelected: boolean
}

const CreateSelfExamModal = ({
  isOpen,
  onCloseRequested,
  patientProfileId,
}: {
  isOpen: boolean
  onCloseRequested: () => void
  patientProfileId: string
}): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [patientProfile, setPatientProfile] = useState<PatientProfile | null>(null)
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()

  const [heartPoints, setHeartPoints] = useState(
    auscultationPoints.heart.map((point) => {
      return {
        point: point,
        isSelected: false,
      }
    })
  )

  const [lungPoints, setLungPoints] = useState(
    auscultationPoints.lungs.map((point) => {
      return {
        point: point,
        isSelected: false,
      }
    })
  )
  const [created, setCreated] = useState(false)

  const handleAuscultationPointClicked = (auscultationPoint: AuscultationPoint) => {
    const [collection, setter] =
      auscultationPoint.type === AuscultationPointType.heart
        ? [heartPoints, setHeartPoints]
        : [lungPoints, setLungPoints]
    setter(
      collection.map((item) => {
        return item.point.label === auscultationPoint.label
          ? ({
              ...item,
              isSelected: !item.isSelected,
            } as Point)
          : (item as Point)
      })
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const patientProfile = await medaicaApiService.patientProfiles.getPatientProfile(patientProfileId)
      setPatientProfile(patientProfile)
    }

    if (isOpen) {
      void fetchData()
    }
  }, [isOpen, medaicaApiService, patientProfileId])

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      await medaicaApiService.patientProfiles.createSelfExamRequest(
        user.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        patientProfile!.id,
        values.examDate as Date,
        values.instructions as string,
        values.auscultationPoints as string[]
      )
      setCreated(true)
    } catch (error) {
      logError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const successDialogCloseClicked = () => {
    enqueueSnackbar("The self exam was successfully created")
    if (onCloseRequested) {
      onCloseRequested()
      setSuccessDialogIsOpen(false)
    }
  }

  return created ? (
    <Dialog open={successDialogIsOpen}>
      <DialogTitle>Self Exam Created</DialogTitle>
      <DialogContent>The patient will receive a secure link to the self exam.</DialogContent>
      <DialogActions>
        <Button onClick={successDialogCloseClicked}>Close</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <PageDialog open={isOpen} onClose={onCloseRequested}>
      {patientProfile ? (
        <Formik
          initialValues={{
            //examDate: null,
            instructions: null,
            auscultationPoints: [],
          }}
          validationSchema={Yup.object({
            //examDate: Yup.string().nullable().required("An exam date is required"),
            auscultationPoints: Yup.array().min(1, "You must select at least one auscultation point"),
          })}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <ConnectedFocusError />
              <PageDialogTitle>
                <DialogCloseButton onClick={onCloseRequested} />
                Create Self Exam
              </PageDialogTitle>
              <DialogContent>
                <div>
                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label htmlFor="patientName" className="block font-medium text-gray-700">
                        Patient Name
                      </label>
                      <div id="patientName" className="mt-1 text-md">
                        {patientProfile.firstName + " " + patientProfile.lastName}
                      </div>
                    </div>

                    {/*<div className="sm:col-span-4">*/}
                    {/*  <label htmlFor="username" className="block font-medium text-gray-700">*/}
                    {/*    Self exam date*/}
                    {/*  </label>*/}
                    {/*  <div className="mt-1">*/}
                    {/*    <DatePicker*/}
                    {/*      autoComplete="off"*/}
                    {/*      className="input"*/}
                    {/*      wrapperClassName="w-auto"*/}
                    {/*      closeOnScroll={true}*/}
                    {/*      name="examDate"*/}
                    {/*      monthsShown={2}*/}
                    {/*      minDate={new Date()}*/}
                    {/*    />*/}
                    {/*    <ErrorMessage name="examDate">*/}
                    {/*      {(msg) => (*/}
                    {/*        <span className="mt-1 block text-red-600" id="examDate-errorMessage">*/}
                    {/*          {msg}*/}
                    {/*        </span>*/}
                    {/*      )}*/}
                    {/*    </ErrorMessage>*/}
                    {/*  </div>*/}
                    {/*  <p className="mt-2 text-gray-500">*/}
                    {/*    The patient will receive an email with a link to the self-exam on the specified day.*/}
                    {/*  </p>*/}
                    {/*</div>*/}

                    <div className="sm:col-span-6">
                      <label htmlFor="about" className="block font-medium text-gray-700">
                        Additional self exam instructions
                      </label>
                      <div className="mt-1">
                        <Field
                          as="textarea"
                          id="instructions"
                          name="instructions"
                          rows={3}
                          className="input block w-full"
                          defaultValue=""
                        />
                      </div>
                      <p className="mt-2 text-gray-500">
                        The patient will receive instructions on how to conduct a self exam. You may include any
                        additional instructions here.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="my-6">
                  <Divider />
                </div>

                <div>
                  <div>
                    <h3 className="panel-title">Auscultation Points</h3>
                    <p className="mt-1 text-gray-500">Select one or more auscultation points.</p>
                    <ErrorMessage name="auscultationPoints">
                      {(msg) => (
                        <div className="mt-2">
                          <span className="text-red-600" id="auscultationPoints-errorMessage">
                            {msg}
                          </span>
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <dl className="mt-4">
                      <dt className="mt-2">Heart</dt>
                      <dd className="mt-2">
                        <ol className="flex space-x-2">
                          {heartPoints.map((point) => (
                            <li key={point.point.label}>
                              <label>
                                <div className={classNames("flex panel", point.isSelected ? "bg-primary-100" : "")}>
                                  <div className="flex justify-center items-center w-16">
                                    <Field
                                      type="checkbox"
                                      name="auscultationPoints"
                                      value={point.point.label}
                                      onClick={() => handleAuscultationPointClicked(point.point)}
                                      checked={point.isSelected}
                                      className="checkbox"
                                    />
                                  </div>
                                  <AuscultationPointImage style={{ width: "62px" }} highlightedPoint={point.point} />
                                </div>
                              </label>
                            </li>
                          ))}
                        </ol>
                      </dd>
                      <dt className="mt-6">Lungs</dt>
                      <dd className="mt-2">
                        <ol className="flex space-x-2">
                          {lungPoints.map((point) => (
                            <li key={point.point.label}>
                              <label>
                                <div className={classNames("flex panel", point.isSelected ? "bg-primary-100" : "")}>
                                  <div className="flex justify-center items-center w-16">
                                    <Field
                                      name="auscultationPoints"
                                      type="checkbox"
                                      onClick={() => handleAuscultationPointClicked(point.point)}
                                      value={point.point.label}
                                      checked={point.isSelected}
                                      className="checkbox"
                                    />
                                  </div>
                                  <AuscultationPointImage style={{ width: "62px" }} highlightedPoint={point.point} />
                                </div>
                              </label>
                            </li>
                          ))}
                        </ol>
                      </dd>
                    </dl>
                  </div>
                </div>
              </DialogContent>
              <PageDialogActions>
                <Button onClick={onCloseRequested} variant="outlined">
                  Cancel
                </Button>
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  Save
                </LoadingButton>
              </PageDialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <Loading />
      )}
    </PageDialog>
  )
}

export default CreateSelfExamModal
