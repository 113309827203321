/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import SelectPointStep from "views/exam/in-person-exam/components/new-auscultation-wizard/components/select-point-step"
import ListenStep from "views/exam/in-person-exam/components/new-auscultation-wizard/components/listen-step"
import ReviewStep from "views/exam/in-person-exam/components/new-auscultation-wizard/components/review-step"
import { AuscultationPoint } from "@medaica/common/types"
import { Dialog, DialogTitle, Step, StepLabel, Stepper } from "@mui/material"
import Switch, { Case } from "react-switch-case"
import { NoiseQualityRecord } from "@medaica/common/components/audio-player/noise-quality-recorder"

const steps = ["Select auscultation point", "Auscultate", "Review"]

type AuscultationData = {
  recording: Blob
  mimeType: string
  auscultationPoint: AuscultationPoint
  deviceLabel: string
  noiseQualityRecord: NoiseQualityRecord
}

const NewAuscultationWizard = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): ReactElement => {
  const [step, setStep] = useState(0)
  const [auscultationPoint, setAuscultationPoint] = useState<AuscultationPoint | undefined>(undefined)
  const [auscultationData, setAuscultationData] = useState<AuscultationData | null>(null)

  const handleAuscultationPointSelected = (auscultationPoint: AuscultationPoint) => {
    setAuscultationPoint(auscultationPoint)
    setStep(1)
  }

  const handleAuscultationProcessed = (auscultationData: AuscultationData): void => {
    setAuscultationData(auscultationData)
    setStep(2)
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <div style={{ minWidth: "420px" }}>
        <DialogTitle>
          <div className="text-center">New Auscultation</div>
        </DialogTitle>
        <div className="mt-2 border p-4 bg-gray-100">
          <Stepper activeStep={step}>
            {steps.map((label, index) => {
              return (
                <Step key={label} completed={step > index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </div>
        <Switch condition={step}>
          <Case value={0}>
            <SelectPointStep
              onAuscultationPointSelected={handleAuscultationPointSelected}
              selectedAuscultationPoint={auscultationPoint}
              onClose={onClose}
            />
          </Case>
          <Case value={1}>
            {auscultationPoint && (
              <ListenStep
                auscultationPoint={auscultationPoint}
                onClose={onClose}
                onAuscultationProcessed={handleAuscultationProcessed}
                onBackButtonClicked={() => setStep(0)}
              />
            )}
          </Case>
          <Case value={2}>
            {auscultationData && (
              <ReviewStep
                auscultationData={auscultationData}
                onBackButtonClicked={() => setStep(1)}
                onClose={onClose}
              />
            )}
          </Case>
        </Switch>
      </div>
    </Dialog>
  )
}

export default NewAuscultationWizard
export type { AuscultationData }
