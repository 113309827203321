/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { AuscultationPoint } from "@medaica/common/types"
import { Button, DialogActions, DialogContent } from "@mui/material"
import AuscultationPointMenu from "@medaica/common/components/auscultation-point-menu"

const SelectPointView = ({
  selectedAuscultationPoint,
  onAuscultationPointSelected,
  onClose,
}: {
  selectedAuscultationPoint?: AuscultationPoint
  onAuscultationPointSelected: (AuscultationPoint) => void
  onClose: () => void
}): ReactElement => {
  const handleAuscultationPointButtonClicked = (auscultationPoint: AuscultationPoint) => {
    onAuscultationPointSelected(auscultationPoint)
  }
  return (
    <>
      <DialogContent>
        <div className="flex justify-center">
          <AuscultationPointMenu
            boxSize="8em"
            selectedAuscultationPoint={selectedAuscultationPoint}
            onAuscultationPointSelected={handleAuscultationPointButtonClicked}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="neutral" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default SelectPointView
