/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import Page from "@medaica/common/components/page/page"
import Header from "@medaica/common/components/page/page-header"
import PageContent from "@medaica/common/components/page/page-content"
import { Calendar, DateLocalizer, Event, momentLocalizer, View } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Button } from "@mui/material"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { Paths } from "const"
import ScheduleExamDialog from "views/exam/virtual-exam-calendar/schedule-exam-wizard"
import useAuth from "hooks/auth"
import { useNavigate } from "react-router-dom"
import CalendarEvent from "views/exam/virtual-exam-calendar/components/calendar-event"
import { VirtualExam } from "@medaica/common/types"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"

const localizer = momentLocalizer(moment)

function CustomHeader({ date }) {
  return (
    <div className="p-1">
      <div className="text-2xl">{date.toLocaleDateString(undefined, { day: "2-digit" })}</div>
      <div className="text-sm">{date.toLocaleDateString(undefined, { weekday: "long" })}</div>
    </div>
  )
}

type EventData = {
  virtualExam: VirtualExam
} & Event

const LiveExamCalendar = (): ReactElement => {
  const { setCurrentPage } = useGlobalContext()
  const [events, setEvents] = useState<Event[]>()
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const defaultView = "week"
  const today = useMemo(() => new Date(), [])
  const [scheduleExamDialogOpen, setScheduleExamDialogOpen] = useState(false)
  const navigate = useNavigate()

  const loadEvents = useCallback(
    async (range: Date[] | { start: Date; end: Date }, view?: View | undefined) => {
      const { start, end } = Array.isArray(range)
        ? {
            start: range[0],
            end: moment(range[range.length - 1])
              .add(1, "days")
              .toDate(),
          }
        : range
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      //const virtualExams = await medaicaApiService.getVirtualExams(user.id, start, end)
      const virtualExams = await medaicaApiService.virtualExams.getVirtualExams({
        filters: [
          { field: "healthcareProviderId", value: user.id },
          { field: "scheduledStartDate", op: ">=", value: start },
          { field: "scheduledEndDate", op: "<=", value: end },
        ],
        joinedLoads: ["patientProfile", "healthcareProvider"],
      })

      const mapped = virtualExams.map((virtualExam) => {
        return {
          virtualExam: virtualExam,
          title: virtualExam.patientProfile.fullName,
          start: virtualExam.scheduledStartDate,
          end: virtualExam.scheduledEndDate,
        } as EventData
      })

      setEvents(mapped)
    },
    [medaicaApiService, user]
  )

  const handleScheduled = (virtualExam: VirtualExam) => {
    setEvents(
      (existingEvents) =>
        [
          {
            virtualExam: virtualExam,
            title: virtualExam.patientProfile.fullName,
            start: moment(virtualExam.scheduledStartDate).toDate(),
            end: moment(virtualExam.scheduledEndDate).toDate(),
          },
          ...(existingEvents ?? []),
        ] as EventData[]
    )
  }

  useEffect(() => {
    setCurrentPage("calendar", Paths.liveExamCalendar)
  }, [setCurrentPage])

  useEffect(() => {
    if (loadEvents && today) {
      const start = moment(today).startOf("week").toDate()
      const end = moment(start).add(6, "days").toDate()
      void loadEvents([start, end])
    }
  }, [loadEvents, today])

  const handleRangeChange = (range: Date[] | { start: Date; end: Date }, view?: View | undefined) => {
    void loadEvents(range)
  }

  return (
    <>
      {scheduleExamDialogOpen && (
        <ScheduleExamDialog
          onScheduled={handleScheduled}
          open={scheduleExamDialogOpen}
          onClose={() => setScheduleExamDialogOpen(false)}
        />
      )}
      <Page variant="full">
        <Header
          title="Virtual Exam Calendar"
          actions={
            <>
              <Button variant="outlined" onClick={() => navigate(Paths.liveExam)}>
                Start Virtual Exam
              </Button>
              <Button variant="contained" onClick={() => setScheduleExamDialogOpen(true)}>
                Schedule Virtual Exam
              </Button>
            </>
          }
        />
        <PageContent>
          <LoadingWrapper loading={!events}>
            <div className="overflow-y-hidden" style={{ height: "800px" }}>
              <Calendar
                components={{
                  event: CalendarEvent,
                  week: { header: CustomHeader },
                }}
                formats={{
                  eventTimeRangeFormat: () => {
                    return ""
                  },
                  timeGutterFormat: (date: Date, culture: string, localizer: DateLocalizer) => {
                    // todo localizer
                    return date.toLocaleTimeString(undefined, { hour: "numeric" })
                  },
                }}
                scrollToTime={moment().startOf("hour").toDate()}
                localizer={localizer}
                defaultDate={today}
                defaultView={defaultView}
                events={events}
                onRangeChange={handleRangeChange}
                timeslots={4}
                step={15}
              />
            </div>
          </LoadingWrapper>
        </PageContent>
      </Page>
    </>
  )
}

export default LiveExamCalendar
export type { EventData }
