/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import CompatibilityCheck, { FailureReason } from "@medaica/common/components/compatibility-check"
import Switch, { Case, Default } from "react-switch-case"
import Main from "./components/main"
import CompatibilityCheckFailure from "./components/compatibility-check-failure"
import { ExamType } from "@medaica/common/const"

const InPersonExam = ({
  isOpen,
  onClose,
  patientProfileId,
}: {
  isOpen: boolean
  onClose: () => void
  patientProfileId: string
}): ReactElement => {
  const [compatibilityCheck, setCompatibilityCheck] = useState<{
    status: "inProgress" | "passed" | "failed"
    failureReason?: FailureReason
  }>({ status: "inProgress" })

  const handleCompatibilityCheckComplete = (passed: boolean, failureReason: FailureReason) => {
    setCompatibilityCheck(passed ? { status: "passed" } : { status: "failed", failureReason: failureReason })
  }

  return (
    <Switch condition={compatibilityCheck.status}>
      <Case value="failed">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <CompatibilityCheckFailure onClose={onClose} failureReason={compatibilityCheck.failureReason!} />
      </Case>
      <Case value="passed">
        <Main patientProfileId={patientProfileId} onClose={onClose} isOpen={isOpen} />
      </Case>
      <Default>
        <CompatibilityCheck examType={ExamType.InPerson} onComplete={handleCompatibilityCheckComplete} />
      </Default>
    </Switch>
  )
}

export default InPersonExam
