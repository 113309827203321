/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import Header from "@medaica/common/components/page/page-header"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import { Exam } from "@medaica/common/types"
import { useParams } from "react-router-dom"
import Loading from "@medaica/common/components/loading"
import moment from "moment"
import ExamDetails from "views/exam/exam-details/components/exam-details"
import { useSnackbar } from "notistack"
import Page from "@medaica/common/components/page/page"

const ExamView = (): ReactElement => {
  const { examId } = useParams<{ examId: string }>()
  const [exam, setExam] = useState<Exam | null>(null)
  const [examTitle, setExamTitle] = useState<string>()
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const displayHiddenInfo = "**********"

  useEffect(() => {
    const fetchData = async () => {
      if (medaicaApiService && examId) {
        const exam = await medaicaApiService.exams.getExamById(examId, ["patientProfile", "auscultations"])
        setExamTitle(
          user.organizationId === exam.patientProfile.organizationId ? exam.patientProfile.fullName : displayHiddenInfo
        )
        setExam(exam)
      }
    }
    void fetchData()
  }, [enqueueSnackbar, examId, medaicaApiService, user.organizationId])

  return exam ? (
    <Page variant="full">
      <Header title={examTitle} subtitle={moment(exam.dateCreated).format("LLL")} />
      <ExamDetails exam={exam} />
    </Page>
  ) : (
    <Loading />
  )
}

export default ExamView
