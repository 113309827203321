/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import { useSnackbar } from "notistack"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"

const DeleteAuscultationDialog = ({
  auscultationId,
  isOpen,
  onClose,
}: {
  auscultationId: string
  isOpen: boolean
  onClose: () => void
}): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const { enqueueSnackbar } = useSnackbar()

  const handleDeleteButtonClicked = async () => {
    try {
      onClose()
      await virtualExamStore.deleteAuscultation(auscultationId)
      enqueueSnackbar("The auscultation has been deleted")
    } catch (error) {
      enqueueSnackbar("An error occurred while deleting the auscultation. Please try again.")
    }
  }
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Delete Auscultation?</DialogTitle>
      <DialogContent>The auscultation will be permanently deleted</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => void handleDeleteButtonClicked()}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAuscultationDialog
