/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import NewExam from "./components/new-exam"
import ExistingExam from "./components/existing-exam"
import URI from "urijs"

const Lobby = (): ReactElement => {
  const virtualExamId = URI(window.location.href).segment(1)

  return virtualExamId ? <ExistingExam virtualExamId={virtualExamId} /> : <NewExam />
}

export default Lobby
