/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { cloneElement, ReactElement, ReactNode } from "react"

const Illustration = ({ children }: { children: ReactElement }): ReactElement =>
  cloneElement(children, { className: "w-48 h-48 mx-auto" })
const Title = ({ children }: { children: ReactNode }): ReactElement => (
  <h3 className="text-2xl font-semibold">{children}</h3>
)
const Body = ({ children }: { children: ReactNode }): ReactElement => <div className="mt-5">{children}</div>
const Actions = ({ children }: { children: ReactNode }): ReactElement => <div className="mt-8">{children}</div>

const NoDataPanel = ({ children }: { children: ReactNode }): ReactElement => {
  return <div className="my-16 mx-auto w-1/2 text-center">{children}</div>
}

export default NoDataPanel
export { Actions, Body, Title, Illustration }
