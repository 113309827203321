/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Alert, Typography, Box, BoxProps, AlertTitle } from "@mui/material"

const M1DisconnectedAlert = (props: BoxProps): ReactElement => {
  return (
    <Box {...props}>
      <Alert severity="warning">
        <AlertTitle>Stethoscope Disconnected</AlertTitle>
        <Typography fontSize={13}>
          The patient's stethoscope is not connected. Please instruct the patient to plug their stethoscope in to
          continue with the auscultation.
        </Typography>
      </Alert>
    </Box>
  )
}

export default M1DisconnectedAlert
