/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import Header from "@medaica/common/components/page/page-header"
import { Link } from "react-router-dom"
import { Button } from "@mui/material"

const CompleteView = (): ReactElement => {
  return (
    <>
      <Header
        title="Account creation successful"
        summary={
          <>
            Your account has been successfully created. Click <strong>Sign in</strong> to enter the Medaica Healthcare
            Provider Portal.
          </>
        }
      />
      <Link to="/">
        <Button variant="contained">Sign in</Button>
      </Link>
    </>
  )
}

export default CompleteView
