/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { ProfileAndSettingsContextType } from "views/profile-and-settings/index"
import Button from "@medaica/common/components/ui/button"
import { HealthcareProvider } from "@medaica/common/types"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import Loading from "@medaica/common/components/loading"
import ContentContainer from "views/profile-and-settings/content-container"
import { Paths } from "const"
import DetailsContainer from "@medaica/common/components/ui/details/details-container"
import DetailsItem from "@medaica/common/components/ui/details/details-item"
import DetailsName from "@medaica/common/components/ui/details/details-name"
import DetailsValue from "@medaica/common/components/ui/details/details-value"
import { Alert } from "@mui/material"
import SystemErrorAlert from "@medaica/common/components/system-error-alert"
import { logError } from "@medaica/common/services/util"

const EditButton = (): ReactElement => {
  const navigate = useNavigate()
  return (
    <Button size="small" onClick={() => navigate(Paths.profileAndSettingsPersonalInfoEdit)}>
      Edit
    </Button>
  )
}

const View = (): ReactElement => {
  const { setActions, setCurrentPage } = useOutletContext<ProfileAndSettingsContextType>()
  const { user } = useAuth()
  const [healthcareProvider, setHealthcareProvider] = useState<HealthcareProvider | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false)
  const { state } = useLocation()
  const [isSystemError, setIsSystemError] = useState(false)

  useEffect(() => {
    void (async () => {
      setActions(<EditButton />)
      setCurrentPage("personalInfo")
      try {
        const healthcareProvider = await medaicaApiService.healthcareProviders.getHealthcareProvider(user.id)
        if (healthcareProvider === null) {
          throw new Error("User not found")
        }
        setHealthcareProvider(healthcareProvider)
      } catch (error) {
        setIsSystemError(true)
        logError(error)
      }
      if (state?.updateSuccessful) {
        setUpdateSuccessful(true)
      }
    })()
  }, [medaicaApiService.healthcareProviders, setActions, setCurrentPage, state?.updateSuccessful, user.id])

  if (!healthcareProvider) {
    return <Loading />
  }

  return (
    <ContentContainer>
      <DetailsContainer sx={{ width: "380px" }}>
        {updateSuccessful && (
          <Alert severity="success" aria-label="submisson error">
            You successfully updated your personal information
          </Alert>
        )}
        {isSystemError && (
          <div>
            <SystemErrorAlert />
          </div>
        )}
        <DetailsItem>
          <DetailsName id="organization">Organization</DetailsName>
          <DetailsValue aria-labelledby="organization">{user.organizationName}</DetailsValue>
        </DetailsItem>
        <DetailsItem>
          <DetailsName id="firstName">First name</DetailsName>
          <DetailsValue aria-labelledby="firstName">{healthcareProvider.firstName}</DetailsValue>
        </DetailsItem>
        <DetailsItem>
          <DetailsName id="lastName">Last name</DetailsName>
          <DetailsValue aria-labelledby="lastName">{healthcareProvider.lastName}</DetailsValue>
        </DetailsItem>
        <DetailsItem>
          <DetailsName id="displayName">Display name</DetailsName>
          <DetailsValue aria-labelledby="displayName">{healthcareProvider.displayName}</DetailsValue>
        </DetailsItem>
      </DetailsContainer>
    </ContentContainer>
  )
}

export default View
export { EditButton }
