/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import PageDialog from "@medaica/common/components/dialog/page-dialog"
import ScheduleStep from "views/exam/virtual-exam-calendar/schedule-exam-wizard/views/schedule-step"
import CompleteStep from "views/exam/virtual-exam-calendar/schedule-exam-wizard/views/complete-step"
import { VirtualExam } from "@medaica/common/types"

const ScheduleExamDialog = ({
  open,
  onClose,
  onScheduled,
}: {
  open: boolean
  onClose: () => void
  onScheduled: (virtualExam: VirtualExam) => void
}): ReactElement => {
  const [virtualExam, setVirtualExam] = useState<VirtualExam | null>(null)

  return (
    <PageDialog open={open} onClose={onClose}>
      {!virtualExam ? (
        <ScheduleStep
          onClose={onClose}
          onScheduled={(virtualExam) => {
            onScheduled(virtualExam)
            setVirtualExam(virtualExam)
          }}
        />
      ) : (
        <CompleteStep virtualExam={virtualExam} onClose={onClose} />
      )}
    </PageDialog>
  )
}

export default ScheduleExamDialog
