/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Auscultation } from "@medaica/common/types"
import AuscultationDetails from "../../components/auscultation-details-combo"
import { AlgorithmType } from "@medaica/common/services/factories"

const AuscultationList = ({ auscultations }: { auscultations: Auscultation[] }): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="panel bg-white">
            <div className="panel-header">Auscultations</div>
            <ul className="divide-y">
              {auscultations.map((auscultation) => (
                <li style={{ borderBottom: "6px solid red" }} key={auscultation.id}>
                  <AuscultationDetails
                    prefilteringAlgorithm={AlgorithmType.Silence}
                    expanded={true}
                    auscultation={auscultation}
                    onContract={() => null}
                  />
                  <AuscultationDetails
                    prefilteringAlgorithm={AlgorithmType.Algo1}
                    expanded={true}
                    auscultation={auscultation}
                    onContract={() => null}
                  />
                  <AuscultationDetails
                    prefilteringAlgorithm={AlgorithmType.Raw}
                    expanded={true}
                    auscultation={auscultation}
                    onContract={() => null}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuscultationList
