/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { ButtonBase, Menu, MenuItem } from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import DeleteAuscultationDialog from "./delete-auscultation-dialog"

const ActionMenu = ({ auscultationId }: { auscultationId: string }): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteAuscultationDialogIsOpen, setDeleteAuscultationDialogIsOpen] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDeleteAuscultationItemClicked = () => {
    setAnchorEl(null)
    setDeleteAuscultationDialogIsOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {deleteAuscultationDialogIsOpen && (
        <DeleteAuscultationDialog
          auscultationId={auscultationId}
          isOpen={deleteAuscultationDialogIsOpen}
          onClose={() => setDeleteAuscultationDialogIsOpen(false)}
        />
      )}
      <ButtonBase
        style={{ marginRight: "2px", marginTop: "-2px" }}
        id={`more-button`}
        aria-controls={open ? "more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
      >
        <MenuItem onClick={handleDeleteAuscultationItemClicked}>Delete Auscultation</MenuItem>
      </Menu>
    </div>
  )
}

export default ActionMenu
