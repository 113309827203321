/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import { observer } from "mobx-react-lite"
import AuscultationRequest from "views/exam/virtual-exam/stores/auscultation-request"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import ErrorDialog from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/create-auscultation-view/components/error-dialog"
import { Box, Button, LinearProgress, Slider, styled } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import M1DisconnectedAlert from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/create-auscultation-view/components/m1-disconnected-alert"
import { WAFStreamContext } from "@medaica/common/components/audio-player/web-audio-filters"
import { MedaicaFilters } from "@medaica/common/components/audio-player/filters"
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded"
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded"
import { logError, getConfigBool } from "@medaica/common/services/util"

const AuscultationProcessingMessage = ({ progress }: { progress: number }) => {
  return (
    <>
      <div className="absolute h-full w-full bg-white opacity-70 z-40" />
      <div className="absolute h-full w-full flex justify-center items-center z-50">
        <div className="border border-primary-300 bg-white p-5 rounded-md w-4/5">
          <div className="bg-gray-200 h-3 rounded">
            <div className="bg-primary-400 h-full rounded" style={{ width: `${progress}%` }} />
          </div>
          <div className="text-center text-sm mt-3">
            The audio is being processed for diagnosis. This may take a few moments.
          </div>
        </div>
      </div>
    </>
  )
}

const RecordButton = styled(Button)`
  color: #ffffff;
  background-color: #f44336;
  &:hover {
    background-color: #c62828;
  }
`

const ListenStep = observer(
  ({
    auscultationRequest,
    onNavigateBack,
  }: {
    auscultationRequest: AuscultationRequest
    onNavigateBack: () => void
  }): ReactElement => {
    const recordingLength = 60
    const { virtualExamStore, avStore, mediaDeviceStore } = useContext(VirtualExamContext)
    const recordingEndedRef = useRef(false)
    const [error, setError] = useState<string | null>(null)
    const [recordingCountdown, setRecordingCountdown] = useState(recordingLength)
    const recordingCountdownIntervalRef = useRef<NodeJS.Timeout | null>(null)
    const [recordingComplete, setRecordingComplete] = useState(false)
    const [auscMicMode, setAuscMicMode] = useState<"active" | "inactive" | "transitioning">("inactive")
    const [isRecording, setIsRecording] = useState(false)
    const [controlsActive, setControlsActive] = useState(false)
    const medaicaStreamContextRef = useRef<WAFStreamContext>(new WAFStreamContext())
    const [volume, setVolume] = useState(medaicaStreamContextRef.current.volume)
    const muteHCP = getConfigBool("MUTE_HCP_IN_AUSCULTATION_RECORDING")
    const handleFiltersChangedEvent = (data: string) => {
      // Handle the event and data received from changed filter types
    }
    const handleVolumeSliderChanged = (volume: number) => {
      setVolume(volume)
      medaicaStreamContextRef.current.setVolume(volume)
    }

    const toggleListening = () => {
      if (auscMicMode === "inactive") {
        setAuscMicMode("transitioning")
        setControlsActive(true)
        virtualExamStore.requestAuscultationMicActivated()
        virtualExamStore.enableTarget()
      } else {
        setAuscMicMode("transitioning")
        setControlsActive(false)
        virtualExamStore.requestAuscultationMicDeactivated()
        virtualExamStore.disableTarget()
      }
    }

    const startRecording = () => {
      setIsRecording(true)
      setRecordingCountdown(recordingLength)
      auscultationRequest.start()
      if (muteHCP) {
        mediaDeviceStore.setAudioDeviceMuted(true)
        virtualExamStore.isRecordingAuscultation = true
      }
      recordingCountdownIntervalRef.current = setInterval(() => {
        setRecordingCountdown((recordingCountdown) => recordingCountdown - 0.25)
      }, 250)
    }

    const endRecording = useCallback(() => {
      setRecordingComplete(true)
      if (muteHCP) {
        mediaDeviceStore.setAudioDeviceMuted(false)
        virtualExamStore.isRecordingAuscultation = false
      }
      if (recordingCountdownIntervalRef.current) {
        clearInterval(recordingCountdownIntervalRef.current)
      }
      auscultationRequest.stop()
      virtualExamStore.requestAuscultationMicDeactivated()
      virtualExamStore.disableTarget()
    }, [auscultationRequest, mediaDeviceStore, muteHCP, virtualExamStore])

    const cancelRecording = () => {
      setIsRecording(false)
      if (muteHCP) {
        mediaDeviceStore.setAudioDeviceMuted(false)
        virtualExamStore.isRecordingAuscultation = false
      }
      if (recordingCountdownIntervalRef.current) {
        clearInterval(recordingCountdownIntervalRef.current)
      }
      auscultationRequest.cancel()
      virtualExamStore.requestAuscultationMicDeactivated()
      virtualExamStore.disableTarget()
      setControlsActive(false)
    }

    const onBackToAuscultationPoint = () => {
      if (isRecording) {
        cancelRecording()
      }
      virtualExamStore.disableTarget()
      onNavigateBack()
    }

    const onCancelAuscultation = () => {
      if (muteHCP && isRecording) {
        mediaDeviceStore.setAudioDeviceMuted(false)
        virtualExamStore.isRecordingAuscultation = false
      }
      virtualExamStore.cancelAuscultationRequest()
    }

    // When we receive the auscultation audio track, start playing it
    useEffect(() => {
      const auscultationAudioTrack = avStore.remoteParticipant?.auscultationAudioTrack
      if (auscultationAudioTrack) {
        medaicaStreamContextRef.current
          .play(new MediaStream([auscultationAudioTrack.mediaStreamTrack]))
          .then(() => {
            setVolume(medaicaStreamContextRef.current.volume)
            setAuscMicMode("active")
          })
          .catch(logError)
      } else {
        // Handle the case when auscultationAudioTrack is not available
        setVolume(0)
        setAuscMicMode("inactive")
      }
    }, [avStore.remoteParticipant?.auscultationAudioTrack, setAuscMicMode])

    // When the recording counter is complete, end the recording
    useEffect(() => {
      if (recordingCountdown <= 0) {
        endRecording()
      }
    }, [endRecording, recordingCountdown])

    // When the page unloads, cancel the recording and request the auscultation mic be deactivated
    useEffect(() => {
      return () => {
        virtualExamStore.requestAuscultationMicDeactivated()
        if (recordingCountdownIntervalRef.current) {
          clearInterval(recordingCountdownIntervalRef?.current)
        }
        if (mediaDeviceStore.audioDeviceIsMuted) {
          mediaDeviceStore.setAudioDeviceMuted(false)
        }
        virtualExamStore.isRecordingAuscultation = false
      }
    }, [mediaDeviceStore, virtualExamStore])

    // Handle and display errors
    useEffect(() => {
      const errorMessages = {
        DeviceDisconnected:
          "The stethoscope was disconnected during the auscultation. The auscultation was cancelled. " +
          "Instruct the patient to plug their stethoscope in and try again.",
        PatientDisconnected: "The patient disconnected during the auscultation. The auscultation was cancelled.",
        AuscultationRecordingFailed: "Auscultation recording failed. Please repeat the auscultation and try again.",
      }

      if (auscultationRequest.error) {
        recordingEndedRef.current = true
        const errorMessage =
          errorMessages[auscultationRequest.error] ??
          "There was an error during the auscultation. The auscultation has been cancelled."
        setError(errorMessage as string)
      }
    }, [auscultationRequest.error])

    return (
      <div className="relative">
        <ErrorDialog isOpen={!!error} errorMessage={error} />
        {auscultationRequest.status === "processing" && !error && (
          <AuscultationProcessingMessage progress={auscultationRequest.processingProgress} />
        )}
        <div className="panel border-primary-150 bg-primary-100">
          <div className="p-3">
            <h4 className="font-semibold header-color">Auscultate</h4>
            {!virtualExamStore.isM1Connected && auscultationRequest.status === "pending" && (
              <M1DisconnectedAlert mt={2} mb={2} />
            )}
            <div className="mt-5 flex">
              <figure className="mr-3 flex flex-col">
                <AuscultationPointImage
                  highlightedPoint={auscultationRequest.auscultationPoint}
                  className="w-28 h-28 border border-primary-200 rounded-md bg-gray-50"
                  style={{ padding: "2px" }}
                />
                <figcaption className="text-sm text-center -mt-px">
                  {auscultationRequest.auscultationPoint.longLabel}
                </figcaption>
              </figure>
              <div className="flex flex-col">
                <div className="flex flex-grow flex-col items-center">
                  <Box
                    style={{
                      marginBottom: 8,
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gridRowGap: "8px",
                    }}
                  >
                    <MedaicaFilters
                      filterContext={medaicaStreamContextRef.current}
                      onFiltersChangeEvent={handleFiltersChangedEvent}
                      disableSetting={!controlsActive}
                    />
                  </Box>
                </div>
                <div className="mb-4 flex flex-row items-center space-x-2 w-full mx-auto">
                  <VolumeDownRounded className="text-gray-500" />
                  <Slider
                    aria-label="Volume"
                    size="small"
                    value={volume}
                    disabled={!controlsActive}
                    onChange={(_, volume) => handleVolumeSliderChanged(volume as number)}
                    sx={{
                      "& .MuiSlider-track": {
                        height: ".32em",
                      },
                      "& .MuiSlider-rail": {
                        height: ".32em",
                      },
                      "& .MuiSlider-thumb": {
                        height: "1.1em",
                        width: "1.1em",
                      },
                    }}
                  />
                  <VolumeUpRounded className="text-gray-500" />
                </div>
                <Box mt={1} style={{ width: "100%" }}>
                  <div className="mb-2">
                    <LoadingButton
                      loading={auscMicMode === "transitioning"}
                      fullWidth
                      variant="contained"
                      onClick={toggleListening}
                      disabled={!virtualExamStore.isM1Connected || isRecording}
                      size="small"
                    >
                      {auscMicMode === "inactive" ? "Preview" : "Stop Preview"}
                    </LoadingButton>
                  </div>
                  <div className="mb-2">
                    <RecordButton
                      fullWidth
                      variant="contained"
                      size="small"
                      onClick={startRecording}
                      disabled={!virtualExamStore.isM1Connected || auscMicMode !== "active" || isRecording || !!error}
                    >
                      Record
                    </RecordButton>
                  </div>
                  <div className="flex gap-x-2 mb-2">
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      onClick={endRecording}
                      disabled={!isRecording || !!error}
                    >
                      Save
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      size="small"
                      onClick={cancelRecording}
                      disabled={!isRecording || !!error}
                    >
                      Cancel
                    </Button>
                  </div>
                  {isRecording && !error && (
                    <>
                      <div className="mb-2">
                        <LinearProgress
                          variant="determinate"
                          value={(recordingLength - recordingCountdown) * (100 / recordingLength)}
                        />
                      </div>
                      {recordingCountdown < 15 && (
                        <div>
                          <Button
                            fullWidth
                            variant="outlined"
                            size="small"
                            disabled={recordingComplete}
                            onClick={() => {
                              setRecordingCountdown((recordingCountdown) =>
                                Math.min(recordingCountdown + 30, recordingLength)
                              )
                            }}
                          >
                            Add 30 seconds
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-7 border-t border-primary-200 p-3">
            {!error ? (
              <button className="button-sm text-primary-400 hover:text-primary-600" onClick={onBackToAuscultationPoint}>
                &lt; Select Auscultation Point
              </button>
            ) : (
              <div />
            )}
            <button
              className="button-sm border hover:bg-primary-125 hover:text-primary-500 text-primary-400
              border-primary-200"
              onClick={onCancelAuscultation}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }
)

export default ListenStep
