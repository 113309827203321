/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useMemo, useState } from "react"
import { PatientProfile } from "@medaica/common/types"
import PageDialog from "@medaica/common/components/dialog/page-dialog"
import PageDialogTitle from "@medaica/common/components/dialog/page-dialog-title"
import PageDialogContent from "@medaica/common/components/dialog/page-dialog-content"
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import useGlobalContext from "@medaica/common/hooks/global-context"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import M1DeviceInfoUtil from "@medaica/common/services/m1-device-info-util"
import { InPersonExamContextProvider } from "views/exam/in-person-exam/in-person-exam-context"
import InPersonExamStore from "views/exam/in-person-exam/stores/in-person-exam-store"
import { getAge } from "@medaica/common/services/util"
import Notes from "views/exam/in-person-exam/components/notes"
import AuscultationList from "views/exam/in-person-exam/components/auscultation-list"
import NewAuscultationWizard from "views/exam/in-person-exam/components/new-auscultation-wizard"
import useAuth from "hooks/auth"
import Loading from "@medaica/common/components/loading"

const Main = ({
  isOpen,
  onClose,
  patientProfileId,
}: {
  isOpen: boolean
  onClose: () => void
  patientProfileId: string
}): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [patientProfile, setPatientProfile] = useState<PatientProfile | null>(null)
  const [newAuscultationWizardIsOpen, setNewAuscultationWizardIsOpen] = useState(false)
  const [examSavedDialogIsOpen, setExamSavedDialogIsOpen] = useState(false)
  const { user } = useAuth()
  const mediaDeviceManager = useMemo(() => new M1MediaDeviceStore(new M1DeviceInfoUtil()), [])
  const [inPersonExamStore, setInPersonExamStore] = useState<InPersonExamStore | null>(null)

  useEffect(() => {
    void (async () => {
      const patientProfile = await medaicaApiService.patientProfiles.getPatientProfile(patientProfileId)
      const inPersonExamStore = new InPersonExamStore(user.id, patientProfile, medaicaApiService, mediaDeviceManager)
      setInPersonExamStore(inPersonExamStore)
      setPatientProfile(patientProfile)
    })()
    return () => {
      mediaDeviceManager.dispose()
    }
  }, [medaicaApiService, mediaDeviceManager, patientProfileId, user.id])

  const handleExitButtonClicked = () => {
    if (inPersonExamStore?.exam) {
      setExamSavedDialogIsOpen(true)
    } else {
      onClose()
    }
  }

  const handleNewAuscultationButtonClicked = () => {
    setNewAuscultationWizardIsOpen(true)
  }

  return (
    <PageDialog fullWidth maxWidth="md" dismissable={false} open={isOpen} onClose={onClose}>
      {patientProfile && inPersonExamStore ? (
        <>
          <Dialog open={examSavedDialogIsOpen}>
            <DialogContent>
              This exam has been saved. If you would like to review it, you can find it in the{" "}
              <strong>Patient's Exams</strong> section.
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setExamSavedDialogIsOpen(false)
                  onClose()
                }}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
          <InPersonExamContextProvider inPersonExamStore={inPersonExamStore} mediaDeviceManager={mediaDeviceManager}>
            {newAuscultationWizardIsOpen && (
              <NewAuscultationWizard
                isOpen={newAuscultationWizardIsOpen}
                onClose={() => setNewAuscultationWizardIsOpen(false)}
              />
            )}
            <PageDialogTitle>
              <div className="flex w-full">
                <div className="flex-grow">In-Person Exam</div>
                <div className="flex justify-end space-x-2">
                  <Button variant="contained" onClick={handleNewAuscultationButtonClicked}>
                    New Auscultation
                  </Button>
                  <Button variant="outlined" onClick={handleExitButtonClicked}>
                    Exit
                  </Button>
                </div>
              </div>
            </PageDialogTitle>
            <PageDialogContent>
              <div className="flex space-x-5 mt-8">
                <div className="w-1/3">
                  <div className="panel">
                    <div className="panel-header">Patient</div>
                    <div className="p-4">
                      <dl
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                          columnGap: "16px",
                        }}
                      >
                        <dt className="detail-label">Name</dt>
                        <dd>
                          {patientProfile.firstName} {patientProfile.lastName}
                        </dd>
                        {patientProfile.sex && (
                          <>
                            <dt className="detail-label">Sex</dt>
                            <dd className="capitalize">{patientProfile.sex}</dd>
                          </>
                        )}
                        {patientProfile.birthdate && (
                          <>
                            <dt className="detail-label">Age</dt>
                            <dd>{getAge(patientProfile.birthdate)}</dd>
                          </>
                        )}
                      </dl>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Notes />
                  </div>
                </div>
                <div className="w-2/3">
                  <AuscultationList />
                </div>
              </div>
            </PageDialogContent>
          </InPersonExamContextProvider>
        </>
      ) : (
        <PageDialogContent>
          <Box sx={{ height: "200px" }}>
            <Loading />
          </Box>
        </PageDialogContent>
      )}
    </PageDialog>
  )
}

export default Main
