/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import CopyIcon from "@medaica/common/components/icons/copy-icon"
import { getConfig, logError, putOnClipboard } from "@medaica/common/services/util"
import InfoIcon from "@medaica/common/components/icons/info-icon"
import { Paths } from "const"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import ShareIcon from "@mui/icons-material/Share"
import { Button, Tooltip } from "@mui/material"
import { useSnackbar } from "notistack"
import Popup from "@medaica/common/components/popup/popup"
import { bindToggle, usePopupState } from "material-ui-popup-state/hooks"
import SendAccessInfoDialog from "views/exam/virtual-exam/exam-room/components/send-access-info-dialog"

const InfoPanel = ({ onClose }: { onClose: () => void }): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const shareAccessInfoButtonRef = useRef<HTMLButtonElement>(null)
  const { enqueueSnackbar } = useSnackbar()
  const [sendAccessInfoDialogIsOpen, setSendAccessInfoDialogIsOpen] = useState(false)

  const sendAccessInfoButtonClicked = () => {
    setSendAccessInfoDialogIsOpen(true)
  }

  const onCloseSendAccessInfoDialog = (shouldClose: boolean) => {
    setSendAccessInfoDialogIsOpen(false)
    // If the dialog was closed by clicking the send button,
    // and access info was sent successfully, then we want to
    // close automatically the info panel too.
    if (shouldClose) {
      onClose()
    }
  }

  function handleCopyButtonClick() {
    putOnClipboard(
      `To join the virtual exam, navigate to ${getConfig("PATIENT_PORTAL_URL")}/live-exam and enter the code ${
        virtualExamStore.accessCode
      }`
    ).catch(logError)
    enqueueSnackbar("The access information has been copied to your clipboard")
  }

  useEffect(() => {
    if (shareAccessInfoButtonRef.current) {
      shareAccessInfoButtonRef.current.focus()
    }
  }, [])

  return (
    <>
      <SendAccessInfoDialog open={sendAccessInfoDialogIsOpen} onClose={onCloseSendAccessInfoDialog} />
      <div className="bg-white p-5 text-left">
        <h3 className="panel-title">Exam Details</h3>
        <div className="mt-5">
          <span className="font-semibold">Patient:</span> {virtualExamStore.patientProfile?.fullName}
        </div>
        <div className="mt-6 mb-1 font-semibold">Access information</div>
        <div className="flex p-3 mb-2 bg-gray-100 rounded-md">
          <div className="flex-grow">
            <dl style={{ display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "3px" }}>
              <dt className="font-semibold">Link:</dt>
              <dd className="whitespace-nowrap">
                {`${getConfig("PATIENT_PORTAL_URL").replace(/^https?:\/\//, "")}${Paths.liveExam}`}
              </dd>
              <dt className="font-semibold">Code:</dt>
              <dd>{virtualExamStore.accessCode}</dd>
            </dl>
          </div>
          <div className="flex items-center ml-6">
            <Tooltip title="Copy access info">
              <button
                onClick={() => handleCopyButtonClick()}
                className="p-2 rounded-full focus focus-primary hover:bg-white"
              >
                <CopyIcon className="h-6 text-black" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="mt-3 mb-4">
          <Button
            startIcon={<ShareIcon />}
            disableElevation
            size="small"
            ref={shareAccessInfoButtonRef}
            onClick={sendAccessInfoButtonClicked}
            variant="contained"
          >
            Send Access Info
          </Button>
        </div>
      </div>
    </>
  )
}

const InfoButton = (): ReactElement => {
  const popupState = usePopupState({ variant: "popper", popupId: "infoPopup" })
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [doInitialPopupCheck, setDoInitialPopupCheck] = useState(true)

  useEffect(() => {
    if (doInitialPopupCheck) {
      setDoInitialPopupCheck(false)
      popupState.setOpen(true, buttonRef.current)
    }
  }, [doInitialPopupCheck, popupState])

  return (
    <>
      <Popup placement="top" style={{ zIndex: 200 }} popupState={popupState}>
        <InfoPanel onClose={() => popupState.setOpen(false, buttonRef.current)} />
      </Popup>
      <button ref={buttonRef} {...bindToggle(popupState)}>
        <div className="video-button">
          <InfoIcon />
        </div>
        <div className="font-sm text-gray-500">Info</div>
      </button>
    </>
  )
}

export default InfoButton
