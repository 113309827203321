/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import VideoControls from "@medaica/common/components/video-controls"
import InfoButton from "views/exam/virtual-exam/exam-room/components/info-button"
import NotesButton from "views/exam/virtual-exam/exam-room/components/notes-button"
import SettingsButton from "@medaica/common/views/exam/virtual-exam/components/settings-button"
import ExitButton from "@medaica/common/views/exam/virtual-exam/exam-room/components/exit-button"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import SettingsPanel from "@medaica/common/views/exam/virtual-exam/components/settings-panel"

const PageControls = (): ReactElement => {
  const { mediaDeviceStore, virtualExamStore, uiStore, preferencesStore } = useContext(VirtualExamContext)
  const onExitButtonPressed = () => {
    virtualExamStore.cancelAuscultationRequest()
    uiStore.navigate("lobby", { redirectReason: "exited" })
  }
  return (
    <div className="flex flex-col mt-2 gap-y-2 w-full">
      <div className="flex flex-row gap-x-3 w-full justify-center">
        <VideoControls mediaDeviceStore={mediaDeviceStore} />
        <NotesButton />
        <InfoButton />
        <SettingsButton>
          <SettingsPanel preferencesStore={preferencesStore} mediaDeviceStore={mediaDeviceStore} />
        </SettingsButton>
        <ExitButton onExit={onExitButtonPressed} />
      </div>
    </div>
  )
}

export default PageControls
