/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import "pointer-events-polyfill"
import React, { ReactElement } from "react"
import { BrowserRouter, useRoutes } from "react-router-dom"
import routes from "routes"
import { AuthProvider } from "contexts/auth"
import { GlobalContextProvider } from "@medaica/common/contexts/global-context"
import LoggingErrorBoundary, { GlobalErrorCatcher } from "@medaica/common/components/logging-error-boundary"
import { Paths } from "const"
import IdleTimer from "@medaica/common/components/idle-timer"
import "react-circular-progressbar/dist/styles.css"
import { ThemeProvider } from "@mui/material/styles"
import theme from "@medaica/common/theme"
import { SnackbarProvider } from "notistack"
import ErrorBoundary from "@medaica/common/views/errors/error-boundary"
import useSentryConfiguration from "@medaica/common/hooks/sentry-configuration"
import useAuth from "hooks/auth"

const Routes = (): ReactElement => {
  const routing = useRoutes(routes)
  return <>{routing}</>
}

const App = (): ReactElement => {
  useSentryConfiguration(useAuth)
  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <LoggingErrorBoundary FallbackComponent={ErrorBoundary}>
            <GlobalErrorCatcher>
              <GlobalContextProvider useAuth={useAuth} pathToHome={Paths.home}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <IdleTimer useAuth={useAuth} loggedOutPath={Paths.loggedOut} />
                  <Routes />
                </SnackbarProvider>
              </GlobalContextProvider>
            </GlobalErrorCatcher>
          </LoggingErrorBoundary>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
export { Routes }
