/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import Header from "@medaica/common/components/page/page-header"
import { Paths } from "const"
import moment from "moment"
import Table, { buildActionColumn } from "@medaica/common/components/table/table"
import Page from "@medaica/common/components/page/page"
import { Exam } from "@medaica/common/types"
import useQuery from "@medaica/common/hooks/query"
import { Typography } from "@mui/material"
import ViewDetailsButton from "@medaica/common/components/table/view-details-button"
import ExamStatusChip from "views/exam/components/exam-status-chip"
import { MUIDataTableData } from "mui-datatables"
import { logError, mapExamType } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"

type ExamData = {
  date: string
  patient: string
  type: string
  reviewed: boolean
  conductedBy: string
  actions: Exam
}

const CouncilExamListView = (): ReactElement => {
  const { setCurrentPage, medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const [error, setError] = useState<string>()
  const [exams, setExams] = useState<ExamData[] | null>(null)
  const [count, setCount] = useState<number>()
  const pageNumber = useQuery("p")
  const chipEls: HTMLDivElement[] = []
  const displayHiddenInfo = "**********"

  useEffect(() => {
    setCurrentPage("council", Paths.councilOfDoctors)
  }, [setCurrentPage])

  /**
   * We want the chips to be the same width. Thus, every time the table updates, we determine the largest chip width.
   *  Once we have that value, we set the width of all the chips to that width.
   */
  const setChipWidths = () => {
    const largestWidth = chipEls.reduce((acc, el) => {
      console.log(acc, el.getBoundingClientRect().width)
      return Math.max(acc, el.getBoundingClientRect().width)
    }, 0)
    chipEls.forEach((el) => {
      el.style.width = `${largestWidth}px`
    })
  }

  const fetchData = useCallback(
    async (pageNumber: number): Promise<void> => {
      try {
        const page = await medaicaApiService.exams.getExams(
          {
            sort: [{ field: "dateCreated", direction: "desc" }],
            joinedLoads: ["patient", "patientProfile", "healthcareProvider"],
            pagination: { pageNumber: pageNumber, pageSize: 10 },
          },
          true
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCount(page.totalCount)
        setExams(
          page.items.map((exam) => {
            return {
              date: moment(exam.dateCreated).format("lll"),
              patient:
                user.organizationId === exam.patientProfile.organizationId ? exam.patientFullName : displayHiddenInfo,
              type: mapExamType(exam.type.toString()),
              reviewed: exam.reviewed,
              conductedBy: exam.healthcareProvider?.displayName,
              actions: exam,
            }
          })
        )
      } catch (e) {
        logError(error)
        setError(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    },
    [error, medaicaApiService.exams, user.organizationId]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])

  function CustomBodyRender({ exams, dataIndex }) {
    return (
      <Typography component="span" sx={{ fontWeight: "600" }}>
        {exams ? (exams[dataIndex].patient as string) : ""}
      </Typography>
    )
  }

  const commonTableOptions = {
    onTableChange: setChipWidths,
    serverSide: true,
    count: count,
    download: false,
    print: false,
  }

  const columns = [
    {
      name: "patient",
      label: "Patient name",
      options: {
        customBodyRenderLite: (dataIndex: string | number) => {
          return <CustomBodyRender exams={exams} dataIndex={dataIndex} />
        },
      },
    },
    {
      label: "Exam date",
      name: "date",
    },
    {
      name: "type",
      label: "Exam type",
    },
    {
      name: "reviewed",
      label: "Status",
      options: {
        customBodyRender: (isReviewed: boolean) => ExamStatusChip({ isReviewed, chipEls }),
      },
    },
    {
      name: "conductedBy",
      label: "Conducted by",
    },
    buildActionColumn("actions", (exam: Exam) => <ViewDetailsButton to={`${Paths.exams}/${exam.id}`} />),
  ]

  return (
    <Page variant="full">
      <Header title="Council History" />
      {exams && (
        <Table data={exams as unknown as MUIDataTableData[]} title="" options={commonTableOptions} columns={columns} />
      )}
      {error && <div style={{ textAlign: "center" }}>{error}</div>}
      {!exams && !error && <Loading />}
    </Page>
  )
}

export default CouncilExamListView
