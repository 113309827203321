/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import VirtualExamContext from "../../../virtual-exam-context"
import ErrorView from "./components/error-view"
import ReenterView from "./components/reenter-view"
import DefaultView from "./components/default-view"
import Loading from "@medaica/common/components/loading"
import Switch, { Case, Default } from "react-switch-case"

const ExistingExam = observer(({ virtualExamId }: { virtualExamId: string }) => {
  const [patientName, setPatientName] = useState<string>()
  const { medaicaApiService } = useGlobalContext()
  const { uiStore } = useContext(VirtualExamContext)

  useEffect(() => {
    const fetchData = async () => {
      const virtualExam = await medaicaApiService.virtualExams.getVirtualExam(virtualExamId, ["patientProfile"])
      if (!virtualExam) {
        throw new Error(`No virtual exam ${virtualExamId}`)
      }
      setPatientName(virtualExam.patientProfile.fullName)
    }

    if (virtualExamId) {
      void fetchData()
    }
  }, [virtualExamId, medaicaApiService])

  return patientName ? (
    <Switch condition={uiStore.viewArgs?.redirectReason}>
      <Case value="entryError">
        <ErrorView patientName={patientName} />
      </Case>
      <Case value="exited">
        <ReenterView patientName={patientName} />
      </Case>
      <Default>
        <DefaultView patientName={patientName} />
      </Default>
    </Switch>
  ) : (
    <Loading />
  )
})

export default ExistingExam
