/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { PatientProfile } from "@medaica/common/types"
import { useNavigate } from "react-router-dom"
import { Paths } from "const"
import NewSelfExamDialog from "views/exam/new-self-exam-request-dialog"
import UpdatePatientDialog from "views/patient/update-patient-dialog"
import InPersonExam from "views/exam/in-person-exam"
import { Divider, IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
// https://medaica.atlassian.net/browse/MD-340
// Removing some functionalities for the Emory Pilot.
// Note: Not used functionalities are removed due to SonarQube issues.

const ActionMenu = ({
  patientProfile,
  refreshPatientList,
}: {
  patientProfile: PatientProfile
  refreshPatientList: () => void
}): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [createSelfExamDialogIsOpen, setCreateSelfExamDialogIsOpen] = useState(false)
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
  const [inPersonExamDialogIsOpen, setInPersonExamDialogIsOpen] = useState(false)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleStartVirtualExamButtonClicked = () => {
    navigate(Paths.liveExam, {
      state: {
        patientProfileId: patientProfile.id,
      },
    })
  }

  const handleEditPatientProfileClicked = () => {
    setAnchorEl(null)
    setEditDialogIsOpen(true)
  }

  const handleStartInPersonExamButtonClicked = () => {
    setAnchorEl(null)
    setInPersonExamDialogIsOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="flex justify-end">
      {createSelfExamDialogIsOpen && (
        <NewSelfExamDialog
          isOpen={createSelfExamDialogIsOpen}
          onCloseRequested={() => setCreateSelfExamDialogIsOpen(false)}
          patientProfileId={patientProfile.id}
        />
      )}
      {editDialogIsOpen && (
        <UpdatePatientDialog
          isOpen={editDialogIsOpen}
          onClose={() => setEditDialogIsOpen(false)}
          patientProfile={patientProfile}
          onUpdated={refreshPatientList}
        />
      )}
      {inPersonExamDialogIsOpen && (
        <InPersonExam
          isOpen={inPersonExamDialogIsOpen}
          onClose={() => setInPersonExamDialogIsOpen(false)}
          patientProfileId={patientProfile.id}
        />
      )}
      <IconButton
        size="small"
        id={`more-button-${patientProfile.id}`}
        aria-controls={open ? "more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: "primary" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": `more-button-${patientProfile.id}`,
        }}
      >
        <MenuItem onClick={() => navigate(`${Paths.patients}/${patientProfile.id}`)}>View Details</MenuItem>
        <MenuItem onClick={handleEditPatientProfileClicked}>Update Details</MenuItem>
        <Divider />
        <MenuItem onClick={handleStartVirtualExamButtonClicked}>Start Virtual Exam</MenuItem>
        <MenuItem onClick={handleStartInPersonExamButtonClicked}>Start In-Person Exam</MenuItem>
      </Menu>
    </div>
  )
}

export default ActionMenu
