/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { Auscultation, AuscultationPoint, Exam, PatientProfile } from "@medaica/common/types"
import { action, makeObservable, observable, runInAction } from "mobx"
import M1MediaDeviceStore from "@medaica/common/services/m1-media-device-store"
import { RecordingData } from "@medaica/common/services/recorder"
import { ExamType } from "@medaica/common/const"
import { NoiseQualityRecord } from "@medaica/common/components/audio-player/noise-quality-recorder"

class InPersonExamStore {
  private _notes = ""
  private _medaicaApiService: MedaicaApiService
  auscultations: Auscultation[] = []
  exam: Exam
  private _mediaDeviceManager: M1MediaDeviceStore
  private readonly _patientProfile: PatientProfile
  private readonly _healthcareProviderId: string

  constructor(
    healthcareProviderId: string,
    patientProfile: PatientProfile,
    medaicaApiService: MedaicaApiService,
    mediaDeviceManager: M1MediaDeviceStore
  ) {
    makeObservable(this, {
      addAuscultation: action,
      deleteAuscultation: action,
      auscultations: observable,
    })
    this._healthcareProviderId = healthcareProviderId
    this._patientProfile = patientProfile
    this._mediaDeviceManager = mediaDeviceManager
    this._medaicaApiService = medaicaApiService

    void this._mediaDeviceManager.start()
  }

  protected async getOrCreateExamId(): Promise<string> {
    if (!this.exam) {
      this.exam = await this._medaicaApiService.exams.createExam({
        examType: ExamType.InPerson,
        patientProfileId: this._patientProfile.id,
        healthcareProviderId: this._healthcareProviderId,
      })
    }
    return this.exam.id
  }

  async addAuscultation(
    recordingData: RecordingData,
    auscultationPoint: AuscultationPoint,
    noiseQualityRecord: NoiseQualityRecord,
    uploadProgressCallback: (number) => void
  ): Promise<Auscultation> {
    const examId = await this.getOrCreateExamId()

    const auscultation = await this._medaicaApiService.exams.addAuscultationToExam({
      examId,
      auscultationPoint,
      recording: recordingData.data,
      noiseQualityRecord,
      deviceLabel: recordingData.deviceLabel,
      uploadProgressCallback,
    })

    runInAction(() => {
      this.auscultations.unshift(auscultation)
    })

    return auscultation
  }

  async deleteAuscultation(auscultationId: string): Promise<void> {
    await this._medaicaApiService.auscultations.deleteAuscultation(auscultationId)
    this.auscultations = this.auscultations.filter((auscultation) => auscultation.id !== auscultationId)
  }

  get notes(): string {
    return this._notes
  }

  async updateNotes(notes: string): Promise<void> {
    // We use a local cache
    this._notes = notes
    const examId = await this.getOrCreateExamId()
    await this._medaicaApiService.exams.updateNotes(examId, notes)
  }
}

export default InPersonExamStore
