/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { ButtonBase, ButtonBaseProps, CircularProgress, SxProps } from "@mui/material"
import mergeSx from "merge-sx"
import { useTheme } from "@mui/styles"

const Button = ({
  variant = "contained",
  size = "large",
  color = "primary",
  loading = false,
  startIcon,
  ...props
}: {
  variant?: "contained" | "outlined" | "text"
  color?: "primary" | "secondary" | "red"
  size?: "large" | "small"
  loading?: boolean
  startIcon?: ReactElement
} & ButtonBaseProps): ReactElement => {
  const theme = useTheme()
  const { sx, children, disabled, ...rest } = props
  const disabledOrLoading = disabled ?? loading

  const baseSx: SxProps = {
    containedOrOutlined: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "6px",
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "30px",
      minWidth: "64px",
    },
    text: {
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "30px",
      borderRadius: "6px",
      minWidth: "64px",
    },
  }[variant === "text" ? "text" : "containedOrOutlined"]

  const colorSx: SxProps = {
    text: {
      disabled: {},
      primary: {
        color: theme.palette.blue.medium,
        "&:hover": {
          backgroundColor: theme.palette.blue.light[1],
        },
      },
      secondary: {
        color: theme.palette.secondary.dark,
        "&:hover": {
          backgroundColor: "#f6fdfb",
        },
      },
      red: {
        color: theme.palette.red.medium,
        "&:hover": {
          backgroundColor: "#FEF6F6",
        },
      },
    },
    contained: {
      disabled: {
        backgroundColor: theme.palette.blue.light[2],
        borderColor: theme.palette.blue.light[2],
        color: "#fff",
      },
      primary: {
        backgroundColor: theme.palette.blue.medium,
        borderColor: theme.palette.blue.light[3],
        color: "#fff",
        "&:hover": {
          backgroundColor: "#2B2ADD",
        },
      },
      secondary: {
        backgroundColor: theme.palette.green.medium,
        borderColor: theme.palette.blue.light[2],
        color: theme.palette.blue.dark,
        "&:hover": {
          backgroundColor: "#4AD8B0",
        },
      },
      red: {
        backgroundColor: theme.palette.red.medium,
        borderColor: "#f49090",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#f06969",
        },
      },
    },
    outlined: {
      disabled: {
        backgroundColor: "#FFF",
        borderColor: theme.palette.blue.light[2],
        color: theme.palette.gray.light,
      },
      primary: {
        backgroundColor: theme.palette.blue.light[2],
        borderColor: theme.palette.blue.medium,
        color: theme.palette.blue.medium,
        "&:hover": {
          backgroundColor: "#E9E9F9",
        },
      },
      secondary: {
        backgroundColor: "#FFF",
        borderColor: theme.palette.green.medium,
        color: theme.palette.blue.dark,
        "&:hover": {
          backgroundColor: "#f6fdfb",
        },
      },
      red: {
        backgroundColor: "#FFF",
        borderColor: theme.palette.border.main,
        color: theme.palette.red.medium,
        "&:hover": {
          backgroundColor: "#FEF6F6",
        },
      },
    },
  }[variant][disabledOrLoading ? "disabled" : color]

  const sizeSx = {
    containedOrOutlined: {
      small: {
        fontSize: "13px",
        paddingX: "28px",
        height: "32px",
      },
      large: {
        fontSize: "14px",
        paddingX: "40px",
        height: "40px",
      },
    },
    text: {
      small: {
        fontSize: "13px",
        paddingX: "8px",
        height: "32px",
      },
      large: {
        fontSize: "14px",
        paddingX: "12px",
        height: "40px",
      },
    },
  }[variant === "text" ? "text" : "containedOrOutlined"][size]

  if (loading) {
    return (
      <ButtonBase sx={mergeSx(baseSx, colorSx, sizeSx, sx)} disabled={disabledOrLoading} {...rest}>
        <CircularProgress
          size={size === "large" ? "22px" : "16px"}
          sx={{
            "&.MuiCircularProgress-root": { color: variant === "contained" ? "#c7c7f2" : "#cccccf" },
          }}
        />
      </ButtonBase>
    )
  }

  return (
    <ButtonBase sx={mergeSx(baseSx, colorSx, sizeSx, sx)} disabled={disabledOrLoading} {...rest}>
      {startIcon ? (
        <span style={{ display: "flex", alignItems: "center" }}>
          <span>{startIcon}</span>
          <span style={{ marginLeft: "8px" }}>{children}</span>
        </span>
      ) : (
        children
      )}
    </ButtonBase>
  )
}

export default Button
