/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Box, Typography } from "@mui/material"
import React, { ReactElement, useContext, useEffect, useMemo, useState } from "react"
import Video from "@medaica/common/components/video"
import { observer } from "mobx-react-lite"
import { VolumeOffIcon } from "@heroicons/react/outline"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import VideoContainer from "@medaica/common/views/exam/virtual-exam/exam-room/components/video-container"
import AudioMutedIcon from "@medaica/common/components/AudioMutedIcon"
import Target from "./target"
import ParticipantNameLabel from "@medaica/common/views/exam/virtual-exam/exam-room/components/participant-name-label"
import BadNoiseIndicatorBase from "@medaica/common/views/exam/virtual-exam/components/bad-noise-indicator"
import BadNoiseDetector from "@medaica/common/components/audio-player/aquf/bad-noise-detector"
import NoiseFilter from "@medaica/common/components/audio-player/noise-filter"

const ReviewingAuscultationMessage = (): ReactElement => {
  return (
    <div
      className="absolute rounded-3xl text-white z-10 w-1/2 top-1/2 left-1/2 transform -translate-x-1/2
            -translate-y-1/2 flex flex-col justify-center bg-black bg-opacity-60 p-3 rounded-xl"
    >
      <VolumeOffIcon className="opacity-40" />
      <div className="text-xl text-center opacity-60 px-2">The patient has been muted during auscultation review.</div>
    </div>
  )
}

const RecordingAuscultationMessage = (): ReactElement => {
  return (
    <div
      className="absolute rounded-3xl text-white z-10 w-1/2 top-1/2 left-1/2 transform -translate-x-1/2
            -translate-y-1/2 flex flex-col justify-center bg-black bg-opacity-60 p-3 rounded-xl"
    >
      <VolumeOffIcon className="opacity-40" />
      <div className="text-xl text-center opacity-60 px-2">
        You have been muted during the auscultation audio recording.
      </div>
    </div>
  )
}

const MutedIndicator = (): ReactElement => {
  return (
    <div className="absolute top-2.5 left-2.5 z-10">
      <AudioMutedIcon />
    </div>
  )
}

const StethoscopeInfo = ({ isConnected }: { isConnected: boolean }): ReactElement => {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        display: "flex",
        alignItems: "center",
        bottom: theme.spacing(0.5),
        right: theme.spacing(0.5),
        color: "white",
        zIndex: 10,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: theme.shape.borderRadius / 2,
        padding: theme.spacing(0.35, 1),
      })}
    >
      {isConnected ? (
        <>
          <div className="h-3 w-3 rounded-full bg-green-400 mr-2" />
          <Typography variant="body2">Stethoscope connected</Typography>
        </>
      ) : (
        <>
          <div className="h-3 w-3 rounded-full bg-red-400 mr-2" />
          <Typography variant="body2">Stethoscope disconnected</Typography>
        </>
      )}
    </Box>
  )
}

const BadNoiseIndicator = observer((): ReactElement => {
  const [isBadNoiseDetected, setIsBadNoiseDetected] = useState(false)
  const badNoiseDetector = useMemo(() => new BadNoiseDetector(new NoiseFilter()), [])
  const { avStore } = useContext(VirtualExamContext)
  const [usePatientAudio, setUsePatientAudio] = useState(true)

  useEffect(() => {
    if (avStore.remoteDataTrack) {
      avStore.remoteDataTrack?.on("message", (message) => {
        const payload = JSON.parse(message as string)
        if (payload.type === "badNoiseDetected") {
          setUsePatientAudio(true)
          setIsBadNoiseDetected(payload.detail as boolean)
        }
      })
    }
  }, [avStore.remoteDataTrack])

  useEffect(() => {
    if (!usePatientAudio) {
      const mediaStreamTrack = avStore.remoteParticipant?.auscultationAudioTrack?.mediaStreamTrack
      if (mediaStreamTrack) {
        const mediaStream = new MediaStream([mediaStreamTrack])
        badNoiseDetector.start(mediaStream)
        return () => {
          badNoiseDetector.stop()
        }
      }
    }
  }, [avStore.remoteParticipant?.auscultationAudioTrack?.mediaStreamTrack, badNoiseDetector, usePatientAudio])

  return usePatientAudio ? (
    <BadNoiseIndicatorBase
      isVisible={isBadNoiseDetected}
      isMicrophoneMuted={!!avStore.remoteParticipant?.auscultationAudioIsMuted}
    />
  ) : (
    <BadNoiseIndicatorBase
      isVisible={badNoiseDetector.isBadNoiseDetected}
      isMicrophoneMuted={!!avStore.remoteParticipant?.auscultationAudioIsMuted}
    />
  )
})

const VideoSet = observer((): ReactElement => {
  const { avStore, virtualExamStore, mediaDeviceStore } = useContext(VirtualExamContext)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (virtualExamStore.examEntryRequest) {
      setIsLoading(virtualExamStore.examEntryRequest?.status === "approved")
    }
  }, [virtualExamStore.examEntryRequest])

  return (
    <VideoContainer>
      <div
        className="absolute top-2 right-2 text-white z-10 bg-gray-300 panel"
        style={{ width: "160px", height: "200px" }}
      >
        <Video
          isLocal={true}
          videoTrack={mediaDeviceStore.videoDevice?.track}
          audioTrack={mediaDeviceStore.audioDevice?.track}
          isMuted={mediaDeviceStore.videoDeviceIsMuted}
        />
      </div>
      <div>
        <Video
          videoTrack={avStore.remoteParticipant?.videoTrack}
          audioTrack={avStore.remoteParticipant?.audioTrack}
          isLocal={false}
          isMuted={avStore.remoteParticipant?.videoIsMuted}
          isLoading={isLoading}
        />
        {avStore.remoteParticipant && (
          <>
            {virtualExamStore.isReviewingAuscultation && <ReviewingAuscultationMessage />}
            {virtualExamStore.isRecordingAuscultation && <RecordingAuscultationMessage />}
            {((avStore.remoteParticipant.auscultationAudioTrack &&
              avStore.remoteParticipant.auscultationAudioIsMuted) ||
              (!avStore.remoteParticipant.auscultationAudioTrack && avStore.remoteParticipant.audioIsMuted)) && (
              <MutedIndicator />
            )}
            {virtualExamStore.patientProvidedName && (
              <ParticipantNameLabel name={virtualExamStore.patientProvidedName} />
            )}
            <StethoscopeInfo isConnected={virtualExamStore.isM1Connected} />
          </>
        )}
        {avStore.remoteParticipant?.auscultationAudioTrack && <BadNoiseIndicator />}
      </div>
      {virtualExamStore.isTargetEnabled && <Target />}
    </VideoContainer>
  )
})

export default VideoSet
