/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React from "react"
import { SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack"
import Alert from "@mui/material/Alert"

const Warning = React.forwardRef<HTMLDivElement, { id: SnackbarKey; message: SnackbarMessage }>((props, ref) => {
  const { message } = props

  return (
    <SnackbarContent ref={ref} role="alert">
      <Alert
        sx={{
          borderColor: (theme) => theme.palette.error.light,
          borderWidth: "1px",
        }}
        severity="error"
      >
        {message}
      </Alert>
    </SnackbarContent>
  )
})

export default Warning
