/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Alert, AlertTitle } from "@mui/material"
import { genericSystemErrorMessage } from "@medaica/common/const"

const SystemErrorAlert = (): ReactElement => {
  return (
    <Alert severity="error" aria-label="Unexpected error">
      <AlertTitle>Unexpected error</AlertTitle>
      {genericSystemErrorMessage}
    </Alert>
  )
}

export default SystemErrorAlert
