/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "@medaica/common/components/forms/text-input"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { logError } from "@medaica/common/services/util"
import Header from "@medaica/common/components/page/page-header"
import { Alert, LoadingButton } from "@mui/lab"
import useAuth from "hooks/auth"
import { HealthcareProvider } from "@medaica/common/types"
import { useSnackbar } from "notistack"

const UpdateUserInfoForm = ({
  onComplete,
  healthcareProvider,
}: {
  onComplete: () => void
  healthcareProvider: HealthcareProvider
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <div className="max-w-3xl">
      <Formik
        initialValues={{
          firstName: healthcareProvider.firstName || "",
          lastName: healthcareProvider.lastName || "",
          displayName: healthcareProvider.displayName || "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(100, "First name cannot be more than 100 characters.")
            .required("First name is required."),
          lastName: Yup.string()
            .max(100, "Last name cannot be more than 100 characters.")
            .required("last name is required."),
          displayName: Yup.string()
            .max(100, "Display name cannot be more than 100 characters.")
            .required("Display name is required."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage(null)
          try {
            await medaicaApiService.healthcareProviders.updateHealthcareProvider(user.id, {
              firstName: values.firstName,
              lastName: values.lastName,
              displayName: values.displayName,
            })
            enqueueSnackbar("Your user profile has been updated.", {
              variant: "success",
            })
            onComplete()
          } catch (error) {
            setErrorMessage("There was an error submitting your information. Please try again later.")
            logError(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <Header
              title="Your user profile"
              summary="We're missing some of your information. Before continuing,
              please complete the user profile form."
            />
            <div className="space-y-8">
              <div>
                {!!errorMessage && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <TextInput
                      label="First name"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="given-name"
                      type="text"
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <TextInput
                      label="Last name"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="given-name"
                      type="text"
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <TextInput
                      label="Display name"
                      name="displayName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      errors={errors}
                      touched={touched}
                      helpText="This is how you will be identified to other users, e.g. Dr. Smith."
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-6 border-t pt-4">
              <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                Continue
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateUserInfoForm
