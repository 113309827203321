/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import { auscultationPoints } from "@medaica/common/const"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import AuscultationRequest from "views/exam/virtual-exam/stores/auscultation-request"
import { AuscultationPoint } from "@medaica/common/types"
import { classNames } from "@medaica/common/services/util"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"

const SelectPointStep = ({
  auscultationRequest,
  onPointSelected,
}: {
  auscultationRequest: AuscultationRequest
  onPointSelected: () => void
}): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const auscultationPointButtonClicked = (auscultationPoint: AuscultationPoint) => {
    auscultationRequest.auscultationPoint = auscultationPoint
    onPointSelected()
  }
  return (
    <div className="panel border-primary-150 bg-primary-100">
      <div className="p-3">
        <h4 className="font-semibold header-color">Select Auscultation Point</h4>
        <dl>
          <dt className="mt-5 mb-1">Heart</dt>
          <dd>
            <ol className="flex space-x-1">
              {auscultationPoints.heart.map((auscultationPoint) => {
                return (
                  <li key={auscultationPoint.label}>
                    <button
                      aria-label={auscultationPoint.longLabel}
                      onClick={() => auscultationPointButtonClicked(auscultationPoint)}
                      className={classNames(
                        auscultationRequest.auscultationPoint === auscultationPoint
                          ? "ring-2 ring-primary-300"
                          : "ring-0",
                        "border bg-gray-50 hover:ring-2 hover:ring-primary-300 rounded-md"
                      )}
                    >
                      <AuscultationPointImage height="100%" width="100%" highlightedPoint={auscultationPoint} />
                    </button>
                  </li>
                )
              })}
            </ol>
          </dd>
          <dt className="mt-2 mb-1">Lungs</dt>
          <dd>
            <ol className="flex space-x-1">
              {auscultationPoints.lungs.map((auscultationPoint) => {
                return (
                  <li key={auscultationPoint.label}>
                    <button
                      onClick={() => auscultationPointButtonClicked(auscultationPoint)}
                      className={classNames(
                        auscultationRequest.auscultationPoint === auscultationPoint
                          ? "ring-2 ring-primary-300"
                          : "ring-0",
                        "border bg-gray-50 hover:ring-2 hover:ring-primary-300 rounded-md"
                      )}
                    >
                      <AuscultationPointImage height="100%" width="100%" highlightedPoint={auscultationPoint} />
                    </button>
                  </li>
                )
              })}
            </ol>
          </dd>
        </dl>
      </div>
      <div className="flex flex-row-reverse justify-between mt-7 border-t border-primary-150 p-3">
        <button
          className="button-sm border hover:bg-primary-125 hover:text-primary-500 text-primary-400 border-primary-200"
          onClick={() => virtualExamStore.cancelAuscultationRequest()}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default SelectPointStep
