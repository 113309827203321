/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import AuscultationDetailsExpanded from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/review-auscultation-view/components/auscultation-details-expanded"
import AuscultationDetailsContracted from "views/exam/virtual-exam/exam-room/components/auscultation-request/components/review-auscultation-view/components/auscultation-details-contracted"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { observer } from "mobx-react-lite"
import { Auscultation } from "@medaica/common/types"

const ReviewView = observer(({ auscultation }: { auscultation: Auscultation }): ReactElement => {
  const { uiStore } = useContext(VirtualExamContext)

  const setIsExpanded = (isExpanded: boolean): void => {
    if (isExpanded) {
      uiStore.virtualExam.activeCardAuscultationId = auscultation.id
    } else {
      uiStore.virtualExam.activeCardAuscultationId = null
    }
  }

  return uiStore.virtualExam.activeCardAuscultationId === auscultation.id ? (
    <AuscultationDetailsExpanded auscultation={auscultation} onContract={() => setIsExpanded(false)} />
  ) : (
    <AuscultationDetailsContracted auscultation={auscultation} onExpand={() => setIsExpanded(true)} />
  )
})

export default ReviewView
