/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import PatientDetailsForm from "views/patient/components/patient-details-form"
import { PatientProfile } from "@medaica/common/types"
import { plainToClassFromExist, instanceToInstance } from "class-transformer"
import { logError } from "@medaica/common/services/util"
import { isMedaicaApiError, MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import SimpleDialog from "@medaica/common/components/dialog/simple-dialog"
import { useSnackbar } from "notistack"

const UpdatePatientDialog = ({
  isOpen,
  onClose,
  patientProfile,
  onUpdated,
}: {
  isOpen: boolean
  onClose: () => void
  patientProfile: PatientProfile
  onUpdated: (patientProfile: PatientProfile) => void
}): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [error, setError] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()
  const clonedPatientProfile = instanceToInstance(patientProfile)

  function handleUnexpectedError(error) {
    logError(error)
    setError("An unexpected error occurred. Our team has been notified and is looking into it. Please try again.")
  }

  function handleMedaicaApiError(error, emailAddress: string) {
    if (isMedaicaApiError(error)) {
      if (error.type === MedaicaApiErrorType.DUPLICATE_PATIENT_PROFILE) {
        setError(`A patient with the email address ${emailAddress} already exists. Email addresses must be unique.`)
      } else if (error.type === MedaicaApiErrorType.UNAUTHORIZED) {
        setError("You are not allowed to update this patient. Please contact your administrator.")
      } else {
        handleUnexpectedError(error)
      }
    } else {
      handleUnexpectedError(error)
    }
  }

  const handleSubmit = async (values: { emailAddress: string }, setSubmitting: (arg0: boolean) => void) => {
    try {
      await medaicaApiService.patientProfiles.updatePatientProfile(plainToClassFromExist(clonedPatientProfile, values))
      enqueueSnackbar("The patient's information has been updated")
      onUpdated(patientProfile)

      if (onClose) {
        onClose()
      }
    } catch (error) {
      handleMedaicaApiError(error, values.emailAddress)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <>
      <SimpleDialog
        message={error}
        onClose={() => {
          setError(undefined)
          onClose()
        }}
      />
      <PatientDetailsForm
        title="Update Patient"
        submitButtonValue="Save"
        onSubmit={handleSubmit}
        onClose={() => {
          onClose?.()
        }}
        isOpen={isOpen}
        patientProfile={patientProfile}
      />
    </>
  )
}

export default UpdatePatientDialog
