/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import { Box } from "@mui/material"
import Target from "@medaica/common/components/icons/target"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"

const targetHeight = 51
const targetWidth = 51

const TargetOverlay = (): ReactElement => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const boundingElRef = useRef<HTMLDivElement>(null)
  const targetElRef = useRef<HTMLDivElement>(null)
  const offsetRef = useRef({ x: 0, y: 0 })
  const { avStore } = useContext(VirtualExamContext)

  useEffect(() => {
    // Set initial position
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boundingElClientRect = boundingElRef.current!.getBoundingClientRect()
    setCoordinates({
      x: boundingElClientRect.width - targetWidth - 10,
      y: boundingElClientRect.height - targetHeight - 10,
    })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const percentX = coordinates.x / boundingElRef.current!.getBoundingClientRect().width
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const percentY = coordinates.y / boundingElRef.current!.getBoundingClientRect().height
    avStore.localDataTrack?.send(JSON.stringify({ type: "targetPos", detail: [percentX, percentY] }))
  }, [avStore, coordinates])

  const onDown = (event) => {
    setIsDragging(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const targetEl = targetElRef.current!
    targetEl.setPointerCapture(event.pointerId as number)
    const boundingRect = targetEl.getBoundingClientRect()
    offsetRef.current = {
      x: event.pageX - boundingRect.x - targetWidth / 2,
      y: event.pageY - boundingRect.y - targetHeight / 2,
    }
  }

  const onMove = (event) => {
    if (!isDragging) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boundingElClientRect = boundingElRef.current!.getBoundingClientRect()
    const maxX = boundingElClientRect.width
    const maxY = boundingElClientRect.height

    // We set the coordinates to the center of the target and ensure that it doesn't go out of bounds
    setCoordinates({
      x: Math.max(Math.min(event.pageX - boundingElClientRect.x - offsetRef.current.x, maxX), 0),
      y: Math.max(Math.min(event.pageY - boundingElClientRect.y - offsetRef.current.y, maxY), 0),
    })
  }

  const onUp = () => setIsDragging(false)

  return (
    <Box ref={boundingElRef} sx={{ position: "absolute", height: "100%", width: "100%" }}>
      <Box
        ref={targetElRef}
        sx={{
          height: `${targetHeight}px`,
          width: `${targetWidth}px`,
          position: "absolute",
          left: coordinates.x - targetWidth / 2,
          top: coordinates.y - targetHeight / 2,
          touchAction: "none",
          borderRadius: "50%",
          opacity: isDragging ? 1 : 0.8,
          cursor: "move",
          zIndex: 100,
        }}
        onPointerDown={onDown}
        onPointerMove={onMove}
        onPointerUp={onUp}
        onPointerCancel={onUp}
      >
        <Target />
      </Box>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: "1px",
          width: "100%",
          top: coordinates.y - 0.5,
          left: 0,
          opacity: 0.4,
          zIndex: 10,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: "100%",
          width: "1px",
          top: 0,
          left: coordinates.x - 0.5,
          opacity: 0.4,
          zIndex: 10,
        }}
      />
    </Box>
  )
}

export default TargetOverlay
