/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react"
import Lobby from "views/exam/virtual-exam/lobby"
import Exam from "views/exam/virtual-exam/exam-room"
import Switch, { Case, Default } from "react-switch-case"
import { VirtualExamContextProvider } from "views/exam/virtual-exam/virtual-exam-context"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import UIStore from "./stores/ui-store"
import { Paths } from "const"
import CompatibilityCheck, { FailureReason } from "@medaica/common/components/compatibility-check"
import CompatibilityCheckFailure from "@medaica/common/views/exam/virtual-exam/CompatibilityCheckFailure"
import PreferencesStore from "@medaica/common/views/exam/virtual-exam/stores/preferences-store"
import MediaDeviceStore from "@medaica/common/services/media-device-store"
import { ExamType } from "@medaica/common/const"
import { logError } from "@medaica/common/services/util"

const VirtualExam = observer((): ReactElement => {
  const navigate = useNavigate()
  const uiStore = useRef(new UIStore(navigate, "lobby", Paths.home))
  const preferencesStoreRef = useRef(new PreferencesStore())
  const mediaDeviceStoreRef = useRef(
    new MediaDeviceStore(preferencesStoreRef.current.audioDeviceId, preferencesStoreRef.current.videoDeviceId)
  )

  const [compatibilityCheck, setCompatibilityCheck] = useState<{
    status: "inProgress" | "passed" | "failed"
    failureReason?: FailureReason
  }>({ status: "inProgress" })

  const handleCompatibilityCheckComplete = (passed: boolean, failureReason: FailureReason) => {
    setCompatibilityCheck(passed ? { status: "passed" } : { status: "failed", failureReason: failureReason })
  }

  // We make sure that once we leave the context, we dispose of all resources.
  // In particular, we want to stop the media streams so that any indicator that the streams are still active is
  // turned off, e.g., the little green light by a laptop's webcam.
  useEffect(() => {
    if (compatibilityCheck.status === "passed") {
      mediaDeviceStoreRef.current.start().catch(logError)
    }
    const currentMediaDeviceStore = mediaDeviceStoreRef.current
    return () => {
      currentMediaDeviceStore.dispose()
    }
  }, [compatibilityCheck.status])

  return (
    <Switch condition={compatibilityCheck.status}>
      <Case value="failed">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <CompatibilityCheckFailure failureReason={compatibilityCheck.failureReason!} />
      </Case>
      <Case value="passed">
        <VirtualExamContextProvider
          uiStore={uiStore.current}
          preferencesStore={preferencesStoreRef.current}
          mediaDeviceStore={mediaDeviceStoreRef.current}
        >
          <Switch condition={uiStore.current.view}>
            <Case value="exam">
              <Exam />
            </Case>
            <Default>
              <Lobby />
            </Default>
          </Switch>
        </VirtualExamContextProvider>
      </Case>
      <Default>
        <CompatibilityCheck examType={ExamType.Virtual} onComplete={handleCompatibilityCheckComplete} />
      </Default>
    </Switch>
  )
})

export default VirtualExam
