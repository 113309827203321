/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { createContext, ReactElement, ReactNode } from "react"
import PreferencesStore from "@medaica/common/views/exam/virtual-exam/stores/preferences-store"
import UIStore from "./stores/ui-store"
import MediaDeviceStore from "@medaica/common/services/media-device-store"
import { observer } from "mobx-react-lite"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import VirtualExamStore from "views/exam/virtual-exam/stores/virtual-exam-store"

type VirtualExamContextInterface = {
  preferencesStore: PreferencesStore
  mediaDeviceStore: MediaDeviceStore
  uiStore: UIStore
  avStoreMaybe: AVStore | undefined
  avStore: AVStore
  virtualExamStoreMaybe: VirtualExamStore | undefined
  virtualExamStore: VirtualExamStore
}

const VirtualExamContext = createContext<VirtualExamContextInterface>(null as unknown as VirtualExamContextInterface)

const VirtualExamContextProvider = observer(
  ({
    uiStore,
    virtualExamStore,
    avStore,
    preferencesStore,
    mediaDeviceStore,
    children,
  }: {
    uiStore: UIStore
    virtualExamStore?: VirtualExamStore
    avStore?: AVStore
    preferencesStore: PreferencesStore
    mediaDeviceStore: MediaDeviceStore
    children: ReactNode
  }): ReactElement => {
    return (
      <VirtualExamContext.Provider
        value={{
          preferencesStore,
          mediaDeviceStore,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          avStore: avStore!,
          avStoreMaybe: avStore,
          uiStore,
          virtualExamStoreMaybe: virtualExamStore,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          virtualExamStore: virtualExamStore!,
        }}
      >
        {children}
      </VirtualExamContext.Provider>
    )
  }
)

export { VirtualExamContextProvider }
export default VirtualExamContext
