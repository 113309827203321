/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext } from "react"
import ActionPanel from "@medaica/common/views/exam/virtual-exam/lobby/action-panel"
import ActionPanelTitle from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-title"
import ActionPanelBody from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-body"
import ActionPanelActions from "@medaica/common/views/exam/virtual-exam/lobby/action-panel-actions"
import { Button } from "@mui/material"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import LobbyBase from "@medaica/common/views/exam/virtual-exam/lobby"

const ReenterView = ({ patientName }: { patientName: string }): ReactElement => {
  const { uiStore, preferencesStore, mediaDeviceStore } = useContext(VirtualExamContext)
  return (
    <LobbyBase mediaDeviceStore={mediaDeviceStore} preferencesStore={preferencesStore}>
      <ActionPanel>
        <ActionPanelTitle>You have left the exam</ActionPanelTitle>
        <ActionPanelBody>
          Click <strong>Re-enter exam</strong> to re-enter the exam with {patientName}.
        </ActionPanelBody>
        <ActionPanelActions>
          <Button
            onClick={() => {
              uiStore.navigate("exam")
            }}
            variant="contained"
          >
            Re-enter exam
          </Button>
          <Button onClick={uiStore.returnToDashboard} variant="outlined">
            Return to dashboard
          </Button>
        </ActionPanelActions>
      </ActionPanel>
    </LobbyBase>
  )
}

export default ReenterView
