/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { HTMLProps, ReactElement, useCallback, useEffect, useMemo, useRef } from "react"
import { auscultationMicSampleRateInHz } from "@medaica/common/const"
import _ from "lodash"

const get150HzBrickWallFilters = (audioContext): BiquadFilterNode[] =>
  _.range(8).map(() => {
    const biquadFilter = audioContext.createBiquadFilter()
    biquadFilter.type = "lowpass"
    biquadFilter.frequency.value = 150
    biquadFilter.Q.value = 0.7071
    return biquadFilter as BiquadFilterNode
  })

const get30HzHighPassBrickWallFilters = (audioContext): BiquadFilterNode[] =>
  _.range(8).map(() => {
    const biquadFilter = audioContext.createBiquadFilter()
    biquadFilter.type = "highpass"
    biquadFilter.frequency.value = 30
    biquadFilter.Q.value = 1
    return biquadFilter as BiquadFilterNode
  })

const setup = (): [AudioContext, Uint8Array, ChannelSplitterNode, AnalyserNode, BiquadFilterNode] => {
  const audioContext = new (window.AudioContext || window["webkitAudioContext"])({
    sampleRate: auscultationMicSampleRateInHz,
  })
  const splitter = audioContext.createChannelSplitter(2)

  const gainNode = audioContext.createGain()
  gainNode.gain.value = 0

  const analyser = audioContext.createAnalyser()
  analyser.fftSize = 1024
  analyser.smoothingTimeConstant = 0.8

  const amplitudeArray = new Uint8Array(analyser.frequencyBinCount)

  const lowPassFilters = get150HzBrickWallFilters(audioContext)

  const highPassFilters = get30HzHighPassBrickWallFilters(audioContext)

  // Chain the low pass filters together
  for (let i = 1; i < lowPassFilters.length; i++) {
    lowPassFilters[i - 1].connect(lowPassFilters[i])
  }

  // Connect the last low pass filter to the first high pass filter
  lowPassFilters[lowPassFilters.length - 1].connect(highPassFilters[0])

  // Chain the high pass filters together
  for (let i = 1; i < highPassFilters.length; i++) {
    highPassFilters[i - 1].connect(highPassFilters[i])
  }

  // Connect the last high pass filter to the rest of the stuff
  highPassFilters[highPassFilters.length - 1].connect(analyser).connect(gainNode).connect(audioContext.destination)

  return [audioContext, amplitudeArray, splitter, analyser, lowPassFilters[0]]
}

const Phonocardiogram2 = ({
  stream,
  framerate = 15,
  ...props
}: {
  stream: MediaStream | null
  framerate?: number
} & HTMLProps<HTMLCanvasElement>): ReactElement => {
  const displayRef = useRef<HTMLCanvasElement>(null)
  const animationFrameIdRef = useRef<number | null>(null)
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window["mozRequestAnimationFrame"] ||
    window["webkitRequestAnimationFrame"] ||
    window["msRequestAnimationFrame"]
  const cnvWidth = 210
  const cnvHeight = 70

  const [audioContext, amplitudeArray, splitter, analyser, filterEntryPoint] = useMemo(setup, [])

  const startDrawingWaveform = useCallback(
    (stream, canvas: HTMLCanvasElement) => {
      const drawingContext = canvas.getContext("2d")
      const waveformsToDraw: number[] = new Array(cnvWidth).fill(0)
      const m = 255 / cnvHeight

      const drawWaveform = () => {
        analyser.getByteTimeDomainData(amplitudeArray)

        const avg = amplitudeArray.reduce((a, b) => a + b) / amplitudeArray.length
        waveformsToDraw.pop()
        waveformsToDraw.unshift(avg / m)

        if (drawingContext) {
          drawingContext.clearRect(0, 0, cnvWidth, cnvHeight)
          drawingContext.scale(1, -1)
          drawingContext.translate(0, -canvas.height)

          drawingContext.lineWidth = 1
          drawingContext.strokeStyle = "#33d399"

          drawingContext.beginPath()

          for (let x = 0; x < waveformsToDraw.length; x++) {
            const y = cnvHeight - waveformsToDraw[x]
            if (x === 0) {
              drawingContext.moveTo(x, y)
            } else {
              drawingContext.lineTo(x, y)
            }
          }
          drawingContext.stroke()
        }

        animationFrameIdRef.current = requestAnimationFrame(drawWaveform)
      }
      drawWaveform()
    },
    [analyser, requestAnimationFrame, amplitudeArray]
  )

  const stopDrawingWaveform = () => {
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current)
    }
  }

  useEffect(() => {
    if (stream && displayRef.current) {
      const source = audioContext.createMediaStreamSource(stream)
      source.connect(splitter).connect(filterEntryPoint, 0)
      startDrawingWaveform(stream, displayRef.current)
    } else {
      stopDrawingWaveform()
    }
    return () => {
      stopDrawingWaveform()
    }
  }, [stream, startDrawingWaveform, audioContext, splitter, filterEntryPoint])

  return <canvas {...props} height={cnvHeight} width={cnvWidth} ref={displayRef} />
}

export default Phonocardiogram2
