/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useRef, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, LinearProgress } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import AuscultationPointImage from "@medaica/common/components/images/auscultation-point"
import AudioPlayer from "@medaica/common/components/audio-player"
import { AuscultationData } from "../index"
import UndismissableDialog from "@medaica/common/components/dialog/undismissable-dialog"
import { useSnackbar } from "notistack"
import InPersonExamContext from "views/exam/in-person-exam/in-person-exam-context"
import { MedaicaFilters } from "@medaica/common/components/audio-player/filters"
import { buildAuscultationFileName } from "@medaica/common/services/factories"
import { WAFFileContext } from "@medaica/common/components/audio-player/web-audio-filters"

const SelectPointView = ({
  auscultationData,
  onBackButtonClicked,
  onClose,
}: {
  auscultationData: AuscultationData
  onBackButtonClicked: () => void
  onClose: () => void
}): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const { inPersonExamStore } = useContext(InPersonExamContext)
  const [discardConfirmationDialogIsOpen, setDiscardConfirmationDialogIsOpen] = useState(false)
  const audioFileRef = useRef({
    filename: buildAuscultationFileName(auscultationData.auscultationPoint, new Date(), auscultationData.mimeType),
    data: auscultationData.recording,
  })
  const filterContextRef = useRef<WAFFileContext>(new WAFFileContext())
  const handleFiltersChangedEvent = (data: string) => {
    // Handle the event and data received from changed filter types
  }

  const onSaveButtonClicked = async () => {
    setIsUploading(true)

    const uploadProgressCallback = (progress: number) => {
      setUploadProgress(progress)
    }

    await inPersonExamStore.addAuscultation(
      {
        data: auscultationData.recording,
        mimeType: auscultationData.mimeType,
        deviceLabel: auscultationData.deviceLabel,
      },
      auscultationData.auscultationPoint,
      auscultationData.noiseQualityRecord,
      uploadProgressCallback
    )

    if (onClose) {
      onClose()
    }

    enqueueSnackbar("Auscultation successfully saved")
  }

  return (
    <>
      <DialogContent>
        <Dialog open={discardConfirmationDialogIsOpen} onClose={() => setDiscardConfirmationDialogIsOpen(false)}>
          <DialogContent>Are you sure you want to discard this auscultation?</DialogContent>
          <DialogActions>
            <Button color="neutral" onClick={() => setDiscardConfirmationDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={onClose} color="error">
              Discard
            </Button>
          </DialogActions>
        </Dialog>
        <UndismissableDialog open={isUploading}>
          <DialogContent>
            <div style={{ minWidth: "20rem" }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <div className="mt-2 text-center text-sm">Uploading...</div>
            </div>
          </DialogContent>
        </UndismissableDialog>
        <div className="p-3">
          <div className="w-full">
            <figure className="flex flex-col">
              <AuscultationPointImage
                highlightedPoint={auscultationData.auscultationPoint}
                className="w-24 h-24 border border-gray-200 rounded-md bg-gray-50"
                style={{ padding: "2px" }}
              />
            </figure>

            <div className="flex flex-col flex-grow" style={{ width: "100%" }}>
              <div className="flex flex-grow items-start">
                <div className="ml-3 flex-grow">
                  <h4 className="font-semibold header-color">{auscultationData.auscultationPoint.longLabel}</h4>
                </div>
              </div>

              <div className="mt-5 ml-3 panel border p-5 bg-gray-50">
                <div>
                  <div className="flex space-x-5">
                    <MedaicaFilters
                      filterContext={filterContextRef.current}
                      onFiltersChangeEvent={handleFiltersChangedEvent}
                      disableSetting={false}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <div className="bg-black mb-1" />
                  <div className="mt-3">
                    <AudioPlayer
                      noiseQualityRecord={auscultationData.noiseQualityRecord}
                      filterContext={filterContextRef.current}
                      audioFile={audioFileRef.current}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex">
          <div className="flex-grow">
            <Button color="neutral" startIcon={<ChevronLeftIcon />} onClick={onBackButtonClicked}>
              Back
            </Button>
          </div>
          <Button color="neutral" onClick={() => setDiscardConfirmationDialogIsOpen(true)}>
            Discard
          </Button>
          <Button onClick={() => void onSaveButtonClicked()}>Save</Button>
        </div>
      </DialogActions>
    </>
  )
}

export default SelectPointView
