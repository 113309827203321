/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Switch, { Case } from "react-switch-case"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"
import SetPasswordForm from "views/auth/post-auth-pipeline/update-password"
import useAuth from "hooks/auth"
import { HealthcareProvider } from "@medaica/common/types"
import { useLocation, useNavigate } from "react-router-dom"
import { Paths } from "const"
import AgreeTCsForm from "views/auth/post-auth-pipeline/agree-tcs"
import UpdateUserInfoForm from "views/auth/post-auth-pipeline/update-user-info"
import { getConfigNumber } from "@medaica/common/services/util"

const PostAuthPipeline = (): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [step, setStep] = useState(1)
  const [view, setView] = useState<"updatePassword" | "onboard" | "agreeTCs" | "updateUserInfo" | null>(null)
  const [healthcareProvider, setHealthcareProvider] = useState<HealthcareProvider | null>(null)
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (healthcareProvider) {
      if (step === 1) {
        if (healthcareProvider.mustChangePassword) {
          setView("updatePassword")
        } else {
          setStep(2)
        }
      }
      // If the user hasn't agreed to the latest T&Cs, we sent them to the T&Cs form
      else if (step === 2) {
        if (healthcareProvider.tcVersion < getConfigNumber("TC_VERSION")) {
          setView("agreeTCs")
        } else {
          setStep(3)
        }
      }
      // If we have missing user info, we send them to the user details form
      else if (step === 3) {
        if (!healthcareProvider.firstName || !healthcareProvider.lastName || !healthcareProvider.displayName) {
          setView("updateUserInfo")
        } else {
          setStep(4)
        }
      }
      // We're now at effectively step 4
      else {
        navigate((location?.state?.returnTo as string) || Paths.home, { replace: true })
      }
    }
  }, [healthcareProvider, location?.state?.returnTo, navigate, step])

  useEffect(() => {
    // We start working our way through the pipeline
    void (async () => {
      const healthcareProvider = await medaicaApiService.healthcareProviders.getHealthcareProvider(user.id)
      setHealthcareProvider(healthcareProvider)
    })()
  }, [medaicaApiService, user])

  return (
    <LoadingWrapper loading={!view}>
      {healthcareProvider ? (
        <Switch condition={view}>
          <Case value={"updatePassword"}>
            <SetPasswordForm onComplete={() => setStep(2)} firstLogin={!healthcareProvider.tcVersion} />
          </Case>
          <Case value={"agreeTCs"}>
            <AgreeTCsForm onComplete={() => setStep(3)} />
          </Case>
          <Case value={"updateUserInfo"}>
            <UpdateUserInfoForm onComplete={() => setStep(4)} healthcareProvider={healthcareProvider} />
          </Case>
        </Switch>
      ) : (
        <></>
      )}
    </LoadingWrapper>
  )
}

export default PostAuthPipeline
