/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { AppBar, Box, CssBaseline, Drawer, IconButton, Link, List, Toolbar, Tooltip, useTheme } from "@mui/material"
import React, { ReactElement } from "react"
import MedaicaLogo from "@medaica/common/components/medaica-logo-new"
import UserIcon from "@medaica/common/components/icons/user-icon"
import CouncilOfDoctorsIcon from "@medaica/common/components/icons/council-of-doctors-icon"
import MenuItem, { MenuItemData } from "./menu-item"
import { Paths } from "const"
import { Outlet, useNavigate } from "react-router-dom"
import AccountCircleIcon from "@medaica/common/components/icons/account-circle-icon"
import { LogoutIcon } from "@heroicons/react/outline"
import useAuth from "hooks/auth"
import HospitalIcon from "@medaica/common/components/icons/hospital-icon"
import DashboardIcon from "@medaica/common/components/icons/dashboard-icon"

const drawerWidth = 240
const headerHeight = "76px"

const menu: MenuItemData[] = [
  // { icon: DashboardIcon, title: "Dashboard", section: "dashboard", path: "dashboard" },
  { icon: UserIcon, title: "My Patients", section: "patients", path: Paths.patients },
  { icon: CouncilOfDoctorsIcon, title: "Council", section: "council", path: Paths.councilOfDoctors },
  {
    icon: DashboardIcon,
    title: "Exam Calendar",
    section: "calendar",
    path: Paths.liveExamCalendar,
  },
  {
    icon: HospitalIcon,
    title: "Organization",
    section: "organization",
    children: [
      { title: "HCP", path: Paths.organizationHealthcareProviders },
      { title: "Patients", path: Paths.organizationPatients },
      { title: "Exams", path: Paths.organizationExams },
    ],
  },
]

const auxMenu = [
  { name: "About Medaica", href: "https://www.medaica.com", newWindow: true },
  { name: "Support", href: Paths.support, newWindow: true },
  { name: "Contact", href: Paths.contact, newWindow: true },
  { name: "Terms", href: Paths.terms, newWindow: true },
  { name: "Privacy", href: Paths.privacy, newWindow: true },
  { name: "HIPAA", href: Paths.hipaa, newWindow: true },
]

const MainLayout = (): ReactElement => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { logout } = useAuth()

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: "#f6f6fa",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#F0F0FB",
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
        elevation={0}
      >
        <Toolbar sx={{ height: headerHeight }} disableGutters>
          <Box component="nav" mr="26px" sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
            <Box component="ul" sx={{ display: "flex", columnGap: "10px" }}>
              <Tooltip title="Profile and settings">
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    height: "36px",
                    width: "36px",
                    border: `2px solid ${theme.palette.secondary.light}`,
                    borderRadius: "8px",
                    padding: "2px",
                    color: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                    },
                  }}
                  size="large"
                  aria-label="Profile and settings"
                  color="inherit"
                  onClick={() => navigate(Paths.profileAndSettings)}
                >
                  <AccountCircleIcon height="24px" width="24px" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Log out">
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    height: "36px",
                    width: "36px",
                    border: `2px solid ${theme.palette.secondary.light}`,
                    borderRadius: "8px",
                    padding: "2px",
                    color: theme.palette.secondary.main,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                    },
                  }}
                  size="large"
                  aria-label="logout"
                  color="inherit"
                  onClick={() => logout()}
                >
                  <LogoutIcon strokeWidth="1px" height="24px" width="24px" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box sx={{ height: headerHeight, display: "flex", alignItems: "center" }}>
          <Box ml="22px">
            <MedaicaLogo />
          </Box>
        </Box>
        <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <List sx={{ width: "100%" }}>
            {menu.map((item) => (
              <MenuItem menuItemData={item} key={`${item.section}_${item.path}`} />
            ))}
          </List>
          <Box ml="28px" mb="34px">
            <ul style={{ lineHeight: "28px", listStyle: "none", marginLeft: 0, paddingLeft: 0 }}>
              {auxMenu.map((item) => {
                return (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      underline="none"
                      color="text.secondary"
                      sx={{
                        "&:hover": {
                          color: "text.primary",
                        },
                      }}
                      {...(item.newWindow && { target: "_blank" })}
                    >
                      {item.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Box>
        </div>
      </Drawer>
      <Box mt={headerHeight} sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
