/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Button } from "@mui/material"
import NoDataPanel, { Body, Actions, Title } from "@medaica/common/components/no-data-panel"

const NoPatientsView = ({ onAddPatient }: { onAddPatient: () => void }): ReactElement => {
  return (
    <NoDataPanel>
      <Title>You don't have any patients yet</Title>
      <Body>Your organization does not have any patients. Click the button below to start adding patients.</Body>
      <Actions>
        <Button type="button" variant="contained" size="small" onClick={onAddPatient}>
          Add new patient
        </Button>
      </Actions>
    </NoDataPanel>
  )
}

export default NoPatientsView
