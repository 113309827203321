/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode, useState } from "react"
import Page from "@medaica/common/components/page/page"
import Header from "@medaica/common/components/page/page-header"
import { Box, useTheme } from "@mui/material"
import { Link, Outlet } from "react-router-dom"
import { Paths } from "const"
import Paper from "@medaica/common/components/ui/paper"

const pages = {
  personalInfo: { name: "Personal Information", url: Paths.profileAndSettingsPersonalInfo },
  emailAddress: { name: "Change Email", url: Paths.profileAndSettingsEmailAddress },
  password: { name: "Change Password", url: Paths.profileAndSettingsPassword },
}

type PageType = keyof typeof pages

type ProfileAndSettingsContextType = {
  setActions: (actions: ReactNode) => void
  setCurrentPage: (page: PageType) => void
}

const ProfileAndSettings = (): ReactElement => {
  const [actions, setActions] = useState<ReactNode>()
  const [currentPage, setCurrentPage] = useState<PageType>("personalInfo")
  const theme = useTheme()

  return (
    <Page variant="full" pb={0}>
      <Header title="Profile and Settings" actions={actions} />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderRightColor: theme.palette.border.main,
            borderRightWidth: "1px",
            width: "232px",
          }}
        >
          <Box p="28px 18px 0px" sx={{ display: "flex", flexDirection: "column" }}>
            <nav>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {Object.entries(pages).map(([pageType, page]) => (
                  <li key={pageType}>
                    <Link
                      style={{
                        color: pageType === currentPage ? theme.palette.primary.dark : theme.palette.text.secondary,
                      }}
                      to={page.url}
                    >
                      {page.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Outlet context={{ setActions, setCurrentPage }} />
        </Box>
      </Paper>
    </Page>
  )
}

export default ProfileAndSettings
export type { ProfileAndSettingsContextType }
