/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import VirtualExamStoreBase from "@medaica/common/views/exam/virtual-exam/virtual-exam-store"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { action, autorun, computed, makeObservable, observable, runInAction } from "mobx"
import AuscultationRequest from "views/exam/virtual-exam/stores/auscultation-request"
import { Auscultation, TwilioAccessToken } from "@medaica/common/types"
import logger from "@medaica/common/services/logging"
import { Role } from "@medaica/common/const"
import FirebaseVirtualExamService from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import "firebase/database"
import UIStore from "views/exam/virtual-exam/exam-room/stores/ui-store"

class VirtualExamStore extends VirtualExamStoreBase {
  private _notes = ""
  hasLoaded = false
  avStore: AVStore
  isM1Connected = false
  currentAuscultationRequest: AuscultationRequest | null = null
  auscultations: Auscultation[] = []
  uiStore: UIStore
  isTargetEnabled = false

  constructor(
    userId: string,
    virtualExamId: string,
    firebaseVirtualExamService: FirebaseVirtualExamService,
    medaicaApiService: MedaicaApiService,
    avStore: AVStore,
    uiStore: UIStore
  ) {
    super(userId, virtualExamId, firebaseVirtualExamService, medaicaApiService)
    makeObservable(this, {
      addAuscultationRequest: action,
      hasLoaded: observable,
      isM1Connected: observable,
      patientProvidedName: computed,
      load: action,
      currentAuscultationRequest: observable,
      cancelAuscultationRequest: action,
      auscultations: observable,
      isTargetEnabled: observable,
    })

    this.avStore = avStore
    this.uiStore = uiStore

    autorun(() => {
      if (!this.currentAuscultationRequest?.error && this.currentAuscultationRequest?.status === "complete") {
        const auscultation = this.currentAuscultationRequest.auscultation
        this.auscultations.unshift(auscultation)
        this.uiStore.setActiveCardAuscultationId(auscultation.id)
        this.currentAuscultationRequest = null
      }
      this._firebaseVirtualExamService.isTargetEnabledRef.set(this.isTargetEnabled)
      if (this.avStore.remoteParticipant === null) {
        this.currentAuscultationRequest = null
      }
    })
  }

  /**
   * This method performs all initial setup.
   */
  async load(): Promise<void> {
    void this._connectToAvRoom()

    const virtualExam = await this._getVirtualExam()

    this.accessCode = virtualExam.accessCode
    this.patientProfile = virtualExam.patientProfile
    this.healthcareProvider = virtualExam.healthcareProvider
    this.examId = virtualExam.examId

    if (virtualExam.exam) {
      this._notes = virtualExam.exam.notes
      this.auscultations = virtualExam.exam.auscultations.reverse()
    }

    // TODO No longer necessary
    this.registerPresence({
      id: this._userId,
      name: virtualExam.healthcareProvider.displayName,
      role: Role.HealthcareProvider,
    })

    this._wireUpFirebaseListeners()

    this.hasLoaded = true
  }

  private async _connectToAvRoom(): Promise<void> {
    // We check if an AVRoomName has been created for this virtual exam.
    let avRoomName: string | undefined = undefined
    const snapshot = await this._firebaseVirtualExamService.avRoomIdRef.get()
    if (snapshot.exists()) {
      avRoomName = snapshot.val() as string
    }
    let token: TwilioAccessToken
    if (avRoomName) {
      // If we have an AVRoomName we get a token for that room name
      token = await this._medaicaApiService.twilio.getTwilioRoomToken(avRoomName)
    } else {
      // If the twilio room hasn't been created yet, we create the room and get the token for that room
      const response = await this._medaicaApiService.twilio.createTwilioRoom()
      token = response.accessToken
      avRoomName = response.roomName
      await this._firebaseVirtualExamService.avRoomIdRef.set(response.roomName)
    }
    await this.avStore.joinRoom(token.value, avRoomName)
  }

  get notes(): string {
    return this._notes
  }

  protected _wireUpFirebaseListeners = (): void => {
    this._firebaseVirtualExamService.isM1ConnectedRef.on(
      "value",
      action((snapshot) => {
        this.isM1Connected = snapshot.val() as boolean
      })
    )
    super._wireUpFirebaseListeners()
  }

  async updateNotes(notes: string): Promise<void> {
    // We use a local cache
    this._notes = notes
    const examId = await this.getOrCreateExam()
    await this._medaicaApiService.exams.updateNotes(examId, notes)
  }

  get patientProvidedName(): string | undefined {
    return this.participants.find((participant) => participant.role === Role.Patient)?.name as string
  }

  addAuscultationRequest(): void {
    this.currentAuscultationRequest = new AuscultationRequest(
      this,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this._firebaseVirtualExamService,
      this._medaicaApiService,
      this.avStore
    )
  }

  cancelAuscultationRequest(): void {
    this.currentAuscultationRequest = null
    this.disableTarget()
  }

  enableTarget(): void {
    this.isTargetEnabled = true
  }

  disableTarget(): void {
    this.isTargetEnabled = false
  }

  setExamEntryRequestStatus(status: "approved" | "denied"): void {
    runInAction(() => {
      if (this.examEntryRequest) {
        this.examEntryRequest = { ...this.examEntryRequest, status }
      }
    })
    this._firebaseVirtualExamService.examEntryRequestRef.set({ ...this.examEntryRequest, status })
  }

  requestAuscultationMicActivated(): void {
    logger.debug("Requesting auscultation mic activation")
    this._firebaseVirtualExamService.auscultationMicActivationRequestRef.push({
      date: Date.now(),
    })
  }

  requestAuscultationMicDeactivated(): void {
    logger.debug("Requesting auscultation mic deactivation")
    this._firebaseVirtualExamService.auscultationMicDeactivationRequestRef.push({
      date: Date.now(),
    })
  }

  async deleteAuscultation(auscultationId: string): Promise<void> {
    await this._medaicaApiService.auscultations.deleteAuscultation(auscultationId)
    runInAction(() => {
      this.auscultations = this.auscultations.filter((auscultation) => auscultation.id !== auscultationId)
    })
  }

  dispose(): void {
    logger.debug("Disposing exam store")
    this._disposers.forEach((disposer) => disposer())
    super.dispose()
  }
}

export default VirtualExamStore
