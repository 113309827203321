/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import {
  AuthContextInterface,
  User as BaseUser,
  AuthContextProvider as BaseAuthProvider,
} from "@medaica/common/services/auth"
import { User as Auth0User } from "@auth0/auth0-react"

type User = BaseUser & {
  roles: string[]
  organizationId: string
  organizationName: string
}

const buildUser = (baseUser: BaseUser, auth0User: Auth0User): User => {
  return {
    roles: (auth0User["https://medaica.com/roles"] as string[]) || [],
    organizationId: auth0User["https://medaica.com/organization_id"],
    organizationName: auth0User["https://medaica.com/organization_name"],
    ...baseUser,
  }
}

const AuthContext = React.createContext<AuthContextInterface<User>>({} as AuthContextInterface<User>)

const AuthProvider = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <BaseAuthProvider userBuilder={buildUser} context={AuthContext}>
      {children}
    </BaseAuthProvider>
  )
}

export { AuthProvider, AuthContext }
export type { User }
