/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { AuthContextInterface } from "@medaica/common/services/auth"
import { useContext } from "react"
import { AuthContext, User } from "contexts/auth"

const useAuth = (): AuthContextInterface<User> => useContext(AuthContext)

export default useAuth
