/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { AutocompleteRenderInputParams, Button, TextField } from "@mui/material"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { ConnectedFocusError } from "focus-formik-error"
import { useNavigate } from "react-router-dom"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { LoadingButton } from "@mui/lab"
import { Autocomplete } from "formik-mui"
import { PatientProfile } from "@medaica/common/types"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"
import useAuth from "hooks/auth"

const UnspecifiedPatientView = ({
  onPatientSelected,
  startingExam,
}: {
  onPatientSelected: (patientProfileId: string) => void
  startingExam: boolean
}): ReactElement => {
  const navigate = useNavigate()
  const [patients, setPatients] = useState<PatientProfile[]>()
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      const page = await medaicaApiService.patientProfiles.getPatientProfiles({
        filters: [{ field: "organizationId", value: user.organizationId }],
        sort: [{ field: "lastName", direction: "asc" }],
      })
      setPatients(page.items)
    }
    void fetchData()
  }, [medaicaApiService, user])

  const onSubmit = ({ patient }, { setSubmitting }) => {
    setSubmitting(false)
    onPatientSelected(patient.id as string)
  }

  return (
    <LoadingWrapper loading={!patients}>
      <>
        <h3 className="text-3xl">Ready to start the exam?</h3>
        <p className="mt-10">Enter name of the patient for whom you would like to conduct the exam.</p>
        <div className="mt-3 w-full">
          {patients && (
            <div className="flex flex-col items-center">
              <Formik
                initialValues={{
                  patient: null,
                }}
                validationSchema={Yup.object().shape({
                  patient: Yup.object().nullable().required("You must select a patient"),
                })}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="w-full">
                    <ConnectedFocusError />
                    <Field
                      name="patient"
                      component={Autocomplete}
                      options={patients}
                      size="small"
                      getOptionLabel={(patientProfile: PatientProfile) => patientProfile.fullName}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.fullName}
                          </li>
                        )
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "100%",
                          }}
                          name="patient"
                          error={touched["patient"] && !!errors["patient"]}
                          helperText={"Start typing to get a list of possible matches"}
                          variant="outlined"
                        />
                      )}
                    />
                    <div className="flex mt-8 justify-center gap-x-2 flex-row-reverse">
                      <LoadingButton loading={startingExam} type="submit" variant="contained">
                        Start exam
                      </LoadingButton>
                      <Button onClick={() => navigate(-1)} variant="outlined">
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </>
    </LoadingWrapper>
  )
}

export default UnspecifiedPatientView
