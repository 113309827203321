/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react"
import moment from "moment"
import { Exam } from "@medaica/common/types"
import { Paths } from "const"
import useGlobalContext from "@medaica/common/hooks/global-context"
import Table, { buildActionColumn } from "@medaica/common/components/table/table"
import useQuery from "@medaica/common/hooks/query"
import ViewDetailsButton from "@medaica/common/components/table/view-details-button"
import { Box } from "@mui/material"
import ExamStatusChip from "views/exam/components/exam-status-chip"
import { MUIDataTableData } from "mui-datatables"
import { logError, mapExamType } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"

type ExamData = {
  date: string
  type: string
  reviewed: boolean
  conductedBy: string
  actions: Exam
}

const Exams = ({ patientProfileId }: { patientProfileId: string }): ReactElement => {
  const { medaicaApiService } = useGlobalContext()
  const [error, setError] = useState<string>()
  const [exams, setExams] = useState<ExamData[] | null>(null)
  const [count, setCount] = useState<number>()
  const pageNumber = useQuery("p")
  const chipEls: HTMLDivElement[] = []

  /**
   * We want the chips to be the same width. Thus, every time the table updates, we determine the largest chip width.
   *  Once we have that value, we set the width of all the chips to that width.
   */
  const setChipWidths = () => {
    const largestWidth = chipEls.reduce((acc, el) => {
      console.log(acc, el.getBoundingClientRect().width)
      return Math.max(acc, el.getBoundingClientRect().width)
    }, 0)
    chipEls.forEach((el) => {
      el.style.width = `${largestWidth}px`
    })
  }

  const fetchData = useCallback(
    async (pageNumber: number) => {
      try {
        const page = await medaicaApiService.exams.getExams({
          filters: [{ field: "patientProfileId", value: patientProfileId }],
          sort: [{ field: "dateCreated", direction: "desc" }],
          joinedLoads: ["healthcareProvider"],
          pagination: { pageNumber: pageNumber, pageSize: 10 },
        })
        setCount(page.totalCount)
        setExams(
          page.items.map((exam) => {
            return {
              date: moment(exam.dateCreated).format("lll"),
              type: mapExamType(exam.type.toString()),
              reviewed: exam.reviewed,
              conductedBy: exam.healthcareProvider?.displayName,
              actions: exam,
            }
          })
        )
      } catch (e) {
        logError(error)
        setError(
          "An unexpected error occurred. Our team has been notified and is looking into it. Please try again later."
        )
      }
    },
    [error, medaicaApiService.exams, patientProfileId]
  )

  useEffect(() => {
    void fetchData(pageNumber ? parseInt(pageNumber) : 1)
  }, [fetchData, pageNumber])

  return (
    <Box
      sx={{
        borderColor: "#f0f0fb",
        borderWidth: "1px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        overflow: "auto",
      }}
    >
      {exams && (
        <Table
          data={exams as unknown as MUIDataTableData[]}
          title="Exams list"
          options={{
            onTableChange: setChipWidths,
            serverSide: true,
            count: count,
            download: false,
            print: false,
          }}
          columns={[
            {
              label: "Date",
              name: "date",
              options: {
                filter: true,
                sort: true,
              },
            },
            {
              name: "type",
              label: "Type",
              options: {
                sort: true,
              },
            },
            {
              name: "reviewed",
              label: "Status",
              options: {
                sort: true,
                customBodyRender: (isReviewed) => ExamStatusChip({ isReviewed, chipEls }),
              },
            },
            {
              label: "Conducted by",
              name: "conductedBy",
              options: {
                filter: true,
                sort: true,
              },
            },
            buildActionColumn("actions", (exam: Exam) => <ViewDetailsButton to={`${Paths.exams}/${exam.id}`} />),
          ]}
        />
      )}
      {error && <div style={{ textAlign: "center" }}>{error}</div>}
      {!exams && !error && <Loading />}
    </Box>
  )
}

export default Exams
