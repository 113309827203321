/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { Exam, HealthcareProvider } from "@medaica/common/types"
import AuscultationList from "views/exam/exam-details/components/auscultation-list"
import Notes from "views/exam/exam-details/components/notes"
import EnhancedNotes from "views/exam/exam-details/components/enhanced-notes"
import { getAge, logError, mapExamType } from "@medaica/common/services/util"
import { Paths } from "const"
import useGlobalContext from "@medaica/common/hooks/global-context"
import useAuth from "hooks/auth"
import { Link } from "react-router-dom"
import moment from "moment"
import { useSnackbar } from "notistack"
import { Switch } from "@mui/material"
import useQuery from "@medaica/common/hooks/query"
import AuscultationListCombo from "views/exam/exam-details/components/auscultation-list-combo"
import ExamStatusChip from "views/exam/components/exam-status-chip"

const ExamDetails = ({ exam }: { exam: Exam }): ReactElement => {
  const patientProfile = exam.patientProfile
  const { medaicaApiService } = useGlobalContext()
  const { user } = useAuth()
  const [reviewed, setReviewed] = useState(exam.reviewed)
  const { enqueueSnackbar } = useSnackbar()
  const useCombo = useQuery("combo")
  const [primaryDoctor, setPrimaryDoctor] = useState<HealthcareProvider | null>(null)
  // Whether the current primary doctor is the same as the doctor who performed the exam
  const isSamePrimaryDoctor = exam.healthcareProvider?.id === exam.patientProfile?.healthcareProviderId
  const displayHiddenInfo = "**********"
  // Whether the current user is in the same organization as the patient
  const isSameOrganization = user.organizationId === exam?.patientProfile.organizationId

  useEffect(() => {
    const fetchData = async () => {
      if (!medaicaApiService || !exam.id || isSamePrimaryDoctor) {
        return
      }
      // If the current primary doctor is not the doctor who performed the exam,
      // then we need to fetch the primary doctor details
      try {
        const curHealthcareProvider = await medaicaApiService.healthcareProviders.getHealthcareProvider(
          exam.patientProfile.healthcareProviderId
        )
        setPrimaryDoctor(curHealthcareProvider)
      } catch (err) {
        logError(err)
      }
    }

    void fetchData()
  }, [exam.id, exam.patientProfile.healthcareProviderId, isSamePrimaryDoctor, medaicaApiService])

  const reviewedSwitchChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const previousValue = reviewed
    setReviewed(event.target.checked)
    try {
      await medaicaApiService.exams.updateReviewed(exam.id, event.target.checked)
      enqueueSnackbar(`The exam has been marked as ${event.target.checked ? "reviewed" : "unreviewed"}`)
    } catch (err) {
      setReviewed(previousValue)
      enqueueSnackbar("There was an error updating the reviewed status", { variant: "error" })
      logError(err)
    }
  }

  return (
    <div className="flex flex-col">
      <div className="panel">
        <div className="panel-content bg-white">
          <dl className="grid grid-cols-4 gap-x-4 gap-y-8">
            <div>
              <dt className="detail-label">Patient</dt>
              {isSameOrganization ? (
                <dd>
                  <Link className="link" to={`${Paths.patients}/${patientProfile.id}`}>
                    {patientProfile.firstName} {patientProfile.lastName}
                  </Link>
                  <dl className="detail-detail">
                    {patientProfile.sex && (
                      <>
                        <dt>Sex</dt>
                        <dd className="capitalize">{patientProfile.sex}</dd>
                      </>
                    )}
                    {patientProfile.birthdate && (
                      <>
                        <dt>Age</dt>
                        <dd>{getAge(patientProfile.birthdate)}</dd>
                      </>
                    )}
                  </dl>
                </dd>
              ) : (
                <dd>{displayHiddenInfo}</dd>
              )}
            </div>
            <div>
              <dt className="detail-label">Exam date</dt>
              <dd>{moment(exam.dateCreated).format("lll")}</dd>
            </div>
            <div>
              <dt className="detail-label">Exam type</dt>
              <dd>{mapExamType(exam.type.toString())}</dd>
            </div>
            <div>
              <dt className="detail-label">Conducted by</dt>
              <dd>{exam.healthcareProvider?.displayName}</dd>
            </div>
            <div>
              <dt className="detail-label">Primary doctor</dt>
              <dd>{isSamePrimaryDoctor ? exam.healthcareProvider?.displayName : primaryDoctor?.displayName}</dd>
            </div>
            <div></div>
            <div></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ExamStatusChip isReviewed={reviewed} chipEls={[]} />
              <dd style={{ marginLeft: "10px" }}>
                <Switch
                  checked={reviewed}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => void reviewedSwitchChanged(event)}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="flex space-x-5 mt-8">
        <div className="w-2/3">
          {useCombo ? (
            <AuscultationListCombo auscultations={exam.auscultations} />
          ) : (
            <AuscultationList auscultations={exam.auscultations} />
          )}
        </div>
        <div className="w-1/3">
          <Notes exam={exam} />
          <EnhancedNotes exam={exam} />
        </div>
      </div>
    </div>
  )
}

export default ExamDetails
