/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { logError } from "@medaica/common/services/util"
import { Paths } from "const"
import { useNavigate } from "react-router-dom"
import { HealthcareProviderInvitation } from "@medaica/common/types"
import Loading from "@medaica/common/components/loading"
import useQuery from "@medaica/common/hooks/query"
import Switch, { Case } from "react-switch-case"
import CreateForm from "views/auth/create-account/components/create-form"
import ErrorView from "views/auth/create-account/components/error-view"
import CompleteView from "views/auth/create-account/components/complete-view"

const CreateAccountView = (): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [healthcareProviderInvitation, setHealthcareProviderInvitation] = useState<HealthcareProviderInvitation | null>(
    null
  )
  const invitationId = useQuery("t")
  const navigate = useNavigate()
  const [view, setView] = useState<"form" | "error" | "complete" | null>(null)

  useEffect(() => {
    void (async () => {
      if (!invitationId) {
        navigate(Paths.errors_404)
      } else {
        try {
          const healthcareProviderInvitation =
            await medaicaApiService.healthcareProviderInvitations.getHealthcareProviderInvitation(invitationId)
          if (!healthcareProviderInvitation.isValid) {
            setErrorMessage(
              "This invitation is no longer valid. If you do not already have a Medaica account, please request a" +
                " new invitation."
            )
            setView("error")
          } else {
            setHealthcareProviderInvitation(healthcareProviderInvitation)
            setView("form")
          }
        } catch (error) {
          setErrorMessage("There is an error with this invitation. Please request another one or try again later.")
          setView("error")
          logError(error)
        }
      }
    })()
  }, [medaicaApiService, navigate, invitationId])

  return view ? (
    <div className="max-w-3xl">
      <Switch condition={view}>
        <Case value="form">
          {healthcareProviderInvitation && (
            <CreateForm
              healthcareProviderInvitation={healthcareProviderInvitation}
              onComplete={() => setView("complete")}
            />
          )}
        </Case>
        <Case value="error">{errorMessage && <ErrorView error={errorMessage} />}</Case>
        <Case value="complete">
          <CompleteView />
        </Case>
      </Switch>
    </div>
  ) : (
    <Loading />
  )
}

export default CreateAccountView
