/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Avatar, Button, IconButton, InputAdornment, OutlinedInput, Tooltip } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { logError, putOnClipboard } from "@medaica/common/services/util"
import { VirtualExam } from "@medaica/common/types"
import { useSnackbar } from "notistack"
import SendInvitationDialog from "views/exam/virtual-exam-calendar/schedule-exam-wizard/components/send-invitation-dialog"

const VirtualExamEventDetails = ({ virtualExam }: { virtualExam: VirtualExam }): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [sendInvitationDialogIsOpen, setSendInvitationDialogIsOpen] = useState(false)
  const handleCopyButtonClicked = (): void => {
    putOnClipboard(virtualExam.link).catch(logError)
    enqueueSnackbar("The link has been copied to your clipboard")
  }

  const onInviteClicked = (): void => {
    setSendInvitationDialogIsOpen(true)
  }
  return (
    <div className="space-y-10">
      <SendInvitationDialog
        virtualExamId={virtualExam.id}
        emailAddress={virtualExam.patientProfile.emailAddress}
        open={sendInvitationDialogIsOpen}
        onClose={() => setSendInvitationDialogIsOpen(false)}
      />
      <div>
        <h4 className="mb-1 font-medium">Access info</h4>
        <OutlinedInput
          size="small"
          onFocus={(e) => e.target.select()}
          sx={{
            minWidth: "400px",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) => theme.palette.grey[400],
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) => theme.palette.grey[400],
              borderWidth: "1px",
            },
          }}
          value={virtualExam.link}
          readOnly
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="Copy link">
                <IconButton aria-label="Copy link" onClick={handleCopyButtonClicked} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </div>
      <div>
        <h4 className="mb-3 font-medium">Participants</h4>
        <ul style={{ display: "grid", gridTemplateColumns: "1fr min-content", gridGap: "12px" }}>
          <div className="flex">
            <Avatar>{virtualExam.healthcareProvider.displayName[0]}</Avatar>
            <div className="ml-2">
              <div className="font-medium">{virtualExam.healthcareProvider.displayName} (You)</div>
              <div className="text-gray-500 text-sm">Healthcare Provider</div>
            </div>
          </div>
          <div />
          <div>
            <div className="flex">
              <Avatar>{virtualExam.patientProfile.fullName[0]}</Avatar>
              <div className="ml-2">
                <div className="flex">
                  <span className="font-medium">{virtualExam.patientProfile.fullName}</span>
                  {/*<div className="-mt-px ml-2 bg-blue-100 text-blue-600 text-sm py-0.5 px-1.5 rounded-md">*/}
                  {/*  Invited 9/18/21*/}
                  {/*</div>*/}
                </div>
                <div className="text-gray-500 text-sm">Patient</div>
              </div>
            </div>
          </div>
          <div>
            <Button variant="outlined" onClick={onInviteClicked} color="neutral" size="small">
              Invite
            </Button>
          </div>
        </ul>
      </div>
    </div>
  )
}

export default VirtualExamEventDetails
