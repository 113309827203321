/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { logError } from "@medaica/common/services/util"
import Header from "@medaica/common/components/page/page-header"
import { Alert, LoadingButton } from "@mui/lab"
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import useAuth from "hooks/auth"
import { useSnackbar } from "notistack"

const SetPasswordForm = ({ onComplete, firstLogin }: { onComplete: () => void; firstLogin: boolean }): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const handleMouseDownPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  return (
    <div className="max-w-xl">
      <Formik
        initialValues={{
          password: "",
          passwordConfirmation: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .max(128, "The password cannot be more than 128 characters.")
            .min(10, "The password must be at least 10 characters.")
            .required("You must enter a password."),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref("password"), ""], "Passwords must match")
            .required("You must confirm your password."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage(null)
          try {
            await medaicaApiService.healthcareProviders.updateHealthcareProviderPassword(values.password, user.id)
            enqueueSnackbar("Your password has been updated.", {
              variant: "success",
            })
            onComplete()
          } catch (error) {
            const generalErrorMessage = "There was an error setting your password. Please try again later."

            const medaicaErrorMessages = {
              [MedaicaApiErrorType.PASSWORD_SAME_AS_PREVIOUS]:
                "The password you entered is the same as your previous password. Please choose a new password.",
              [MedaicaApiErrorType.PASSWORD_TOO_SHORT]:
                "The password you entered is too short. Please ensure that the password is at least 10 characters.",
              [MedaicaApiErrorType.PASSWORD_TOO_COMMON]:
                "The password you entered is too common. Please enter a less commonly used password.",
              [MedaicaApiErrorType.PASSWORD_CONTAINS_USER_INFORMATION]:
                "The password contains user information. Please change your password.",
              [MedaicaApiErrorType.AUTH0_UNKNOWN_ERROR]:
                "There was an error setting your password. Please contact administrator.",
            }

            setErrorMessage((medaicaErrorMessages[error.type] ?? generalErrorMessage) as string)
            logError(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isValid, errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <Header
              title="Update your password"
              summary={
                firstLogin
                  ? "To ensure the security of your account, please create a new password to replace the temporary" +
                    " password you were given in the email invitation."
                  : "Please create a new password."
              }
            />
            <div className="space-y-8">
              <div>
                {!!errorMessage && (
                  <Alert sx={{ mt: 3 }} severity="error">
                    {errorMessage}
                  </Alert>
                )}
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <FormControl size="small" variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <Field
                        name="password"
                        errors={errors}
                        touched={touched}
                        render={() => {
                          return (
                            <OutlinedInput
                              name="password"
                              label="New Password"
                              autoComplete="off"
                              size="small"
                              type={showPassword ? "text" : "password"}
                              onBlur={handleBlur}
                              error={!!errors.password && !!touched.password}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )
                        }}
                      />
                      <FormHelperText sx={{ color: "error.main" }}>{errors.password}</FormHelperText>
                      <FormHelperText>The password must be at least 10 characters long.</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <FormControl size="small" variant="outlined">
                      <InputLabel htmlFor="passwordConfirmation">Confirm password</InputLabel>
                      <Field
                        name="passwordConfirmation"
                        errors={errors}
                        touched={touched}
                        render={() => {
                          return (
                            <OutlinedInput
                              name="passwordConfirmation"
                              label="Confirm password"
                              autoComplete="off"
                              size="small"
                              type={showPasswordConfirmation ? "text" : "password"}
                              onBlur={handleBlur}
                              error={!!errors.passwordConfirmation && !!touched.passwordConfirmation}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConfirmation}
                                    onMouseDown={handleMouseDownPasswordConfirmation}
                                  >
                                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )
                        }}
                      />
                      {errors.passwordConfirmation && touched.passwordConfirmation && (
                        <FormHelperText sx={{ color: "error.main" }}>{errors.passwordConfirmation}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6 border-t pt-4">
              <LoadingButton loading={isSubmitting} disabled={!isValid} type="submit" variant="contained">
                Continue
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SetPasswordForm
