/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Box, BoxProps } from "@mui/material"
import { useTheme } from "@mui/styles"

const Paper = (props: BoxProps): ReactElement => {
  const { sx, children, ...rest } = props
  const theme = useTheme()

  return (
    <Box
      sx={{
        borderColor: theme.palette.border.main,
        borderWidth: "1px",
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Paper
