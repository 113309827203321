/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useContext, useEffect, useRef, useState } from "react"
import NotesIcon from "@medaica/common/components/icons/notes-icon"
import { logError, moveCaretToEnd } from "@medaica/common/services/util"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import Popup from "@medaica/common/components/popup/popup"
import { bindToggle, usePopupState } from "material-ui-popup-state/hooks"
import { Fade } from "@mui/material"
import { maxCharactersInNote } from "@medaica/common/const"
import { LoadingButton } from "@mui/lab"
import { useSnackbar } from "notistack"

const NotesPanel = (): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const notesRef = useRef<HTMLTextAreaElement>(null)
  const [saved, setSaved] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)
  let saveMessageTimeout: NodeJS.Timeout | undefined

  const handleSaveButtonClicked = async () => {
    setIsSubmitting(true)
    if (notesRef.current) {
      const notesValue = notesRef.current.value
      try {
        await virtualExamStore.updateNotes(notesValue)
        setSaved(true)
        if (saveMessageTimeout) {
          clearTimeout(saveMessageTimeout)
        }
        saveMessageTimeout = setTimeout(() => {
          setSaved(false)
        }, 3000)
      } catch (err) {
        enqueueSnackbar(`Failed to save exam notes.`, { variant: "error" })
        logError(err)
      }
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.focus()
      moveCaretToEnd(notesRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesRef.current])

  return (
    <div>
      <div className="w-96 bg-white p-5 text-left">
        <Fade in={saved} timeout={1200}>
          <div className="absolute bottom-14 left-7 font-semibold text-gray-600 text-sm">Notes saved</div>
        </Fade>
        <h3 className="panel-title">Notes</h3>
        <textarea
          ref={notesRef}
          spellCheck="false"
          className="border-0 w-full h-80 p-0 focus:outline-none focus:ring-0 placeholder-gray-400"
          style={{ resize: "none" }}
          placeholder="Add notes..."
          defaultValue={virtualExamStore.notes}
          maxLength={maxCharactersInNote}
        />
      </div>
      <div>
        <LoadingButton
          loading={isSubmitting}
          onClick={() => void handleSaveButtonClicked()}
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}

const NotesButton = (): ReactElement => {
  const popupState = usePopupState({ variant: "popper", popupId: `infoPopup` })
  return (
    <>
      <Popup placement="top" style={{ zIndex: 200 }} popupState={popupState}>
        <NotesPanel />
      </Popup>
      <button {...bindToggle(popupState)}>
        <div className="video-button">
          <NotesIcon />
        </div>
        <div className="font-sm text-gray-500">Notes</div>
      </button>
    </>
  )
}

export default NotesButton
