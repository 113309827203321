/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { LoadingButton } from "@mui/lab"
import { PatientProfile } from "@medaica/common/types"
import LoadingWrapper from "@medaica/common/components/loading-wrapper"

const SpecifiedPatientView = ({
  patientProfileId,
  onStartExam,
  startingExam,
}: {
  patientProfileId: string
  onStartExam: () => void
  startingExam: boolean
}): ReactElement => {
  const navigate = useNavigate()
  const [patient, setPatient] = useState<PatientProfile>()
  const { medaicaApiService } = useGlobalContext()

  useEffect(() => {
    const fetchData = async () => {
      const patient = await medaicaApiService.patientProfiles.getPatientProfile(patientProfileId)
      setPatient(patient)
    }
    void fetchData()
  }, [medaicaApiService, patientProfileId])

  return (
    <LoadingWrapper loading={!patient}>
      <>
        <h3 className="text-3xl">Ready to start the exam?</h3>
        {patient && (
          <p className="mt-10">
            Click <strong>Start exam</strong> to begin an exam with {patient.fullName}.
          </p>
        )}
        <div className="mt-3 w-full">
          <div className="flex flex-col items-center">
            <div className="flex mt-8 justify-center gap-x-2 flex-row-reverse">
              <LoadingButton loading={startingExam} onClick={onStartExam} variant="contained">
                Start exam
              </LoadingButton>
              <Button onClick={() => navigate(-1)} variant="outlined">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </>
    </LoadingWrapper>
  )
}

export default SpecifiedPatientView
