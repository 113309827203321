/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { Exam } from "@medaica/common/types"
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material"
import { AuscultationPointType } from "@medaica/common/const"
import { logError } from "@medaica/common/services/util"
import { useSnackbar } from "notistack"

type CheckboxState = {
  label: string
  details?: string
  isChecked: boolean
}

type CheckboxGroup = {
  name: string
  numOfElems: number
}

const initialHeartCheckboxes: CheckboxState[] = [
  { label: "Systolic ejection", isChecked: false },
  { label: "Holosystolic", isChecked: false },
  { label: "Decrescendo systolic", isChecked: false },
  { label: "Early diastolic", isChecked: false },
  { label: "Mid-diastolic", isChecked: false },
  { label: "Late diastolic", isChecked: false },
  { label: "Continuous", isChecked: false },
  { label: "Likely no murmur", isChecked: false },
]

const checkboxHeartGroups: CheckboxGroup[] = [
  { name: "Systolic", numOfElems: 3 },
  { name: "Diastolic", numOfElems: 3 },
  { name: "Continuous", numOfElems: 1 },
  { name: "No murmur", numOfElems: 1 },
]

const initialLungCheckboxes: CheckboxState[] = [
  { label: "Stridor", details: "High pitched sound", isChecked: false },
  { label: "Pleural Rub", details: "Creaking, grating, rubbing sound", isChecked: false },
  { label: "Rhonchi", details: "Low pitched, rattling and continuous sounds", isChecked: false },
  { label: "Wheezing", details: "A high pitched, continuous sound", isChecked: false },
  {
    label: "Crackling",
    details: "Discontinuous, intermittent sounds, similar to bubbling, popping, or clicking noises",
    isChecked: false,
  },
  { label: "Likely regular respiratory sounds", isChecked: false },
]

const checkboxLungGroups: CheckboxGroup[] = [
  { name: "Lung sounds", numOfElems: 5 },
  { name: "Regular respiratory sounds", numOfElems: 1 },
]

interface GroupIndexRange {
  start: number
  end: number
}

const calculateGroupIndices = (checkboxGroups: CheckboxGroup[]) => {
  const indices: GroupIndexRange[] = []
  let start = 0
  let end = 0

  for (const group of checkboxGroups) {
    end += group.numOfElems
    indices.push({ start, end })
    start = end
  }

  return indices
}

const EnhancedNotes = ({ exam }: { exam: Exam }): ReactElement => {
  const initEnhancedNotes = exam.enhancedNotes || ([] as string[])
  const init_heart_checkbox_values = initialHeartCheckboxes.map((checkbox) => ({
    ...checkbox,
    isChecked: initEnhancedNotes.includes(checkbox.label),
  }))
  const init_lung_checkbox_values = initialLungCheckboxes.map((checkbox) => ({
    ...checkbox,
    isChecked: initEnhancedNotes.includes(checkbox.label),
  }))
  const groupHeartIndices = calculateGroupIndices(checkboxHeartGroups)
  const groupLungIndices = calculateGroupIndices(checkboxLungGroups)
  const { medaicaApiService } = useGlobalContext()
  const hasChangedRef = useRef<boolean>(false)
  const [checkboxHeartState, setCheckboxHeartState] = useState(init_heart_checkbox_values)
  const [checkboxLungState, setCheckboxLungState] = useState(init_lung_checkbox_values)
  const { enqueueSnackbar } = useSnackbar()

  function handleCheckboxChange(label: string) {
    const updateState = (
      label: string,
      checkboxState: CheckboxState[],
      setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState[]>>
    ) => {
      setCheckboxState((prevCheckboxState) => {
        return prevCheckboxState.map((checkbox) => {
          if (checkbox.label !== label) {
            return checkbox
          }

          const isLastCheckbox = checkbox.label === prevCheckboxState[prevCheckboxState.length - 1].label
          hasChangedRef.current = true
          // if the last checkbox is checked and the state switches from unchecked to checked
          if (isLastCheckbox && !checkbox.isChecked) {
            // Reset all checkboxes
            checkboxState.forEach((checkbox) => {
              checkbox.isChecked = false
            })
          }
          // Toggle the current checkbox's state
          return { ...checkbox, isChecked: !checkbox.isChecked }
        })
      })
    }

    const existsHeart = initialHeartCheckboxes.some((checkbox) => checkbox.label === label)
    const existsLung = initialLungCheckboxes.some((checkbox) => checkbox.label === label)
    if (existsHeart) {
      updateState(label, checkboxHeartState, setCheckboxHeartState)
    } else if (existsLung) {
      updateState(label, checkboxLungState, setCheckboxLungState)
    }
  }

  const saveEnhancedNotes = useCallback(() => {
    // Filter for checkboxes with isChecked true
    // and map to string array of the label names
    const heartNotes = checkboxHeartState.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.label)
    const lungNotes = checkboxLungState.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.label)
    const enhancedNotes = [...heartNotes, ...lungNotes]

    // Only save if there is a change
    if (hasChangedRef.current) {
      void medaicaApiService.exams.updateEnhancedNotes(exam.id, enhancedNotes).catch((error) => {
        logError(error)
        enqueueSnackbar("Failed to save enhanced notes.", { variant: "error" })
      })
    }
  }, [checkboxHeartState, checkboxLungState, enqueueSnackbar, exam.id, medaicaApiService.exams])

  useEffect(() => {
    saveEnhancedNotes()
  }, [checkboxHeartState, checkboxLungState, saveEnhancedNotes])

  const renderCheckboxes = (checkboxState: CheckboxState[], start: number | undefined, end: number | undefined) => {
    const lastCheckboxLabel = checkboxState[checkboxState.length - 1].label
    // Determine if the last checkbox is checked or not
    const isLastCheckboxChecked = checkboxState[checkboxState.length - 1].isChecked

    return Object.values(checkboxState)
      .slice(start, end)
      .map((checkbox, index) => {
        const isLastCheckbox = checkbox.label === lastCheckboxLabel
        // Last checkbox is always enabled
        // Disable all other checkboxes if the last checkbox is checked
        const isDisabled = isLastCheckbox ? false : isLastCheckboxChecked

        // Create the label text with details if available
        let labelText = checkbox.label
        if (checkbox.details) {
          labelText += `:  ${checkbox.details}`
        }

        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkbox.isChecked}
                disabled={isDisabled}
                onChange={() => handleCheckboxChange(checkbox.label)}
                name={checkbox.label}
              />
            }
            label={<Typography sx={{ fontSize: "12px" }}>{labelText}</Typography>}
          />
        )
      })
  }

  const renderHeartNotes = (checkboxGroups: CheckboxGroup[]) => {
    return exam.auscultations.some(
      (auscultation) => auscultation.auscultationPoint.type === AuscultationPointType.heart
    ) ? (
      <div className="mt-4">
        <div className="panel bg-white">
          <div className="panel-header" title="Click on the checkboxes to add the corresponding notes.">
            Murmur
          </div>
          <div className="px-6 py-2">
            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9">
              <div className="sm:col-span-3">
                <div className="header mb-1">{checkboxGroups[0].name}</div>
                <FormGroup>
                  {renderCheckboxes(checkboxHeartState, groupHeartIndices[0].start, groupHeartIndices[0].end)}
                </FormGroup>
              </div>
              <div className="sm:col-span-3">
                <div className="header mb-1">{checkboxGroups[1].name}</div>
                <FormGroup>
                  {renderCheckboxes(checkboxHeartState, groupHeartIndices[1].start, groupHeartIndices[1].end)}
                </FormGroup>
              </div>
              <div className="sm:col-span-3">
                <div className="header mb-1">{checkboxGroups[2].name}</div>
                <FormGroup>
                  {renderCheckboxes(checkboxHeartState, groupHeartIndices[2].start, groupHeartIndices[2].end)}
                </FormGroup>
              </div>
            </div>
            <div className="border-t mt-3 pt-1">
              <FormGroup>
                {renderCheckboxes(checkboxHeartState, groupHeartIndices[3].start, groupHeartIndices[3].end)}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  const renderLungNotes = (checkboxGroups: CheckboxGroup[]) => {
    return exam.auscultations.some(
      (auscultation) => auscultation.auscultationPoint.type === AuscultationPointType.lungs
    ) ? (
      <div className="mt-4">
        <div className="panel bg-white">
          <div className="panel-header" title="Click on the checkboxes to add the corresponding notes.">
            Breath sounds
          </div>
          <div className="px-6 py-2">
            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9">
              <div className="sm:col-span-9">
                <FormGroup>
                  {renderCheckboxes(checkboxLungState, groupLungIndices[0].start, groupLungIndices[0].end)}
                </FormGroup>
              </div>
            </div>
            <div className="border-t mt-3 pt-1">
              <FormGroup>
                {renderCheckboxes(checkboxLungState, groupLungIndices[1].start, groupLungIndices[1].end)}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  return (
    <div>
      {renderHeartNotes(checkboxHeartGroups)}
      {renderLungNotes(checkboxHeartGroups)}
    </div>
  )
}

export default EnhancedNotes
