/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useCallback, useContext, useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import logger from "@medaica/common/services/logging"
import VirtualExamContext from "views/exam/virtual-exam/virtual-exam-context"
import { ExamEntryRequestStatus } from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { logError } from "@medaica/common/services/util"

const ExamEntryRequest = observer((): ReactElement => {
  const { virtualExamStore } = useContext(VirtualExamContext)
  const audioRef = useRef<HTMLAudioElement>(new Audio("/live-exam-ring.mp3"))
  const [patientName, setPatientName] = React.useState<string>()
  const [open, setOpen] = React.useState(true)

  const handleRequest = useCallback(
    (status: "approved" | "denied") => {
      virtualExamStore.setExamEntryRequestStatus(status)
      setOpen(false)
    },
    [virtualExamStore]
  )

  useEffect(() => {
    const currentAudioRef = audioRef.current
    if (virtualExamStore.examEntryRequest?.status === ExamEntryRequestStatus.pending) {
      setPatientName(virtualExamStore.examEntryRequest?.name as string)
      setOpen(true)
      if (currentAudioRef.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA) {
        logger.debug("Playing entry alert sound")
        currentAudioRef.play().catch(logError)
      } else {
        currentAudioRef.addEventListener("canplaythrough", () => {
          logger.debug("Playing entry alert sound")
          currentAudioRef.play().catch(logError)
        })
      }
    } else {
      setOpen(false)
    }
  }, [handleRequest, virtualExamStore.examEntryRequest])

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      fullWidth
      maxWidth="xs"
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pb: 0.5 }}>Entry Request</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText>{patientName} wants to join the exam</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleRequest("denied")
          }}
        >
          Deny entry
        </Button>
        <Button
          variant="text"
          onClick={() => {
            handleRequest("approved")
          }}
          autoFocus={true}
          style={{
            // Add styles to emphasize the button when focused or active
            background: "blue",
            color: "white",
            outline: "1px solid blue", // Add an outline when focused
            boxShadow: "0px 0px 5px blue", // Add a box shadow when active
          }}
        >
          Admit
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ExamEntryRequest
