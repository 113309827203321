/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Formik, Form, Field } from "formik"
import { TextField } from "formik-mui"
import * as Yup from "yup"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { getConfigNumber, logError } from "@medaica/common/services/util"
import Header from "@medaica/common/components/page/page-header"
import { Alert, LoadingButton } from "@mui/lab"
import { Paths } from "const"
import Checkbox from "@medaica/common/components/forms/checkbox"
import { Link } from "react-router-dom"
import { HealthcareProviderInvitation } from "@medaica/common/types"
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import {
  healthcareProviderDisplayNameValidator,
  healthcareProviderFirstNameValidator,
  healthcareProviderLastNameValidator,
  healthcareProviderPasswordValidator,
} from "services/yup"

const CreateForm = ({
  healthcareProviderInvitation,
  onComplete,
}: {
  healthcareProviderInvitation: HealthcareProviderInvitation
  onComplete: () => void
}): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { medaicaApiService } = useGlobalContext()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const handleMouseDownPasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
        firstName: "",
        lastName: "",
        displayName: "",
        termsAndConditions: false,
        useOfData: false,
      }}
      validationSchema={Yup.object({
        firstName: healthcareProviderFirstNameValidator.required("First name is required"),
        lastName: healthcareProviderLastNameValidator.required("Last name is required"),
        displayName: healthcareProviderDisplayNameValidator.required("Display name is required"),
        password: healthcareProviderPasswordValidator.required("Password is required."),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password"), ""], "Passwords must match")
          .required("You must confirm your password."),
        termsAndConditions: Yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
        useOfData: Yup.boolean().oneOf([true], "You must agree to the use of data terms"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setErrorMessage(null)
        try {
          await medaicaApiService.healthcareProviders.createHealthcareProvider(
            healthcareProviderInvitation.id,
            values.password,
            values.firstName,
            values.lastName,
            values.displayName,
            getConfigNumber("TC_VERSION")
          )
          onComplete()
        } catch (error) {
          const generalErrorMessage = "There was an error submitting your information. Please try again later."

          const medaicaErrorMessages = {
            [MedaicaApiErrorType.INVALID_HEALTHCARE_PROVIDER_INVITATION]:
              "This invitation is no longer valid. If you do not already have a Medaica account, please request a new" +
              " invitation.",
            [MedaicaApiErrorType.PASSWORD_TOO_SHORT]: "The password is too short. Minimum length is 10 characters.",
            [MedaicaApiErrorType.PASSWORD_TOO_COMMON]: "The password is too common. Please use different characters.",
            [MedaicaApiErrorType.PASSWORD_CONTAINS_USER_INFORMATION]:
              "The password contains user information. Please change your password.",
            [MedaicaApiErrorType.USER_ALREADY_EXISTS]: "The user already exists. Try to login or reset your password.",
            [MedaicaApiErrorType.AUTH0_UNKNOWN_ERROR]:
              "There was an error submitting your information. Please contact administrator.",
          }

          setErrorMessage((medaicaErrorMessages[error.type] ?? generalErrorMessage) as string)
          logError(error)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
        <Form>
          <Header
            title="Create account"
            summary="Complete the form below to create your account for the Medaica Healthcare Provider Portal."
          />
          <div className="space-y-8">
            <div>
              {!!errorMessage && (
                <Alert sx={{ mt: 3 }} severity="error">
                  {errorMessage}
                </Alert>
              )}
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Field
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      render={() => {
                        return (
                          <OutlinedInput
                            name="password"
                            size="small"
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            onBlur={handleBlur}
                            error={!!errors.password && !!touched.password}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  tabIndex={-1}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )
                      }}
                    />
                    {errors.password && touched.password && (
                      <FormHelperText sx={{ color: "error.main" }}>{errors.password}</FormHelperText>
                    )}
                    <FormHelperText>The password must be at least 10 characters long.</FormHelperText>
                  </FormControl>
                </div>
                <div className="sm:col-span-3">
                  <InputLabel htmlFor="passwordConfirmation">Confirm password</InputLabel>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <Field
                      name="passwordConfirmation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      render={() => {
                        return (
                          <OutlinedInput
                            name="passwordConfirmation"
                            size="small"
                            autoComplete="off"
                            type={showPasswordConfirmation ? "text" : "password"}
                            onBlur={handleBlur}
                            error={!!errors.passwordConfirmation && !!touched.passwordConfirmation}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordConfirmation}
                                  onMouseDown={handleMouseDownPasswordConfirmation}
                                  tabIndex={-1}
                                >
                                  {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )
                      }}
                    />
                    {errors.passwordConfirmation && touched.passwordConfirmation && (
                      <FormHelperText sx={{ color: "error.main" }}>{errors.passwordConfirmation}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="sm:col-span-3">
                  <InputLabel htmlFor="firstName">First name</InputLabel>
                  <Field
                    sx={{ width: "100%" }}
                    component={TextField}
                    size="small"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="given-name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="sm:col-span-3">
                  <InputLabel htmlFor="lastName">Last name</InputLabel>
                  <Field
                    sx={{ width: "100%" }}
                    component={TextField}
                    size="small"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="family-name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="sm:col-span-3">
                  <InputLabel htmlFor="displayName">Display name</InputLabel>
                  <FormControl>
                    <Field
                      sx={{ width: "100%" }}
                      component={TextField}
                      name="displayName"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                    <FormHelperText>This is how you will be identified to other users, e.g. Dr. Smith.</FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <fieldset>
                <div className="mt-4 space-y-4">
                  <Checkbox
                    name="termsAndConditions"
                    label="Terms and Conditions"
                    description={
                      <>
                        I agree with the{" "}
                        <Link to={Paths.terms} className="link" target="_blank" rel="noopener noreferrer">
                          terms and conditions.
                        </Link>
                      </>
                    }
                    touched={touched}
                    errors={errors}
                  />

                  <Checkbox
                    name="useOfData"
                    label="Permissions and Use of Data"
                    description={
                      <>
                        I agree to comply with{" "}
                        <Link to={Paths.privacy} className="link" target="_blank" rel="noopener noreferrer">
                          specific requirements.
                        </Link>
                      </>
                    }
                    touched={touched}
                    errors={errors}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className="flex justify-end mt-6 border-t pt-4">
            <LoadingButton loading={isSubmitting} type="submit" variant="contained">
              Continue
            </LoadingButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateForm
