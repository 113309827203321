/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React from "react"

import { protect as baseProtect, withLayout } from "@medaica/common/services/routing"
import { footerNavigation, Paths } from "const"

// Layouts
import MainLayout from "layouts/main-layout"

// Misc
/* https://medaica.atlassian.net/browse/MD-339
import Dashboard from "views/dashboard"
import HomeIcon from "@medaica/common/components/icons/home-icon"
 */

import SupportView from "@medaica/common/views/misc/support"
import PrivacyView from "@medaica/common/views/misc/privacy"
import ContactView from "@medaica/common/views/misc/contact"
import TermsView from "@medaica/common/views/misc/terms"
import HipaaView from "@medaica/common/views/misc/hipaa"
import PostAuthPipeline from "views/auth/post-auth-pipeline"
import CreateAccount from "views/auth/create-account"

// Exams
import LiveExamCalendar from "views/exam/virtual-exam-calendar"
import ExamView from "views/exam/exam-details"
import LiveExam from "views/exam/virtual-exam"

// Patients
import PatientListView from "views/patient/patient-list"
import PatientView from "views/patient/patient-overview"

// Organization
import HealthcareProviderListView from "./views/organization/healthcare-provider-list"
import ExamListView from "views/organization/exam-list"
import OrganizationPatientListView from "views/organization/patient-list"

// Testing
import { Navigate, Outlet, RouteObject } from "react-router-dom"
import NotFoundView from "@medaica/common/views/errors/not-found"
import LoggedOutView from "@medaica/common/views/auth/logged-out"
import LogOutView from "@medaica/common/views/auth/log-out"

//import SelfExamRequestListView from "views/exam/self-exam-request-list"
//import SelfExamRequestView from "views/exam/self-exam-request-details"
import Devices from "views/devices"
import CompatibilityTestView from "@medaica/common/views/compatibility-test"
import MinimalLayout from "@medaica/common/layouts/minimal-layout"
import useAuth from "hooks/auth"
import AuthCallback from "views/auth/auth-callback"
import Design from "views/design"
import CouncilExamListView from "./views/exam/council"

// Profile and Settings
import ProfileAndSettings from "views/profile-and-settings"
import PersonalInformation from "views/profile-and-settings/personal-information"
import PersonalInformationEdit from "views/profile-and-settings/personal-information/edit"
import Password from "views/profile-and-settings/password"
import EmailAddress from "views/profile-and-settings/email-address"

const protect = (...children: RouteObject[]) => baseProtect(useAuth, ...children)

const mainLayout = <MainLayout />

const examRoutes = [
  withLayout(
    mainLayout,
    "/",
    {
      path: `${Paths.exams}/:examId`,
      element: <ExamView />,
    },
    /* https://medaica.atlassian.net/browse/MD-339
      {
        path: Paths.selfExamRequests,
        element: <SelfExamRequestListView />,
      },
      {
        path: `${Paths.selfExamRequests}/:selfExamRequestId`,
        element: <SelfExamRequestView />,
      },
    */
    {
      path: `${Paths.liveExamCalendar}`,
      element: <LiveExamCalendar />,
    }
  ),
  {
    path: Paths.liveExam,
    element: <LiveExam />,
  },
  {
    path: `${Paths.liveExam}/:liveExamId`,
    element: <LiveExam />,
  },
]

const patientRoutes = [
  withLayout(
    mainLayout,
    "/",
    {
      path: Paths.patients,
      element: <PatientListView />,
    },
    {
      path: `${Paths.patients}/:patientProfileId`,
      element: <PatientView />,
    }
  ),
]

const councilRoutes = [
  withLayout(mainLayout, "/", {
    path: Paths.councilOfDoctors,
    element: <CouncilExamListView />,
  }),
]

const organizationRoutes = [
  withLayout(
    mainLayout,
    "/",
    {
      path: Paths.organizationHealthcareProviders,
      element: <HealthcareProviderListView />,
    },
    {
      path: Paths.organizationPatients,
      element: <OrganizationPatientListView />,
    },
    {
      path: Paths.organizationExams,
      element: <ExamListView />,
    }
  ),
]

const profileAndSettingsRoutes = [
  withLayout(mainLayout, "/", {
    path: Paths.profileAndSettings,
    element: <ProfileAndSettings />,
    children: [
      {
        index: true,
        element: <PersonalInformation />,
      },
      {
        path: Paths.profileAndSettingsPersonalInfo,
        element: <PersonalInformation />,
      },
      {
        path: Paths.profileAndSettingsPersonalInfoEdit,
        element: <PersonalInformationEdit />,
      },
      {
        path: Paths.profileAndSettingsEmailAddress,
        element: <EmailAddress />,
      },
      {
        path: Paths.profileAndSettingsPassword,
        element: <Password />,
      },
    ],
  }),
]

const protectedRoutes = [
  ...protect(
    withLayout(
      mainLayout,
      "/",
      {
        path: Paths.home,
        element: <PatientListView />,
      },
      {
        path: Paths.profileAndSettings,
        element: <ProfileAndSettings />,
        children: [
          {
            index: true,
            element: <PersonalInformation />,
          },
          {
            path: Paths.profileAndSettingsPersonalInfo,
            element: <PersonalInformation />,
          },
          {
            path: Paths.profileAndSettingsPersonalInfoEdit,
            element: <PersonalInformationEdit />,
          },
          {
            path: Paths.profileAndSettingsEmailAddress,
            element: <EmailAddress />,
          },
          {
            path: Paths.profileAndSettingsPassword,
            element: <Password />,
          },
        ],
      }
    ),
    {
      path: "/home",
      element: <Navigate to={Paths.home} replace={true} />,
    },
    {
      path: "/sign-in",
      element: <Navigate to={Paths.home} replace={true} />,
    },
    ...examRoutes,
    ...patientRoutes,
    ...councilRoutes,
    ...profileAndSettingsRoutes,
    ...organizationRoutes,
    withLayout(
      <MinimalLayout useHomeLink={false}>
        <Outlet />
      </MinimalLayout>,
      "/",
      {
        path: Paths.postAuthPipeline,
        element: <PostAuthPipeline />,
      }
    )
  ),
]

const unprotectedRoutes = [
  withLayout(
    <MinimalLayout footerNavigation={footerNavigation}>
      <Outlet />
    </MinimalLayout>,
    "/",
    {
      path: Paths.loggedOut,
      element: <LoggedOutView useAuth={useAuth} />,
    },
    {
      path: Paths.support,
      element: <SupportView />,
    },
    {
      path: Paths.privacy,
      element: <PrivacyView />,
    },
    {
      path: Paths.contact,
      element: <ContactView />,
    },
    {
      path: Paths.terms,
      element: <TermsView />,
    },
    {
      path: Paths.hipaa,
      element: <HipaaView />,
    },
    {
      path: Paths.createAccount,
      element: <CreateAccount />,
    }
  ),
  {
    path: Paths.authCallback,
    element: <AuthCallback />,
  },
  {
    path: Paths.logOut,
    element: <LogOutView useAuth={useAuth} />,
  },
  {
    path: Paths.liveExamCompatibilityTest,
    element: <CompatibilityTestView />,
  },
  {
    path: Paths.emsLog,
    element: <Devices />,
  },
  withLayout(mainLayout, "/", {
    path: "_design",
    element: <Design />,
  }),
]

const routes = [
  ...protectedRoutes,
  ...unprotectedRoutes,
  {
    path: "*",
    element: (
      <MinimalLayout footerNavigation={footerNavigation}>
        <NotFoundView />
      </MinimalLayout>
    ),
  },
]

export default routes
