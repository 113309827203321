/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Box, BoxProps } from "@mui/material"
import { useTheme } from "@mui/styles"

const DetailsName = (props: BoxProps): ReactElement => {
  const theme = useTheme()
  const { sx, children, ...rest } = props
  return (
    <Box
      component="dt"
      sx={{ fontSize: "12px", fontWeight: "500", color: theme.palette.dark[2], marginBottom: 0.5, ...sx }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default DetailsName
