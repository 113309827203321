/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import { v4 as uuid4 } from "uuid"
import { Auscultation, AuscultationPoint } from "@medaica/common/types"
import { action, autorun, makeObservable, observable, runInAction } from "mobx"
import VirtualExamStore from "views/exam/virtual-exam/stores/virtual-exam-store"
import MedaicaApiService from "@medaica/common/services/medaica-api-service"
import { logError } from "@medaica/common/services/util"
import FirebaseVirtualExamService from "@medaica/common/views/exam/virtual-exam/exam-room/services/firebase-virtual-exam-service"
import logger from "@medaica/common/services/logging"
import AVStore from "@medaica/common/views/exam/virtual-exam/stores/av-store"
import AuscultationRequestDataStore from "views/exam/virtual-exam/stores/auscultation-request-data-store"
import getBlobDuration from "get-blob-duration"

class AuscultationRequest {
  private medaicaApiService: MedaicaApiService
  private virtualExamStore: VirtualExamStore
  private readonly firebaseVirtualExamService: FirebaseVirtualExamService
  private fakeProgressTimer: NodeJS.Timeout
  private dataStore: AuscultationRequestDataStore

  status: "pending" | "complete" | "processing" | "cancelled" = "pending"
  recording: Blob | null = null
  auscultation: Auscultation
  id = uuid4()
  auscultationPoint: AuscultationPoint
  error: string | null
  /**
   * Once an auscultation is created on the patient client, the file will eventually get to the HCP's client. The
   *  `processingProgress` variable is for tracking the file's journey as it makes its way to the HCP's client.
   *  1. The first 30% of the progress bar reflects the progress of the upload from patient to server.
   *   that progress.
   *  2. The next 50% of the progress bar reflects the progress of the server processing and uploading the file to
   *   S3. We fake this with a simple timer.
   *  3. The final 20% of the progress bar reflects the progress of the download from server to HCP.
   */
  processingProgress = 0

  constructor(
    virtualExamStore: VirtualExamStore,
    firebaseVirtualExamService: FirebaseVirtualExamService,
    medaicaApiService: MedaicaApiService,
    avStore: AVStore
  ) {
    makeObservable(this, {
      error: observable,
      stop: action,
      processingProgress: observable,
      recording: observable,
      auscultation: observable,
      status: observable,
    })
    this.firebaseVirtualExamService = firebaseVirtualExamService
    this.medaicaApiService = medaicaApiService
    this.virtualExamStore = virtualExamStore
    this.dataStore = new AuscultationRequestDataStore(this.firebaseVirtualExamService)

    const processAuscultation = async (auscultationId: string) => {
      await this.downloadRecording(auscultationId)
      if (this.auscultation.recording.data) {
        const duration = await getBlobDuration(this.auscultation.recording.data)
        if (duration <= 0) {
          throw new Error("Auscultation recording cannot be processed")
        }
      }
    }

    const handleAuscultationError = async (error: Error) => {
      this.error = error.message
      this.status = "cancelled"
      logError(this.error)

      // If the auscultation recording cannot be used, we delete it.
      try {
        if (this.dataStore.auscultationId) {
          await this.virtualExamStore.deleteAuscultation(this.dataStore.auscultationId)
        }
      } catch (e) {
        logError(e)
      }
    }

    autorun(async () => {
      if (!avStore.remoteParticipant) {
        this.error = "PatientDisconnected"
        this.status = "cancelled"
        return
      }
      if (this.dataStore.error) {
        this.error = this.dataStore.error
        this.status = "cancelled"
        logError(this.dataStore.error)
        return
      }
      const uploadProgress = this.dataStore.uploadProgress
      if (uploadProgress < 100) {
        this.processingProgress = uploadProgress * 0.3
      } else {
        this.doFakeProgress()
      }
      if (this.dataStore.auscultationId !== null) {
        try {
          await processAuscultation(this.dataStore.auscultationId)
        } catch (error) {
          await handleAuscultationError(error as Error)
          return
        }
        this.status = "complete"
      }
    })
  }

  private doFakeProgress() {
    this.fakeProgressTimer = setInterval(() => {
      if (this.processingProgress <= 80) {
        runInAction(() => {
          this.processingProgress += 1
        })
      } else {
        clearInterval(this.fakeProgressTimer)
      }
    }, 300)
  }

  private async downloadRecording(auscultationId: string): Promise<void> {
    // In case the fake progress timer hasn't stopped, we stop it.
    clearInterval(this.fakeProgressTimer)
    const auscultation = await this.medaicaApiService.auscultations.getAuscultation(auscultationId)
    auscultation.recording.data =
      await this.medaicaApiService.auscultations.getAuscultationRecordingNormalizedDataSilenced(
        auscultation.id,
        (progress: number) => {
          runInAction(() => {
            if (progress > this.processingProgress) {
              this.processingProgress = progress
            }
          })
        }
      )

    this.processingProgress = 100
    this.auscultation = auscultation
  }

  start(): void {
    logger.debug("Requesting auscultation start")
    this.dataStore.start(this.auscultationPoint.label)
  }

  cancel(): void {
    this.dataStore.setRecordingCancelRequest()
    this.status = "cancelled"
  }

  stop(): void {
    this.dataStore.setRecordingStopRequest()
    runInAction(() => {
      this.status = "processing"
    })
  }
}

export default AuscultationRequest
