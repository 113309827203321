/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { FormControlLabel, FormGroup, Radio, Switch, Typography } from "@mui/material"
import { WAFFilterType } from "@medaica/common/components/audio-player/web-audio-filters"

const wafPresets = [
  { label: "Raw", file: WAFFilterType.raw },
  { label: "Wide", file: WAFFilterType.wideband },
  { label: "Low", file: WAFFilterType.lowband },
  { label: "High", file: WAFFilterType.highband },
]

type Presets = { label: string; file: string }[]

type PresetSetter = {
  apply: (preset: string | null) => void
  setDefaultFilterValues: () => void
  toggleAudioEnhancement: (enabled: boolean) => void
}

const Filters = ({
  filterContext,
  disableSetting,
  presets = wafPresets,
  onFiltersChangeEvent,
}: {
  filterContext: PresetSetter
  disableSetting: boolean
  presets?: Presets
  onFiltersChangeEvent: (data: string | null) => void
}): ReactElement => {
  const [preset, setPreset] = useState(presets[0].file)

  useEffect(() => {
    if (filterContext) {
      // Filter type is changed, reset the filter values
      filterContext.setDefaultFilterValues()
      const filterType = preset === "" ? null : preset
      filterContext.apply(filterType)
      // emit event and notify the audio player that
      // the filter type is changed
      onFiltersChangeEvent(filterType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterContext, preset])

  return (
    <>
      {presets.map((filter) => (
        <FormControlLabel
          value={filter.file}
          disabled={disableSetting}
          key={filter.file}
          control={<Radio size="small" checked={filter.file === preset} disabled={disableSetting} />}
          label={<Typography sx={{ fontSize: "13px" }}>{filter.label}</Typography>}
          onClick={() => (!disableSetting ? setPreset(filter.file) : null)}
          sx={{
            marginLeft: "0px",
            "& .MuiRadio-root": {
              padding: "1px 4px 1px 0px",
            },
          }}
        />
      ))}
    </>
  )
}

const MedaicaFilters = ({
  filterContext,
  onFiltersChangeEvent,
  disableSetting,
}: {
  filterContext: PresetSetter
  onFiltersChangeEvent: (data: string | null) => void
  disableSetting: boolean
}): ReactElement => {
  const [enhanceAudioEnabled, setEnhanceAudioEnabled] = useState<boolean>(false)

  const handleEnhanceAudioClicked = () => {
    setEnhanceAudioEnabled((prevEnhanceAudioEnabled) => !prevEnhanceAudioEnabled)
  }

  useEffect(() => {
    if (filterContext) {
      filterContext.toggleAudioEnhancement(enhanceAudioEnabled)
    }
  }, [enhanceAudioEnabled, filterContext])

  return (
    <>
      <Filters
        presets={wafPresets}
        filterContext={filterContext}
        onFiltersChangeEvent={onFiltersChangeEvent}
        disableSetting={disableSetting}
      />
      <FormControlLabel
        style={{ display: "none" }}
        disabled={disableSetting}
        control={<Switch />}
        label="Enhanced"
        onClick={handleEnhanceAudioClicked}
      />
      <FormGroup></FormGroup>
    </>
  )
}

export default Filters
export { MedaicaFilters }
