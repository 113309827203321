/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

const HospitalIcon = ({
  height = 24,
  width = 24,
  deemphasize = false,
}: {
  height?: number
  width?: number
  deemphasize?: boolean
}): ReactElement => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {deemphasize ? (
        <>
          <path
            d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
            stroke="#2823EE"
            strokeWidth="1.5"
          />
          <path d="M16 8V16" stroke="#2823EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 12L8 12" stroke="#2823EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 8V16" stroke="#2823EE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </>
      ) : (
        <>
          <path
            opacity="0.4"
            d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
            fill="#1A1990"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7.25C8.41421 7.25 8.75 7.58579 8.75 8V11.25L15.25 11.25V8C15.25 7.58579 15.5858 7.25 16 7.25C16.4142 7.25 16.75 7.58579 16.75 8V16C16.75 16.4142 16.4142 16.75 16 16.75C15.5858 16.75 15.25 16.4142 15.25 16V12.75H8.75V16C8.75 16.4142 8.41421 16.75 8 16.75C7.58579 16.75 7.25 16.4142 7.25 16V8C7.25 7.58579 7.58579 7.25 8 7.25Z"
            fill="#1A1990"
          />
        </>
      )}
    </svg>
  )
}

export default HospitalIcon
