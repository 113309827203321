/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { DialogContent, DialogTitle } from "@mui/material"
import DialogCloseButton from "@medaica/common/components/dialog/dialog-close-button"
import { VirtualExam } from "@medaica/common/types"
import VirtualExamEventDetails from "views/exam/components/virtual-exam-event-details"

const CompleteStep = ({ virtualExam, onClose }: { virtualExam: VirtualExam; onClose: () => void }): ReactElement => {
  return (
    <>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Your exam is scheduled</DialogTitle>
      <DialogContent>
        <VirtualExamEventDetails virtualExam={virtualExam} />
      </DialogContent>
    </>
  )
}

export default CompleteStep
