/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useEffect, useState } from "react"
import { Alert, FormControl, InputLabel } from "@mui/material"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import ContentContainer from "views/profile-and-settings/content-container"
import FormContainer from "@medaica/common/components/ui/form/form-container"
import Button from "@medaica/common/components/ui/button"
import { useOutletContext } from "react-router-dom"
import { ProfileAndSettingsContextType } from "views/profile-and-settings/index"
import ButtonRow from "@medaica/common/components/ui/form/form-actions"
import { TextField } from "formik-mui"
import { EmailAddressValidator } from "services/yup"
import useAuth from "hooks/auth"
import useGlobalContext from "@medaica/common/hooks/global-context"
import { logError } from "@medaica/common/services/util"
import Loading from "@medaica/common/components/loading"
import SystemErrorAlert from "@medaica/common/components/system-error-alert"
import { MedaicaApiErrorType } from "@medaica/common/services/medaica-api-service"
import DetailsItem from "@medaica/common/components/ui/details/details-item"
import DetailsName from "@medaica/common/components/ui/details/details-name"
import DetailsValue from "@medaica/common/components/ui/details/details-value"

const EmailAddress = (): ReactElement => {
  const { setActions, setCurrentPage } = useOutletContext<ProfileAndSettingsContextType>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const { user } = useAuth()
  const { medaicaApiService } = useGlobalContext()
  const [currentEmailAddress, setCurrentEmailAddress] = useState<string>()
  const [systemError, setSystemError] = useState<boolean>(false)

  useEffect(() => {
    void (async () => {
      setActions(null)
      setCurrentPage("emailAddress")
      try {
        const emailAddress = await medaicaApiService.healthcareProviders.getHealthcareProviderEmailAddress(user.id)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCurrentEmailAddress(emailAddress)
      } catch (error) {
        setSystemError(true)
        logError(error)
      }
    })()
  }, [medaicaApiService.healthcareProviders, setActions, setCurrentPage, user.id])

  if (systemError) {
    return <SystemErrorAlert />
  }

  if (!currentEmailAddress) {
    return <Loading />
  }

  return (
    <ContentContainer>
      <Formik
        initialValues={{
          emailAddress: "",
          emailAddressConfirmation: "",
        }}
        validationSchema={Yup.object({
          emailAddress: EmailAddressValidator,
          emailAddressConfirmation: Yup.string()
            .oneOf([Yup.ref("emailAddress"), ""], "Email addresses must match.")
            .required("You must confirm your email address."),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            setErrorMessage(null)
            setIsSuccessful(false)
            await medaicaApiService.healthcareProviders.updateHealthcareProviderEmailAddress(
              values.emailAddress,
              user.id
            )
            setCurrentEmailAddress(values.emailAddress)
            setIsSuccessful(true)
          } catch (error) {
            const generalErrorMessage = "There was an error updating your email address. Please try again later."

            const medaicaErrorMessages = {
              [MedaicaApiErrorType.USER_ALREADY_EXISTS]: "A user with that email address already exists.",
            }

            setErrorMessage((medaicaErrorMessages[error.type] ?? generalErrorMessage) as string)
            logError(error)
          } finally {
            resetForm()
            setSubmitting(false)
          }
        }}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched }) => (
          <Form>
            <dl>
              <FormContainer sx={{ width: "380px" }}>
                {errorMessage && (
                  <Alert severity="error" aria-label="submisson error">
                    {errorMessage}
                  </Alert>
                )}
                {isSuccessful && (
                  <Alert severity="success" aria-label="submisson error">
                    You successfully updated your email address
                  </Alert>
                )}
                <DetailsItem>
                  <DetailsName id="organization">Current email address</DetailsName>
                  <DetailsValue aria-labelledby="organization">{currentEmailAddress}</DetailsValue>
                </DetailsItem>
                <div>
                  <InputLabel htmlFor="emailAddress">New email address</InputLabel>
                  <FormControl variant="outlined">
                    <Field
                      name="emailAddress"
                      component={TextField}
                      size="small"
                      id="emailAddress"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="email"
                      errors={errors}
                      touched={touched}
                    />
                  </FormControl>
                </div>
                <div>
                  <InputLabel htmlFor="emailAddressConfirmation">Confirm email address</InputLabel>
                  <FormControl variant="outlined">
                    <Field
                      name="emailAddressConfirmation"
                      component={TextField}
                      size="small"
                      id="emailAddressConfirmation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      autoComplete="email"
                      errors={errors}
                      touched={touched}
                    />
                  </FormControl>
                </div>
                <ButtonRow>
                  <Button loading={isSubmitting} size="small" type="submit">
                    Submit
                  </Button>
                </ButtonRow>
              </FormContainer>
            </dl>
          </Form>
        )}
      </Formik>
    </ContentContainer>
  )
}

export default EmailAddress
