/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement } from "react"
import { Chip } from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"

const ExamStatusChip = (props: { isReviewed: boolean; chipEls: HTMLDivElement[] }): ReactElement => {
  const { isReviewed, chipEls } = props
  return !isReviewed ? (
    <Chip
      ref={(el) => {
        if (el) {
          chipEls.push(el)
        }
      }}
      label="Unreviewed"
      sx={(theme) => ({
        border: "3px solid #F0F0FB",
        backgroundColor: "#fff",
        "& .MuiChip-label": {
          color: theme.palette.text.secondary,
        },
      })}
    />
  ) : (
    <Chip
      ref={(el) => {
        if (el) {
          chipEls.push(el)
        }
      }}
      icon={<TaskAltIcon style={{ color: "#fff" }} />}
      label="Reviewed"
      sx={{
        border: "3px solid #68DFBE",
        backgroundColor: "#68DFBE",
        "& .MuiChip-label": {
          color: "#198466",
          fontWeight: "600",
        },
      }}
    />
  )
}

export default ExamStatusChip
