/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* eslint-disable max-len */
import React, { ReactElement } from "react"

const CouncilOfDoctorsIcon = ({ height = 32, width = 32 }: { height?: number; width?: number }): ReactElement => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={`${width}`} height={`${width}`} rx="8" fill="#F0F0FB" />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.53846C14.6923 7.53846 13.4559 7.83458 12.3523 8.3627C11.9691 8.54609 11.5098 8.3841 11.3264 8.00089C11.143 7.61767 11.305 7.15835 11.6882 6.97496C12.9947 6.34974 14.4576 6 16 6C17.5424 6 19.0053 6.34974 20.3118 6.97496C20.695 7.15835 20.857 7.61768 20.6736 8.00089C20.4902 8.3841 20.0309 8.54609 19.6477 8.3627C18.5441 7.83458 17.3077 7.53846 16 7.53846ZM8.00089 11.3264C8.3841 11.5098 8.54609 11.9691 8.3627 12.3523C7.83458 13.4559 7.53846 14.6923 7.53846 16C7.53846 17.3077 7.83458 18.5441 8.3627 19.6477C8.54609 20.0309 8.3841 20.4902 8.00089 20.6736C7.61768 20.857 7.15835 20.695 6.97496 20.3118C6.34974 19.0053 6 17.5424 6 16C6 14.4576 6.34974 12.9947 6.97496 11.6882C7.15835 11.305 7.61768 11.143 8.00089 11.3264ZM23.9991 11.3264C24.3823 11.143 24.8416 11.305 25.025 11.6882C25.6503 12.9947 26 14.4576 26 16C26 17.5424 25.6503 19.0053 25.025 20.3118C24.8416 20.695 24.3823 20.857 23.9991 20.6736C23.6159 20.4902 23.4539 20.0309 23.6373 19.6477C24.1654 18.5441 24.4615 17.3077 24.4615 16C24.4615 14.6923 24.1654 13.4559 23.6373 12.3523C23.4539 11.9691 23.6159 11.5098 23.9991 11.3264ZM11.3264 23.9991C11.5098 23.6159 11.9691 23.4539 12.3523 23.6373C13.4559 24.1654 14.6923 24.4615 16 24.4615C17.3077 24.4615 18.5441 24.1654 19.6477 23.6373C20.0309 23.4539 20.4902 23.6159 20.6736 23.9991C20.857 24.3823 20.695 24.8416 20.3118 25.025C19.0053 25.6503 17.5424 26 16 26C14.4576 26 12.9947 25.6503 11.6882 25.025C11.305 24.8416 11.143 24.3823 11.3264 23.9991Z"
        fill="#1A1990"
      />
      <path
        d="M12.6667 9.33333C12.6667 11.1743 11.1743 12.6667 9.33333 12.6667C8.41197 12.6667 7.57792 12.2929 6.97457 11.6886C6.37238 11.0855 6 10.2529 6 9.33333C6 7.49238 7.49238 6 9.33333 6C10.2529 6 11.0855 6.37238 11.6886 6.97457C12.2929 7.57792 12.6667 8.41197 12.6667 9.33333Z"
        fill="#1A1990"
      />
      <path
        d="M26 9.33333C26 10.2529 25.6276 11.0855 25.0254 11.6886C24.4221 12.2929 23.588 12.6667 22.6667 12.6667C20.8257 12.6667 19.3333 11.1743 19.3333 9.33333C19.3333 8.41197 19.7071 7.57792 20.3114 6.97457C20.9145 6.37238 21.7471 6 22.6667 6C24.5076 6 26 7.49238 26 9.33333Z"
        fill="#1A1990"
      />
      <path
        d="M12.6667 22.6667C12.6667 23.588 12.2929 24.4221 11.6886 25.0254C11.0855 25.6276 10.2529 26 9.33333 26C7.49238 26 6 24.5076 6 22.6667C6 21.7471 6.37238 20.9145 6.97457 20.3114C7.57792 19.7071 8.41197 19.3333 9.33333 19.3333C11.1743 19.3333 12.6667 20.8257 12.6667 22.6667Z"
        fill="#1A1990"
      />
      <path
        d="M26 22.6667C26 24.5076 24.5076 26 22.6667 26C21.7471 26 20.9145 25.6276 20.3114 25.0254C19.7071 24.4221 19.3333 23.588 19.3333 22.6667C19.3333 20.8257 20.8257 19.3333 22.6667 19.3333C23.588 19.3333 24.4221 19.7071 25.0254 20.3114C25.6276 20.9145 26 21.7471 26 22.6667Z"
        fill="#1A1990"
      />
    </svg>
  )
}

export default CouncilOfDoctorsIcon
