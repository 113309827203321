/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Collapse,
  generateUtilityClasses,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material"
import useGlobalContext from "@medaica/common/hooks/global-context"

type MenuIcon = ({ deemphasize }: { deemphasize?: boolean }) => ReactElement

type MenuItemChildData = { title: string; path: string }

type MenuItemData = {
  icon: MenuIcon
  title: string
  path?: string
  section: string
  children?: MenuItemChildData[]
}

const menuItemClasses = generateUtilityClasses("menuItem", ["root", "icon", "button", "title"])

const MenuItem = ({ menuItemData }: { menuItemData: MenuItemData }): ReactElement => {
  const { currentPage } = useGlobalContext()
  const isSection = currentPage.section === menuItemData.section
  const [hover, setHover] = useState(false)
  const [expanded, setExpanded] = useState(isSection)
  return (
    <ListItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      disablePadding
      sx={{
        py: 0.25,
        flexDirection: "column",
        display: "block",
        alignItems: "start",
        borderLeft: "3px solid transparent",
        ...(isSection
          ? {
              borderLeftColor: "secondary.main",
              [`.${menuItemClasses.icon}`]: {
                backgroundColor: "#F0F0FB",
              },
              [`.${menuItemClasses.title}`]: {
                pl: "0px",
                fontSize: "16px",
                color: "text.primary",
                fontWeight: 500,
              },
            }
          : {
              [`.${menuItemClasses.icon}`]: {
                opacity: 0.22,
              },
              [`.${menuItemClasses.title}`]: {
                pl: "0px",
                fontSize: "16px",
                color: "text.secondary",
                fontWeight: 500,
              },
            }),
        "&:hover": {
          borderLeftColor: "secondary.main",
          [`.${menuItemClasses.button}`]: {
            backgroundColor: "transparent",
          },
          [`.${menuItemClasses.icon}`]: {
            backgroundColor: "#F0F0FB",
            opacity: 1,
          },
          [`.${menuItemClasses.title}`]: {
            color: "text.primary",
            fontWeight: 500,
          },
        },
      }}
    >
      <ListItemButton
        className={menuItemClasses.button}
        disableRipple
        sx={{
          height: "42px",
          py: 0.5,
          pl: 2.5,
        }}
        {...(menuItemData.children && { onClick: () => setExpanded(!expanded) })}
      >
        <ListItemIcon
          className={menuItemClasses.icon}
          sx={{
            mr: 1,
            borderRadius: 1,
            height: "32px",
            width: "32px",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "auto",
          }}
        >
          <menuItemData.icon deemphasize={!isSection && !hover} />
        </ListItemIcon>
        {menuItemData.children || !menuItemData.path ? (
          <Typography className={menuItemClasses.title}>{menuItemData.title}</Typography>
        ) : (
          <Link className={menuItemClasses.title} component={RouterLink} underline="none" to={menuItemData.path}>
            {menuItemData.title}
          </Link>
        )}
      </ListItemButton>
      {menuItemData.children && (
        <Collapse in={expanded}>
          <List
            disablePadding
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              ml: 8,
              mb: 1,
              borderLeft: "1px solid",
              borderColor: "border.main",
            }}
            aria-expanded={expanded ? "true" : undefined}
          >
            {menuItemData.children?.map((item) => {
              return (
                <ListItem key={item.path} sx={{ py: "1px" }}>
                  <Link
                    sx={{
                      fontSize: "14px",
                      ...(isSection && currentPage.path === item.path
                        ? {
                            color: "text.primary",
                            fontWeight: 500,
                          }
                        : {
                            color: "text.secondary",
                            "&:hover": {
                              color: "text.primary",
                            },
                          }),
                    }}
                    component={RouterLink}
                    underline="none"
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </ListItem>
  )
}

export default MenuItem
export type { MenuItemData }
