/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

import React, { ReactElement, ReactNode } from "react"
import Loading from "@medaica/common/components/loading"
import { Box, BoxProps } from "@mui/material"
import mergeSx from "merge-sx"

const Page = ({
  variant = "standard",
  children,
  loading = false,
  ...props
}: {
  variant?: "standard" | "full"
  loading?: boolean
  children: ReactNode
} & BoxProps): ReactElement => {
  const { sx, ...rest } = props
  return loading ? (
    <Loading />
  ) : (
    <Box
      p={2}
      sx={mergeSx(
        {
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          flexGrow: 1,
          ...(variant === "standard" && { width: "960px" }),
        },
        sx
      )}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default Page
